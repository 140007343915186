import { CommonModule, formatDate, getLocaleId } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DashboardLayoutComponent } from '../../ui/dashboard-layout/dashboard-layout.component';
import { faPlus, faDashboard, faTrainSubway, faPeopleGroup, faBell, faPeopleLine, faSignOut, faUser, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { SummaryCardComponent } from '../../ui/summary-card/summary-card.component';
import { SummaryChipComponent } from '../../ui/summary-chip/summary-chip.component';
import { SmallTransactionsListComponent } from '../../ui/transactions/small-transactions-list/small-transactions-list.component';
import { SmallEmployeesListComponent } from '../../ui/employees/small-employees-list/small-employees-list.component';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DashboardService } from '../../services/dashboard.service';
import { TransactionListElement } from '../../models/transaction/transaction-list';
import { EmployeeIn } from '../../models/employee/employee-in';
import { Formatters } from '../../helpers/formatters';
import { BusinessService } from '../../services/business.service';
import { EmployeesService } from '../../services/employees.service';
import { SuppliersService } from '../../services/suppliers.service';
import { BarChartComponent } from '../../ui/bar-chart/bar-chart.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    DashboardLayoutComponent,
    SummaryCardComponent,
    SummaryChipComponent,
    SmallTransactionsListComponent,
    SmallEmployeesListComponent,
    RouterModule,
    FontAwesomeModule,
    TranslateModule,
    BarChartComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  currentUsername: string = localStorage.getItem('firstname') || 'User';

  pageTitle: string = '';
  employees: EmployeeIn[] = []

  @ViewChild('dropdown') dropdown!: ElementRef;
  isDropdownOpen: boolean = false;

  date = formatDate(Date.now(), "EEEE, dd MMMM", this.translate.getDefaultLang())

  constructor(private suppliersService: SuppliersService, private employeesService: EmployeesService, private router: Router, private businessData: BusinessService, private translate: TranslateService, private dashboardService: DashboardService) {
    this.translate.get('dashboard.welcome', { currentUsername: this.currentUsername }).subscribe((translation: string) => {
      this.pageTitle = translation;
    });
  }

  formatAmount(amount: any) {
    return Formatters.formatAmount(amount.value, amount.currency, this.translate.getDefaultLang())
  }

  nextPaymentDate(date: string) {
    if (null == date) return ""
    const numDate = Date.parse(date)
    return formatDate(numDate, "EEEE, dd MMMM", this.translate.getDefaultLang())
  }

  icons = { faPlus, faDashboard, faTrainSubway, faPeopleGroup, faBell, faPeopleLine, faSignOut, faUser, faQuestion }

  get summary() {
    return this.dashboardService.dashboard
  }
  get transactions(): TransactionListElement[] {
    return null == this.summary ? [] : this.summary.last_transactions.map((t: any) => {
      t.amount_formatted = this.formatAmount(t.amount)
      return t as TransactionListElement
    })
  }

  showTransaction(transaction: TransactionListElement) {
    this.router.navigate([`/dashboard/transactions/${transaction.id}`]);
  }
  showAllTransactions() {
    this.router.navigate(['/dashboard/transactions'])
  }
  reviewTransaction(id: string) {
    this.router.navigate([`/dashboard/schedules/${id}`])
  }
  newTransaction() {
    this.router.navigate(['/dashboard/transactions/new'])
  }

  showAllEmployees() {
    this.router.navigate(['/dashboard/employees'])
  }

  onNotificationClicked() {
    console.log("Captured click from the notifications icon")
  }
  onActionClicked() {
    this.router.navigate(["dashboard/employees/new"])
  }
  onSummaryActionClicked(event: number) {
    if (1 == event) this.router.navigate(['/dashboard/account'])
    else {
      if (null == this.summary.next_schedule)
        this.newTransaction()
      else 
        this.reviewTransaction(this.summary.next_schedule.id)
    }
  }

  get hasEmployees() {
    return this.employeesService.employees.length > 0
  }
  get hasSuppliers() {
    return this.suppliersService.suppliers.length > 0
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdownElement = this.dropdown?.nativeElement;

    if (!dropdownElement?.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  ngOnInit() {
    this.dashboardService.continuous = true
    this.employeesService.listEmployees().subscribe(_ => {
      this.employees = this.employeesService.employees.slice(0, 3)
    })
    this.suppliersService.listSuppliers().subscribe(_ => {})
    this.dashboardService.summary().subscribe(_ => { })
    this.businessData.getInfos().subscribe(infos => {
      (window as any)._kiq.push('identity', infos.reference)
    })
  }

  ngOnDestroy() {
    this.dashboardService.continuous = false
  }
}
