import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierIn } from '../../models/suppliers/supplier-in';
import { SuppliersService } from '../../services/suppliers.service';
import { DashboardLayoutComponent } from '../../ui/dashboard-layout/dashboard-layout.component';
import { EmptyListComponent } from '../../ui/suppliers/empty-list/empty-list.component';
import { FullSuppliersListComponent } from '../../ui/suppliers/full-suppliers-list/full-suppliers-list.component';
import { SharedService } from '../../services/shared.service';
import { switchMap, take } from 'rxjs';
import { SupplierResp } from '../../models/suppliers/supplier-resp';

@Component({
  selector: 'app-list-suppliers',
  standalone: true,
  imports: [DashboardLayoutComponent, EmptyListComponent, CommonModule, FullSuppliersListComponent, TranslateModule],
  templateUrl: './list-suppliers.component.html',
  styleUrl: './list-suppliers.component.scss'
})
export class ListSuppliersComponent implements OnInit {

  constructor(private router: Router, private data: SuppliersService, private sharedService: SharedService) { }

  onNotificationClicked() {

  }

  onActionClicked() {
    this.sharedService.getStaticData().subscribe(_ => {
      this.router.navigate(["/dashboard/suppliers/new"])
    })
  }

  get hasNewItem(): Boolean {
    return this.data.hasNewSupplier
  }
  get alreadyLoaded(): Boolean {
    return this.data.loaded
  }
  get suppliers(): SupplierIn[] {
    return this.data.suppliers.reverse()
  }

  updateSupplier(supplier: SupplierIn) {
    this.data.getSupplier(supplier.id).pipe(
      take(1),
      switchMap((supplierObj: SupplierResp) => {
        this.data.fetchedTarget = supplierObj

        return this.sharedService.getStaticData()
      })
    ).subscribe(resp => {
      this.router.navigate(["/dashboard/suppliers/new"])
    })
  }

  ngOnInit() {
    this.data.listSuppliers().subscribe(_ => { })
    this.sharedService.getStaticData().subscribe(_ => { })
  }
}
