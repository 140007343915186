<app-dashboard-layout [pageTitle]="'schedules.list_title' | translate" (onNotificationClick)="onNotificationClicked()"
  [hasAction]="schedules.length > 0">
  @if (schedules.length > 0) {
  <div customActionButton #dropdown>
    <button id="dropdownDefaultButton" (click)="toggleDropdown()"
      [attr.data-dropdown-toggle]="isDropdownOpen ? 'dropdown' : null"
      class="colors-primary-border text-sm font-medium md:text-xl colors-primary-bg px-5 py-2.5 text-center inline-flex items-center rounded-2xl"
      type="button">{{'schedules.new_action' | translate}} <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
      </svg>
    </button>

    <div id="dropdown" [ngClass]="{'hidden': !isDropdownOpen}"
      class="z-10 fixed text-sm colors-on-background md:text-xl dropdown-button divide-y rounded-lg shadow md:w-56 w-44">
      <ul class="py-2" [attr.aria-labelledby]="isDropdownOpen ? 'dropdownDefaultButton' : null">
        <li (click)="newEmployeeTransaction()" class="cursor-pointer block px-4 py-2 dropdown-button">
          {{'transactions.action.new.salary' |translate}}
        </li>
        <li (click)="newSupplierTransaction()" class="cursor-pointer block px-4 py-2 dropdown-button">
          {{'transactions.action.new.supplier' |translate}}
        </li>
      </ul>
    </div>
  </div>
  }
  <div pageContent>
    @if (isLoading) {
    <app-transaction-list-shimmer></app-transaction-list-shimmer>
    } @else if (schedules.length > 0) {
    <app-schedules-list (onItemClicked)="showSchedule($event)" [resultsPerPage]="SCHEDULES_PER_PAGE" [currentPage]="paginationCurrentPage" [totalResults]="totalResults"
      (onShowAllschedules)="showAllSchedules()" (onNewSchedule)="newSchedule()" [list]="schedules"
      class="md:col-span-3 2xl:col-span-4 self-stretch"></app-schedules-list>
    } @else {
    <ng-container>
      <app-empty-schedules-list></app-empty-schedules-list>
    </ng-container>
    }
  </div>
</app-dashboard-layout>