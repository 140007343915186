<li *ngIf="null != item" (click)="onRedirectToDetails(item)"
    class="py-5 colors-background-card cursor-pointer flex items-start md:justify-between border px-4 rounded-lg my-5 flex-col sm:flex-row">
    <div class="flex w-full justify-between items-center gap-3">
        <div class="flex md:w-2/5 lg:w-1/3 truncate">
        <div class="flex-none w-12 mr-2 h-12 rounded-full">
            @if (item.is_money_in) {
            <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path opacity="0.5"
                        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
                        fill="#1C39"></path>
                    <path
                        d="M13.5 15.75C13.9142 15.75 14.25 15.4142 14.25 15C14.25 14.5858 13.9142 14.25 13.5 14.25H10.8107L15.5303 9.53033C15.8232 9.23744 15.8232 8.76256 15.5303 8.46967C15.2374 8.17678 14.7626 8.17678 14.4697 8.46967L9.75 13.1893V10.5C9.75 10.0858 9.41421 9.75 9 9.75C8.58579 9.75 8.25 10.0858 8.25 10.5V15C8.25 15.4142 8.58579 15.75 9 15.75H13.5Z"
                        fill="#1C39"></path>
                </g>
            </svg>
            } @else {
            <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path opacity="0.5"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        fill="#f83f3f"></path>
                    <path
                        d="M10.5 8.25C10.0858 8.25 9.75 8.58579 9.75 9C9.75 9.41421 10.0858 9.75 10.5 9.75H13.1893L8.46967 14.4697C8.17678 14.7626 8.17678 15.2374 8.46967 15.5303C8.76256 15.8232 9.23744 15.8232 9.53033 15.5303L14.25 10.8107V13.5C14.25 13.9142 14.5858 14.25 15 14.25C15.4142 14.25 15.75 13.9142 15.75 13.5V9C15.75 8.58579 15.4142 8.25 15 8.25H10.5Z"
                        fill="#f83f3f"></path>
                </g>
            </svg>
            }
        </div>
        <div>
            <p class="block text-xl font-black mr-2">{{item.concept}}</p>
            <span class="block text-xs">{{('schedules.type_' + item.recipient_type.toLowerCase()) | translate}}</span>
        </div>
        </div>
        <div>
            <span class="block text-lg font-medium" [textContent]="('schedules.frequency_' + item.schedule_frequency).toLowerCase() | translate"></span>
            <span class="block text-xs">{{(item.schedule_frequency == 'ONCE' ? 'schedules.once_on' : 'schedules.monthly_from') | translate}} {{item.schedule_next_execution_date | date : 'dd/MM/yyyy'}}</span>
        </div>
        <div>
            <span class="font-bold">{{item.amount_formatted}}</span>
                <span class="flex text-sm">
                  <svg width="20px" height="20px" viewBox="0 0 24 24" class="mr-1" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <rect width="24" height="24" fill="white"></rect>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5 9.5C5 7.01472 7.01472 5 9.5 5C11.9853 5 14 7.01472 14 9.5C14 11.9853 11.9853 14 9.5 14C7.01472 14 5 11.9853 5 9.5Z"
                        fill="#07033a"></path>
                      <path
                        d="M14.3675 12.0632C14.322 12.1494 14.3413 12.2569 14.4196 12.3149C15.0012 12.7454 15.7209 13 16.5 13C18.433 13 20 11.433 20 9.5C20 7.567 18.433 6 16.5 6C15.7209 6 15.0012 6.2546 14.4196 6.68513C14.3413 6.74313 14.322 6.85058 14.3675 6.93679C14.7714 7.70219 15 8.5744 15 9.5C15 10.4256 14.7714 11.2978 14.3675 12.0632Z"
                        fill="#07033a"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.64115 15.6993C5.87351 15.1644 7.49045 15 9.49995 15C11.5112 15 13.1293 15.1647 14.3621 15.7008C15.705 16.2847 16.5212 17.2793 16.949 18.6836C17.1495 19.3418 16.6551 20 15.9738 20H3.02801C2.34589 20 1.85045 19.3408 2.05157 18.6814C2.47994 17.2769 3.29738 16.2826 4.64115 15.6993Z"
                        fill="#07033a"></path>
                      <path
                        d="M14.8185 14.0364C14.4045 14.0621 14.3802 14.6183 14.7606 14.7837V14.7837C15.803 15.237 16.5879 15.9043 17.1508 16.756C17.6127 17.4549 18.33 18 19.1677 18H20.9483C21.6555 18 22.1715 17.2973 21.9227 16.6108C21.9084 16.5713 21.8935 16.5321 21.8781 16.4932C21.5357 15.6286 20.9488 14.9921 20.0798 14.5864C19.2639 14.2055 18.2425 14.0483 17.0392 14.0008L17.0194 14H16.9997C16.2909 14 15.5506 13.9909 14.8185 14.0364Z"
                        fill="#07033a"></path>
                    </g>
                  </svg>
                  {{item.recipients_count}}
                </span>
        </div>
    </div>
</li>