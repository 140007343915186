<app-dashboard-layout [pageTitle]="'topup.page.title' | translate" [actionLabel]="'employees.layout.action.add'"
  [hasAction]="false">
  <ng-container pageContent>
    <app-stepper [steps]="steps" [currentStep]="currentStep"></app-stepper>
    <div class="my-2 hidden md:block">&nbsp;</div>

    <app-payment-progress [showPaymentProgress]="showPaymentProgress" [mobileOperator]="mobileOperator"
      (buttonAction)="startAgain()" [status]="status"
      [textButton]="'new.transaction.bulk.back.button' | translate"></app-payment-progress>

    @if (!showPaymentProgress) {
    <section class="w-full colors-surface rounded-2xl px-2 py-4 lg:pt-16 text-center relative">
      <button class="p-2 absolute right-2 lg:right-4 top-2 rounded-2xl text-4xl" (click)="close()">&times;</button>
      <div class="max-w-2xl mx-auto px-4 md:px-0">

        <!-- Step content -->
        <div>
          <div *ngIf="currentStep === 0">
            <div class="my-3">
              <app-alert [alertMessage]="alertMessage | translate" [alertTitle]="alertTitle | translate"
                [alertType]="'success'" [showAlert]="showAlert"></app-alert>
            </div>
            <form [formGroup]="topUpForm">
              <p class="text-2xl">{{'topup.title' | translate}}</p>
              <p class="my-2 mb-14">{{'topup.description' | translate}}</p>

              <div class="mb-4">
                <app-error [errorMessage]="errorMessage" [showError]="showError" class="text-left"></app-error>
              </div>

              <div class="inputs-group-span-1 mb-6">
                <label for="amount">{{'topup.form.amount' | translate}}</label>
                <input class="inputs-field-default"
                  [ngClass]="{'colors-border-error focus:outline-red-500': isAmountInvalid}" type="text"
                  formControlName="amount">
                @if (isAmountInvalid) {
                <label class="alert-error px-2 py-1">{{amountErrorMessage | translate}}</label>
                }
                @if (amountError) {
                <label class="alert-error px-2 py-1">{{amountErrorMessage | translate}}</label>
                }
              </div>

              <div [ngClass]="'mx-auto'" [ngStyle]="{ '--selected-payment-method': selectedPaymentMethod }"
                class="mx-auto text-left">
                <h2 class="font-medium">{{'topup.option.label' | translate}}</h2>
                <div class="flex flex-col md:flex-row md:justify-between">
                  <ul class="grid grid-cols-2 gap-5">
                    <li class="my-3">
                      <label class="block relative">
                        <input type="radio" [checked]="selectedPaymentMethod === 'MOBILE_MONEY'"
                          (click)="selectedPaymentMethod = 'MOBILE_MONEY'" (change)="onChanged($event)"
                          value="MOBILE_MONEY" name="payment" class="sr-only peer" />
                        <div
                          class="w-full flex gap-x-3 items-start p-4 cursor-pointer rounded-lg border option-card-selection shadow-sm"
                          [class.peer-checked:ring-2]="selectedPaymentMethod === 'MOBILE_MONEY'"
                          [class.duration-200]="selectedPaymentMethod === 'MOBILE_MONEY'">
                          <div class="flex-none">
                            <svg width="24px" height="24px" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg" fill="#000000">
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <defs>
                                  <style>
                                    .cls-1 {
                                      fill: none;
                                      stroke: #020202;
                                      stroke-miterlimit: 10;
                                      stroke-width: 1.91px;
                                    }
                                  </style>
                                </defs>
                                <rect class="cls-1" x="4.36" y="1.5" width="15.27" height="21" rx="2.04"></rect>
                                <path class="cls-1"
                                  d="M13.91,2.45H10.09a.94.94,0,0,1-.95-1h5.72A.94.94,0,0,1,13.91,2.45Z">
                                </path>
                                <path class="cls-1"
                                  d="M9.14,14.86h3.34a1.43,1.43,0,0,0,1.43-1.43h0A1.43,1.43,0,0,0,12.48,12h-1a1.43,1.43,0,0,1-1.43-1.43h0a1.43,1.43,0,0,1,1.43-1.43h3.34">
                                </path>
                                <line class="cls-1" x1="12" y1="7.23" x2="12" y2="9.14"></line>
                                <line class="cls-1" x1="12" y1="14.86" x2="12" y2="16.77"></line>
                              </g>
                            </svg>
                          </div>
                          <div>
                            <h3 class="leading-none font-bold pr-3"
                              [textContent]="'topup.option.mobile.money.title' | translate"></h3>
                            <p class="mt-1 text-sm" [textContent]="'topup.option.mobile.money.desc' | translate"></p>
                          </div>
                        </div>
                        <div
                          class="absolute top-4 right-4 flex-none flex items-center justify-center w-4 h-4 rounded-full border"
                          [class.peer-checked:bg-indigo-600]="selectedPaymentMethod === 'MOBILE_MONEY'"
                          [class.text-white]="selectedPaymentMethod === 'MOBILE_MONEY'"
                          [class.duration-200]="selectedPaymentMethod === 'MOBILE_MONEY'">
                          <svg class="w-2.5 h-2.5" viewBox="0 0 12 10">
                            <polyline fill="none" stroke-width="2px"
                              [attr.stroke]="selectedPaymentMethod === 'MOBILE_MONEY' ? 'currentColor' : 'none'"
                              [attr.stroke-dasharray]="selectedPaymentMethod === 'MOBILE_MONEY' ? '16px' : null"
                              points="1.5 6 4.5 9 10.5 1"></polyline>
                          </svg>
                        </div>
                      </label>
                    </li>


                    <li class="my-3">
                      <label class="block relative">
                        <input type="radio" [checked]="selectedPaymentMethod === 'BANK_ACCOUNT'"
                          (click)="selectedPaymentMethod = 'BANK_ACCOUNT'" (change)="onChanged($event)"
                          value="BANK_ACCOUNT" name="bankAccount" class="sr-only peer" />
                        <div
                          class="w-full flex gap-x-3 items-start p-4 cursor-pointer rounded-lg border option-card-selection shadow-sm"
                          [class.peer-checked:ring-2]="selectedPaymentMethod === 'BANK_ACCOUNT'"
                          [class.duration-200]="selectedPaymentMethod === 'BANK_ACCOUNT'">
                          <div class="flex-none">
                            <svg width="24px" height="24px" viewBox="0 0 20 20" version="1.1"
                              xmlns="http://www.w3.org/2000/svg" fill="#000000">
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                <g id="layer1">
                                  <path
                                    d="M 9.5 0 L 9.5 1 C 8.6774954 1 8 1.677495 8 2.5 C 8 3.322505 8.6774954 4 9.5 4 L 10.5 4 C 10.782065 4 11 4.217935 11 4.5 C 11 4.782065 10.782065 5 10.5 5 L 9.5 5 L 8 5 L 8 6 L 9.5 6 L 9.5 7 L 10.5 7 L 10.5 6 C 11.322504 6 12 5.322505 12 4.5 C 12 3.677495 11.322504 3 10.5 3 L 9.5 3 C 9.2179352 3 9 2.782065 9 2.5 C 9 2.217935 9.2179352 2 9.5 2 L 10.5 2 L 12 2 L 12 1 L 10.5 1 L 10.5 0 L 9.5 0 z M 7 3.9238281 L 0 6.1328125 L 0 6.5 L 0 9 L 1 9 L 1 17 L 0 17 L 0 20 L 20 20 L 20 17 L 19.5 17 L 19 17 L 19 9 L 20 9 L 20 6.1328125 L 13 3.9238281 L 13 4.9707031 L 19 6.8671875 L 19 8 L 1 8 L 1 6.8652344 L 7 4.9707031 L 7 3.9238281 z M 2 9 L 3 9 L 3 17 L 2 17 L 2 9 z M 4 9 L 6 9 L 6 17 L 4 17 L 4 9 z M 7 9 L 8 9 L 8 17 L 7 17 L 7 9 z M 9 9 L 11 9 L 11 17 L 9 17 L 9 9 z M 12 9 L 13 9 L 13 17 L 12 17 L 12 9 z M 14 9 L 16 9 L 16 17 L 14 17 L 14 9 z M 17 9 L 18 9 L 18 17 L 17 17 L 17 9 z M 1 18 L 4 18 L 6 18 L 9 18 L 11 18 L 14 18 L 16 18 L 19 18 L 19 19 L 1 19 L 1 18 z "
                                    style="fill:#222222; fill-opacity:1; stroke:none; stroke-width:0px;"></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div>
                            <h3 class="leading-none font-bold pr-3"
                              [textContent]="'topup.option.bank.title' | translate"></h3>
                            <p class="mt-1 text-sm" [textContent]="'topup.option.bank.desc' | translate"></p>
                          </div>
                        </div>
                        <div
                          class="absolute top-4 right-4 flex-none flex items-center justify-center w-4 h-4 rounded-full border"
                          [class.peer-checked:bg-indigo-600]="selectedPaymentMethod === 'BANK_ACCOUNT'"
                          [class.text-white]="selectedPaymentMethod === 'BANK_ACCOUNT'"
                          [class.duration-200]="selectedPaymentMethod === 'BANK_ACCOUNT'">
                          <svg class="w-2.5 h-2.5" viewBox="0 0 12 10">
                            <polyline fill="none" stroke-width="2px"
                              [attr.stroke]="selectedPaymentMethod === 'BANK_ACCOUNT' ? 'currentColor' : 'none'"
                              [attr.stroke-dasharray]="selectedPaymentMethod === 'BANK_ACCOUNT' ? '16px' : null"
                              points="1.5 6 4.5 9 10.5 1"></polyline>
                          </svg>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="selectedPaymentMethod === 'MOBILE_MONEY'" class="inputs-group-span-1 my-4">
                <label for="phoneNumber">{{'topup.form.phone' | translate}}</label>
                <div class="relative">
                  <input class="inputs-field-default w-full"
                    [ngClass]="{'colors-border-error focus:outline-red-500': isPhoneInvalid}" type="text"
                    formControlName="phone" maxlength="9">
                  <app-operator-icon [customizedForm]="topUpForm" [formFieldName]="'phone'"></app-operator-icon>
                </div>
                @if (isPhoneInvalid) {
                <label class="alert-error px-2 py-1">Please enter a valid number</label>
                }
              </div>
            </form>
          </div>

          <div *ngIf="selectedPaymentMethod === 'MOBILE_MONEY' && currentStep === 1">
            <div class="bg-white shadow-lg border rounded-lg p-6">
              <h2 class="text-2xl fonts-bold mb-4">{{'topup.step2.title' | translate}}</h2>

              <div class="flex flex-col space-y-4 fonts-normal">
                <div class="flex justify-between">
                  <span>{{'topup.step2.identifier' | translate}}: </span>
                  <span class="fonts-bold">{{topUpResponse.payment_method.identifier!! | phoneNumber}}</span>
                </div>

                <div class="flex justify-between">
                  <span>{{'topup.option.label' | translate}}: </span>
                  <span class="fonts-bold">{{topUpResponse.payment_method.operator_id!!.split('_').join('
                    ').toUpperCase()}}</span>
                </div>

                <div class="flex justify-between">
                  <span>{{'topup.total.amount' | translate}}: </span>
                  <span class="fonts-bold">{{topUpResponse.total_amount_formatted}}</span>
                </div>

                <div class="flex justify-between">
                  <span>{{'payment.summary.fees' | translate}}: </span>
                  <span class="fonts-bold">{{topUpResponse.fees_formatted}}</span>
                </div>

                <div class="space-y-0">
                  <div class="flex justify-between text-xl mt-10">
                    <span class="fonts-bold">{{'topup.step2.amount' | translate}}: </span>
                    <span class="fonts-bold text-2xl">{{topUpResponse.amount_formatted}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedPaymentMethod === 'BANK_ACCOUNT' && currentStep === 1">
            <div class="bg-white shadow-lg border rounded-lg p-6">
              <h2 class="text-2xl fonts-bold mb-4">{{'topup.step2.title' | translate}}</h2>

              <div class="flex justify-between">
                <span>{{'topup.total.amount' | translate}}: </span>
                <span class="fonts-bold">{{topUpResponse.total_amount_formatted}}</span>
              </div>

              <div class="flex justify-between">
                <span>{{'payment.summary.fees' | translate}}: </span>
                <span class="fonts-bold">{{topUpResponse.fees_formatted}}</span>
              </div>

              <div class="space-y-0">
                <div class="flex justify-between text-xl mt-10">
                  <span class="fonts-bold">{{'topup.step2.amount' | translate}}: </span>
                  <span class="fonts-bold text-2xl">{{topUpResponse.amount_formatted}}</span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="selectedPaymentMethod === 'BANK_ACCOUNT' && currentStep === 2">
            <div class="mb-4">
              <p class="font-bold text-lg">{{'topup.bank.details.title' | translate}}</p>
              <app-bank-details-generator [deposit_details]="topUpResponse.deposit_details" [amount]="getAmount(topUpResponse.total_amount)"></app-bank-details-generator>
            </div>
            <div class="flex border rounded-lg justify-between items-center md:p-8 p-4">
              <div class="flex flex-col space-y-4 text-left w-2/4">
                <div class="colors-background-card shadow-lg rounded-lg border p-4 flex flex-row justify-between">
                  <div>
                    <h3 class="text-xs">{{'topup.bank.details.beneficiary' | translate}}</h3>
                    <p class="font-bold">{{topUpResponse.recipient_name ? topUpResponse.recipient_name : 'FUTAMOBILE SARL'}}</p>
                  </div>

                  <div class="flex-shrink-0">
                    <svg width="24px" height="24px" (click)="copyToClipboard(topUpResponse.recipient_name ? topUpResponse.recipient_name : 'FUTAMOBILE SARL', 1)" viewBox="0 0 24 24"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                          stroke="#1C274C" stroke-width="1.5"></path>
                        <path
                          d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                          stroke="#1C274C" stroke-width="1.5"></path>
                      </g>
                    </svg>
                    @if (copied === 1) {
                    <span class="text-xs text-green-500">{{'copied' | translate}}</span>
                    }
                  </div>
                </div>

                <div class="colors-background-card shadow-lg rounded-lg border p-4 flex flex-row justify-between">
                  <div>
                    <h3 class="text-xs">{{'topup.bank.details.reference' | translate}}</h3>
                    <p class="font-bold">{{topUpResponse.deposit_details.reference}}</p>
                  </div>
                  <div class="flex-shrink-0">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" (click)="copyToClipboard(topUpResponse.deposit_details.reference, 2)"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                          stroke="#1C274C" stroke-width="1.5"></path>
                        <path
                          d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                          stroke="#1C274C" stroke-width="1.5"></path>
                      </g>
                    </svg>
                    @if (copied === 2) {
                    <span class="text-xs text-green-500">{{'copied' | translate}}</span>
                    }
                  </div>
                </div>

                <div class="colors-background-card shadow-lg rounded-lg border p-4 flex flex-row justify-between">
                  <div>
                    <h3 class="text-xs">{{'topup.bank.details.account.number' | translate}}</h3>
                    <p class="font-bold">{{topUpResponse.deposit_details.account_number}}</p>
                  </div>
                  <div class="flex-shrink-0">
                    <svg width="24px" height="24px" (click)="copyToClipboard(topUpResponse.deposit_details.account_number, 3)"
                      viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                          stroke="#1C274C" stroke-width="1.5"></path>
                        <path
                          d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                          stroke="#1C274C" stroke-width="1.5"></path>
                      </g>
                    </svg>
                    @if (copied === 3) {
                    <span class="text-xs text-green-500">{{'copied' | translate}}</span>
                    }
                  </div>
                </div>

                <div class="colors-background-card shadow-lg rounded-lg border p-4 flex flex-row justify-between">
                  <div>
                    <h3 class="text-xs">{{'topup.bank.details.bank.code' | translate}}</h3>
                    <p class="font-bold">{{topUpResponse.deposit_details.bank_code}}</p>
                  </div>
                  <div class="flex-shrink-0">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" (click)="copyToClipboard(topUpResponse.deposit_details.bank_code, 4)"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                          stroke="#1C274C" stroke-width="1.5"></path>
                        <path
                          d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                          stroke="#1C274C" stroke-width="1.5"></path>
                      </g>
                    </svg>
                    @if (copied === 4) {
                    <span class="text-xs text-green-500">{{'copied' | translate}}</span>
                    }
                  </div>
                </div>

                <div class="colors-background-card shadow-lg rounded-lg border p-4 flex flex-row justify-between">
                  <div>
                    <h3 class="text-xs">{{'topup.bank.details.amount' | translate}}</h3>
                    <p class="font-bold">{{getAmount(topUpResponse.total_amount)}}</p>
                  </div>
                  <div class="flex-shrink-0">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" (click)="copyToClipboard((topUpResponse.total_amount.value/100).toString(), 5)" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                          stroke="#1C274C" stroke-width="1.5"></path>
                        <path
                          d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                          stroke="#1C274C" stroke-width="1.5"></path>
                      </g>
                    </svg>
                    @if (copied === 5) {
                    <span class="text-xs text-green-500">{{'copied' | translate}}</span>
                    }
                  </div>
                </div>

                <div class="colors-background-card shadow-lg rounded-lg border p-4 flex flex-row justify-between">
                  <div>
                    <h3 class="text-xs">{{'topup.bank.details.bank' | translate}}</h3>
                    <p class="font-bold">{{bankName(topUpResponse.deposit_details.bank_operator_id)}}</p>
                  </div>
                  <div class="flex-shrink-0">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" (click)="copyToClipboard(bankName(topUpResponse.deposit_details.bank_operator_id), 6)"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                          stroke="#1C274C" stroke-width="1.5"></path>
                        <path
                          d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                          stroke="#1C274C" stroke-width="1.5"></path>
                      </g>
                    </svg>
                    @if (copied === 6) {
                    <span class="text-xs text-green-500">{{'copied' | translate}}</span>
                    }
                  </div>
                </div>
              </div>
              <div class="flex w-2/4 text-sm px-2">
                <p>{{'topup.bank.details.text' | translate: {reference: topUpResponse.deposit_details.reference} }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 flex justify-center space-x-2">
          @if (!isLoading) {
            @if(currentStep > 0) {
            <button [ngClass]="{'colors-primary-bg-disabled': onDisableNext() && paymentMethod.type != 'BANK_ACCOUNT', 'bg-green-600 text-white hover:bg-green-600': copied == 7}" (click)="previousStep()"
              class="flex items-center px-8 py-2 stepper-buttons border rounded-lg">
              <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path *ngIf="currentStep < 2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
                <g *ngIf="currentStep == 2" id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g *ngIf="currentStep == 2" id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g *ngIf="currentStep == 2" id="SVGRepo_iconCarrier">
                  <path
                    d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                    stroke="currentColor" stroke-width="1.5"></path>
                  <path
                    d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                    stroke="currentColor" stroke-width="1.5"></path>
                </g>
              </svg>
              {{(currentStep < 2 ? 'topup.previous.button' : (copied == 7 ? 'topup_page.action_copied_all' : 'topup_page.action_copy_all')) | translate}}
            </button>
            }
            @if (currentStep < 2) {
            <button [ngClass]="{'colors-primary-bg-disabled': onDisableNext() && paymentMethod.type != 'BANK_ACCOUNT'}"
              (click)="nextStep()"
                class="flex items-center px-8 py-2 colors-primary-bg rounded-lg">{{
              (currentStep === 0 ?
              "topup_page.action_continue" : (currentStep == 2 && paymentMethod.type == "BANK_ACCOUNT" ? "topup_page.action_close" :
              "topup_page.action_confirm")) | translate
              }}
              <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
              </svg>
            </button>
            } @else {
              <button
                (click)="goHome()" class="flex items-center px-8 py-2 colors-primary-bg rounded-lg">{{
                  'topup_page.action_close' | translate
                }}
                <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                </svg>
              </button>
            }
          } @else {
          <button class="px-8 py-2 colors-primary-bg rounded-lg self-start flex disabled">
            <p class="animate animate-spin mr-4"><fa-icon [icon]="icons.faLoader"></fa-icon></p> {{'loader.message' |
            translate}}
          </button>
          }
        </div>
      </div>
    </section>
    }
  </ng-container>
</app-dashboard-layout>
