<div class="flex w-full justify-center">
  <div *ngIf="business!!" class="w-full bg-white max-w-xl rounded-xl shadow-md overflow-hidden">
    <div class="px-6 pt-4 flex">
      <div *ngIf="null == business.logo" [title]="'business.logo_placeholder' | translate"
        class="hover:bg-gray-50 cursor-default flex justify-center items-center w-[160px] h-[160px] border rounded-lg">
        <p class="text-7xl">{{business.name.substring(0, 1)}}</p>
      </div>
      <img *ngIf="null != business.logo" class="flex justify-center items-center w-[160px] h-[160px] border rounded-lg"
        [alt]="'business.logo_alt' | translate" [src]="business.logo">
      <div class="flex flex-col w-72 h-full justify-between">
        <div class="ml-8">
          <p class="text-2xl font-bold pb-4">{{business.name}}</p>
          <label for="account-id" class="text-md">{{'business.infos_reference' | translate}}:</label>
          <div class="w-full max-w-[16rem] pb-2">
            <div class="relative">
              <input id="npm-install-copy-text" type="text"
                class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full px-2.5 py-4"
                [value]="business.reference" disabled readonly>
              <button data-copy-to-clipboard-target="npm-install-copy-text"
                class="absolute end-2.5 top-1/2 -translate-y-1/2 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border">
                @if (!isCopied) {
                <span (click)="onCopyReference()" id="default-message" class="inline-flex items-center">
                  <svg class="w-3.5 h-3.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                        stroke="#1C274C" stroke-width="1.5"></path>
                      <path
                        d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                        stroke="#1C274C" stroke-width="1.5"></path>
                    </g>
                  </svg>
                  <span class="text-xs font-semibold">{{'business.copy' | translate}}</span>
                </span>
                } @else {
                <span id="success-message" class="items-center">
                  <span class="text-xs font-semibold">{{'business.copied' | translate}}</span>
                </span>
                }
              </button>
            </div>
          </div>

          @if (business.public_link !== null) {
          <label class="text-md">{{'business.infos_public_link' | translate}}: </label>
          <div class="w-full max-w-[16rem]">
            <div class="relative">
              <input id="npm-install-copy-text" type="text"
                class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full px-2.5 py-4"
                value="https://app.futamobile.com/#/tester-sarlu-bc803" disabled readonly>
              <button data-copy-to-clipboard-target="npm-install-copy-text"
                class="absolute end-2.5 top-1/2 -translate-y-1/2 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border">
                @if (!isCopiedPublicLink) {
                <span (click)="onCopyPublicLink()" id="default-message" class="inline-flex items-center">
                  <svg class="w-3.5 h-3.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                        stroke="#1C274C" stroke-width="1.5"></path>
                      <path
                        d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                        stroke="#1C274C" stroke-width="1.5"></path>
                    </g>
                  </svg>
                  <span class="text-xs font-semibold">{{'business.copy' | translate}}</span>
                </span>
                } @else {
                <span id="success-message" class="items-center">
                  <span class="text-xs font-semibold">{{'business.copied' | translate}}</span>
                </span>
                }
              </button>
            </div>
          </div>
          }
        </div>
      </div>
    </div>

    <div class="px-6 py-4 space-y-3">
      <h3 class="text-lg mb-2 bg-slate-300 fonts-bold px-2">{{'business.general_infos' | translate}}</h3>

      <div class="flex justify-between px-4">
        <p>{{'business.infos_address' | translate}}</p>
        <p>{{business.address}}</p>
      </div>
      <div class="flex justify-between px-4">
        <p>{{'business.infos_category' | translate}}</p>
        <p>{{categoryName(business.category_id!!)}}</p>
      </div>
      <div class="flex justify-between px-4">
        <p>{{'business.infos_website' | translate}}</p>
        <p>{{business.website}}</p>
      </div>
      <div class="px-4">
        <!-- <p class="mr-12 flex-shrink-0">{{'business.description' | translate}}</p>
        <p class="flex-grow text-end">
          {{ business.description ?? '' | truncateWords:10:'...' }}
        </p> -->

        <p class="mr-12 flex-shrink-0 pb-2">{{'business.description' | translate}}</p>
        <textarea id="message" rows="3"
          class="block p-2.5 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          [value]="business.description" disabled readonly></textarea>
      </div>
    </div>

    <div class="border-t border-gray-200 px-6 py-4 space-y-3">
      <h3 class="text-lg font-semibold fonts-bold bg-slate-300 mb-2 px-2">{{'business.legal_infos' | translate}}
      </h3>
      <div class="flex justify-between px-4">
        <p>{{'business.infos_legal_status' | translate}}</p>
        <p>{{statusName(business.legal_status_id!!)}}</p>
      </div>
      <div class="flex justify-between px-4">
        <p>{{'business.infos_tax_id_number' | translate}}</p>
        <p>{{business.tax_identification_number}}</p>
      </div>
      <div class="flex justify-between px-4">
        <p>{{'business.infos_trade_register' | translate}}</p>
        <p>{{business.trade_register_number}}</p>
      </div>
    </div>

    @if (business.services.length > 0) {
    <div class="px-6 py-4 space-y-3">
      <h3 class="text-lg mb-2 bg-slate-300 fonts-bold px-2">Services</h3>
      <div class="px-4">
        @for (item of business.services; track $index) {
        <span class="bg-indigo-100 text-indigo-800 text-md font-medium me-2 px-2.5 py-0.5 rounded-full">{{item}}</span>
        }
      </div>
    </div>
    }
  </div>
