<!-- <div class="relative">
  <button (click)="toggleModal()" class="text-3xl focus:outline-none">
    🌐
  </button>

  <div *ngIf="isModalOpen"
       class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300"
       [ngClass]="{'opacity-0': !isModalOpen, 'opacity-100': isModalOpen}">
    <div class="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300"
         [ngClass]="{'scale-90': !isModalOpen, 'scale-100': isModalOpen}">
      <h2 class="text-xl font-bold mb-4">Website</h2>
      <div class="flex items-center">
        <input type="text" [value]="website" readonly class="border p-2 rounded-l-md flex-grow">
        <button (click)="copyWebsite()" class="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition-colors">
          Copy
        </button>
      </div>
      <button (click)="toggleModal()" class="mt-4 text-gray-600 hover:text-gray-800">Close</button>
    </div>
  </div>
</div> -->

<div class="flex items-center space-x-3">
  <button (click)="toggleInput()" class="text-3xl focus:outline-none">🌐</button>

  <!-- <input *ngIf="isInputOpen" type="text" [value]="website" class="border p-2 rounded-l-md"> -->
  <div *ngIf="isInputOpen" class="relative max-w-xs mt-2">
    <button class="text-gray-400 absolute right-3 inset-y-0 my-auto">
      <ng-container>
        <svg width="34px" height="44px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
              stroke="#1C274C" stroke-width="1.5"></path>
            <path
              d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
              stroke="#1C274C" stroke-width="1.5"></path>
          </g>
        </svg>
        @if (copied) {
        <span class="text-xs text-green-500">{{'copied' | translate}}</span>
        }
      </ng-container>
    </button>
    <input type="text" [value]="website" readonly
      class="w-full pr-12 pl-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg" />
  </div>
</div>
