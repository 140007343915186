import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransactionListElement } from '../../../models/transaction/transaction-list'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-transaction-item',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './transaction-item.component.html',
  styleUrl: './transaction-item.component.scss'
})
export class TransactionItemComponent {
  @Output() onClicked = new EventEmitter<TransactionListElement>()
  @Input('transaction') item: TransactionListElement | null = null

  icons = {faPlus}

  onRedirectToDetails(transaction: TransactionListElement) {
    this.onClicked.emit(transaction)
  }
}
