import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.pre';
import { HttpClient } from '@angular/common/http';
import { TopUpRequest, TopUpResponse } from '../models/account/top-up';
import { Observable, map } from 'rxjs';
import { Amount } from '../models/transaction/transaction';
import Dinero, { Currency } from 'dinero.js';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseURL = `${environment.apiUrl}/businesses/wallet`

  constructor(private http: HttpClient) { }

  initiateTopUp(topUpObj: any): Observable<TopUpResponse> {
    return this.http.post<TopUpResponse>(`${this.baseURL}/topup`, topUpObj).pipe(
      map((data: any) => {
        const topUpResponse = data.topup as TopUpResponse;
        if (topUpResponse) {
          return {
            ...topUpResponse,
            amount_formatted: this.moneyFormat(topUpResponse.amount),
            fees_formatted: this.moneyFormat(topUpResponse.fees),
            total_amount_formatted: this.moneyFormat(topUpResponse.total_amount)
          };
        }

        return {} as TopUpResponse;
      })
    );
  }

  confirmTopUp(topupId: string): Observable<TopUpResponse> {
    return this.http.post<TopUpResponse>(`${this.baseURL}/topup/${topupId}/confirm`, {}).pipe(
      map((data: any) => {
        return data.topup as TopUpResponse;
      })
    );
  }

  getWalletBalance(): Observable<any> {
    return this.http.get(`${this.baseURL}`).pipe(
      map((data: any) => {
        return data.wallet;
      })
    );
  }

  moneyFormat(amount: Amount) {
    return Dinero({ amount: amount.value, currency: amount.currency as Currency }).toFormat('$0,0');
  }
}
