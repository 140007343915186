<div class="w-full text-center pt-16 pb-24">
  <h2 class="fonts-bold mb-2 text-lg">{{'schedules.empty.header' | translate}}</h2>
  <p class="mb-4 text-xl">{{'schedules.empty.text' | translate}}</p>

  <div class="flex justify-center">
    <ul class="grid md:w-2/4 sm:w-full gap-6">
      <li (click)="goToNewTransaction()">
        <input type="radio" id="hosting-small" name="hosting" value="hosting-small" class="hidden peer" required />
        <label for="hosting-small"
          class="inline-flex justify-between w-full p-5 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
          <div class="block">
            <div class="flex">
              <svg version="1.1" id="Uploaded to svgrepo.com" class="mr-2" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 32 32"
                xml:space="preserve" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <style type="text/css">
                    .stone_een {
                      fill: #0B1719;
                    }

                    .st0 {
                      fill: #0B1719;
                    }
                  </style>
                  <path class="stone_een"
                    d="M18.314,25l-5.849,5.849c-0.391,0.391-0.902,0.586-1.414,0.586s-1.024-0.195-1.414-0.586L3.787,25H18.314 z M28.213,7l-5.849-5.849c-0.391-0.391-0.902-0.586-1.414-0.586s-1.024,0.195-1.414,0.586L13.686,7H28.213z M16,11 c-2.949,0-4,2.583-4,5c0,2.417,1.051,5,4,5s4-2.583,4-5C20,13.583,18.949,11,16,11z M31,22c0,1.105-0.895,2-2,2 c0,0-26.158-0.015-26.237-0.024c-0.923-0.11-1.647-0.844-1.742-1.771C1.014,22.136,1,10,1,10c0-1.105,0.895-2,2-2 c0,0,26.158,0.015,26.237,0.024c0.923,0.11,1.647,0.844,1.742,1.771C30.986,9.864,31,22,31,22z M5,21c0-0.552-0.448-1-1-1 s-1,0.448-1,1c0,0.552,0.448,1,1,1S5,21.552,5,21z M5,11c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.552,0.448,1,1,1S5,11.552,5,11z M21,16c0-3.314-1.686-6-5-6s-5,2.686-5,6s1.686,6,5,6S21,19.314,21,16z M29,21c0-0.552-0.448-1-1-1s-1,0.448-1,1 c0,0.552,0.448,1,1,1S29,21.552,29,21z M29,11c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.552,0.448,1,1,1S29,11.552,29,11z">
                  </path>
                </g>
              </svg>
              <div class="w-full flex flex-col text-left justify-center">
                <div class="text-lg font-semibold">{{'schedules.empty.action_schedule_salary' | translate}}</div>
                <div class="w-full">{{'schedules.empty.schedule_salary_description' | translate}}</div>
              </div>
            </div>
          </div>
          <svg class="w-5 h-5 ms-3 mt-6 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9" />
          </svg>
        </label>
      </li>
      <li (click)="goToNewSupplier()">
        <input type="radio" id="hosting-big" name="hosting" value="hosting-big" class="hidden peer">
        <label for="hosting-big"
          class="inline-flex justify-between w-full p-5 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
          <div class="block">
            <div class="flex">
              <svg version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 32 32"
                xml:space="preserve" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <style type="text/css">
                    .duotone_twee {
                      fill: #555D5E;
                    }

                    .duotone_een {
                      fill: #0B1719;
                    }
                  </style>
                  <g>
                    <path class="duotone_twee"
                      d="M18.5,17c-0.827,0-1.5-0.673-1.5-1.5v-3c0-0.827,0.673-1.5,1.5-1.5H27V8.5C27,8.224,26.776,8,26.5,8 h-21C5.224,8,5,8.224,5,8.5V21h22v-4H18.5z">
                    </path>
                    <path class="duotone_een"
                      d="M27,22v3.5c0,0.276-0.224,0.5-0.5,0.5h-21C5.224,26,5,25.776,5,25.5V22H27z M28,12.5v3 c0,0.276-0.224,0.5-0.5,0.5h-9c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5h9C27.776,12,28,12.224,28,12.5z M21,14 c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.552,0.448,1,1,1S21,14.552,21,14z">
                    </path>
                  </g>
                </g>
              </svg>
              <div class="w-full flex flex-col text-left justify-center">
                <div class="text-lg font-semibold">{{'schedules.empty.action_schedule_supplier_payment' | translate}}</div>
                <div class="w-full">{{'schedules.empty.schedule_supplier_payment_description' | translate}}</div>
              </div>
            </div>
          </div>
          <svg class="w-5 h-5 mt-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9" />
          </svg>
        </label>
      </li>
    </ul>
  </div>


</div>
