import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ScheduleListElement } from '../../../models/transaction/transaction-list';
import { PaginationComponent } from '../../pagination/pagination.component';
import { ScheduleItemComponent } from '../schedule-item/schedule-item.component';

@Component({
  selector: 'app-schedules-list',
  standalone: true,
  imports: [TranslateModule, ScheduleItemComponent, CommonModule, PaginationComponent],
  templateUrl: './schedules-list.component.html',
  styleUrl: './schedules-list.component.scss'
})
export class SchedulesList {

  @Output() onNewSchedule = new EventEmitter()
  @Output() onShowAllSchedules = new EventEmitter()
  @Output() onItemClicked = new EventEmitter<ScheduleListElement>()

  @Input('list') schedules: ScheduleListElement[] = []
  @Input() totalResults: number = 0
  @Input() resultsPerPage: number = 0
  @Input() currentPage: number = 0

  newSchedule() {
    this.onNewSchedule.emit()
  }
  itemClicked(schedule: ScheduleListElement)
  {
    this.onItemClicked.emit(schedule)
  }

  handlePageChange(page: number) {

  }
}
