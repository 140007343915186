import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionListElement } from '../../../models/transaction/transaction-list';
import { TransactionItemComponent } from '../transaction-item/transaction-item.component';

@Component({
  selector: 'app-small-transactions-list',
  standalone: true,
  imports: [TranslateModule, TransactionItemComponent, CommonModule],
  templateUrl: './small-transactions-list.component.html',
  styleUrl: './small-transactions-list.component.scss'
})
export class SmallTransactionsListComponent {

  @Output() onNewTransaction = new EventEmitter()
  @Output() onShowAllTransactions = new EventEmitter()
  @Output() onItemClicked = new EventEmitter<TransactionListElement>()

  @Input('list') transactions: TransactionListElement[] = []

  newTransaction() {
    this.onNewTransaction.emit()
  }
  showAllTransactions() {
    this.onShowAllTransactions.emit()
  }
  itemClicked(transaction: TransactionListElement)
  {
    this.onItemClicked.emit(transaction)
  }
}
