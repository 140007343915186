import { Component } from '@angular/core';

@Component({
  selector: 'app-supplier-details-shimmer',
  standalone: true,
  imports: [],
  templateUrl: './supplier-details-shimmer.component.html',
  styleUrl: './supplier-details-shimmer.component.scss'
})
export class SupplierDetailsShimmerComponent {

}
