import { WithdrawalMethodOut } from "../withdrawal-method/withdrawal-method-out"

export class EmployeeOut {
    first_name: string = ""
    first_name_error: Boolean = false
    first_name_error_message: string = ""
    last_name: string = ""
    last_name_error: Boolean = false
    last_name_error_message: string = ""
    birth_date: string = ""
    national_id_number = ""
    phone: string = ""
    email: string = ""
    role: string = ""
    employment_date: string = ""
    employment_type_id: string = ""
    gender_id: string = ""
    withdrawal_method = new WithdrawalMethodOut()
    // main_withdrawal_method = new WithdrawalMethodOut()

    wrap(): EmployeeOutWrapped {
        return new EmployeeOutWrapped(this)
    }
}
export class EmployeeOutWrapped {
    employee: EmployeeOut
    constructor(employee: EmployeeOut) {
        this.employee = employee
    }
}
