import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierOut } from '../../models/suppliers/supplier-out';
import { SuppliersService } from '../../services/suppliers.service';
import { DashboardLayoutComponent } from '../../ui/dashboard-layout/dashboard-layout.component';
import { SupplierFormComponent } from '../../ui/suppliers/supplier-form/supplier-form.component';
import { faSpinner as faLoader } from '@fortawesome/free-solid-svg-icons';
import { StepperComponent } from '../../ui/stepper/stepper.component';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { OperatorIconComponent } from '../../ui/operator-icon/operator-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { parsePhoneNumber } from 'libphonenumber-js';
import { AlertComponent } from '../../ui/alert/alert.component';

@Component({
  selector: 'app-new-supplier',
  standalone: true,
  imports: [
    DashboardLayoutComponent,
    FormsModule,
    ReactiveFormsModule,
    SupplierFormComponent,
    CommonModule,
    TranslateModule,
    StepperComponent,
    FontAwesomeModule,
    OperatorIconComponent,
    AlertComponent
  ],
  templateUrl: './new-supplier.component.html',
  styleUrl: './new-supplier.component.scss'
})
export class NewSupplierComponent implements OnInit, OnDestroy {
  notifyFailure: boolean = false
  steps: string[] = ["Details", "Withdrawal details"]
  currentStep: number = 0
  forUpdate: boolean = false
  supplierDetailsForm!: FormGroup
  supplierWithdrawalDetailsForm!: FormGroup
  selectedPaymentMethod: string = "MOBILE_MONEY"
  paymentMethodMobileMoneySelected: boolean = false
  paymentMethodBankTransferSelected: boolean = false
  categorySelected: any
  bankSelected: any
  typeSelected: any
  mobileMoneySelected: boolean = false
  bankAccountSelected: boolean = false
  categories: any[] = []
  bankOperators: any[] = []
  types: any[] = []
  alertTitle: string = '';
  showAlert: boolean = false;
  alertMessage: string = '';
  alertType: string = 'info';
  loading: boolean = false
  icons = { faLoader }
  accountNumber: string = ''

  constructor(private router: Router, private suppliersService: SuppliersService, private sharedService: SharedService, private translate: TranslateService) {
    this.translate.get('STEPS.NEW.SUPPLIER').subscribe((translatedSteps: string[]) => {
      this.steps = translatedSteps;
    });
  }

  ngOnInit() {
    // const supplier = this.suppliersService.updateTarget
    const supplier = this.suppliersService.fetchedTarget
    this.initializeForm(supplier)

    this.sharedService.getStaticData().subscribe(_ => {
      this.categories = this.sharedService.staticData.business_categories
      this.types = this.sharedService.staticData.supplier_types
      this.bankOperators = this.sharedService.staticData.bank_operators
    })
  }

  initializeForm(supplier: any) {
    if (supplier) {
      this.forUpdate = true
      this.supplierDetailsForm = new FormGroup({
        legalName: new FormControl(supplier?.legal_name, [Validators.required]),
        email: new FormControl(/*supplier?.email*/ null),
        phone: new FormControl(/*supplier?.phone !== null ? supplier?.phone.split('+237')[1] : supplier?.phone*/ null),
        category: new FormControl(supplier?.category_id, [Validators.required]),
        type: new FormControl(supplier?.type_id, [Validators.required]),
      })

      this.supplierWithdrawalDetailsForm = new FormGroup({
        withdrawalIdentifier: new FormControl(supplier.withdrawal_methods.mobile_money !== null ? supplier.withdrawal_methods.mobile_money.identifier.split('+237')[1] : null),
        bankName: new FormControl(supplier.withdrawal_methods.bank_account !== null ? supplier.withdrawal_methods.bank_account.operator_id : null),
        bankCode: new FormControl(supplier.withdrawal_methods.bank_account !== null ? supplier.withdrawal_methods.bank_account.bank_code : null),
        accountNumber: new FormControl(supplier.withdrawal_methods.bank_account !== null ? supplier.withdrawal_methods.bank_account.account_number : null),
        branchCode: new FormControl(supplier.withdrawal_methods.bank_account !== null ? supplier.withdrawal_methods.bank_account.branch_code : null),
        ribKey: new FormControl(supplier.withdrawal_methods.bank_account !== null ? supplier.withdrawal_methods.bank_account.rib_key : null)
      })

      this.types = this.sharedService.staticData.supplier_types
      this.categories = this.sharedService.staticData.business_categories
      this.bankOperators = this.sharedService.staticData.bank_operators

      this.categorySelected = this.categories.find(it => it.id === supplier.category_id)
      this.typeSelected = this.types.find(it => it.id === supplier.type_id)

      if (supplier.withdrawal_methods.mobile_money !== null) {
        this.paymentMethodMobileMoneySelected = true
        this.mobileMoneySelected = true
      }
      if (supplier.withdrawal_methods.bank_account !== null) {
        this.paymentMethodBankTransferSelected = true
        this.bankSelected = this.bankOperators.find(it => it.id === supplier.withdrawal_methods.bank_account.operator_id)?.id
        this.bankAccountSelected = true
      }
    } else {
      this.supplierDetailsForm = new FormGroup({
        legalName: new FormControl(null, [Validators.required]),
        email: new FormControl(null),
        phone: new FormControl(null),
        category: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
      })

      this.supplierWithdrawalDetailsForm = new FormGroup({
        withdrawalIdentifier: new FormControl(null, this.paymentMethodMobileMoneySelected ? Validators.required : null),
        bankName: new FormControl(null, this.paymentMethodBankTransferSelected ? Validators.required : null),
        bankCode: new FormControl(null, this.paymentMethodBankTransferSelected ? Validators.required : null),
        accountNumber: new FormControl(null, this.paymentMethodBankTransferSelected ? [Validators.required, Validators.minLength(11), Validators.maxLength(11)] : null),
        branchCode: new FormControl(null, this.paymentMethodBankTransferSelected ? [Validators.required] : null),
        ribKey: new FormControl(null, this.paymentMethodBankTransferSelected ? Validators.required : null)
      })
    }
  }

  get updateTarget() {
    // return this.suppliersService.updateTarget
    return this.suppliersService.fetchedTarget
  }

  get invalidLegalName() {
    const control = this.supplierDetailsForm.controls['legalName']
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidCategory() {
    const control = this.supplierDetailsForm.controls['category']
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidBankName() {
    const control = this.supplierWithdrawalDetailsForm.controls['bankName']
    return this.paymentMethodBankTransferSelected && (control.dirty || control.touched)
  }

  get invalidType() {
    const control = this.supplierDetailsForm.controls['type']
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidPhoneIdentifier() {
    const control = this.supplierWithdrawalDetailsForm.controls['withdrawalIdentifier']
    return (control?.dirty || control?.touched) && control?.value?.length < 9
  }

  get invalidAccountNumber() {
    const control = this.supplierWithdrawalDetailsForm.get('accountNumber')
    return control?.invalid && (control?.dirty || control?.touched)
  }

  get invalidBankCode() {
    const control = this.supplierWithdrawalDetailsForm.get('bankCode')
    return control?.invalid && (control?.dirty || control?.touched)
  }

  get invalidBranchCode() {
    const control = this.supplierWithdrawalDetailsForm.get('branchCode')
    return control?.invalid && (control?.dirty || control?.touched)
  }

  get invalidRibKey() {
    const control = this.supplierWithdrawalDetailsForm.get('ribKey')
    return control?.invalid && (control?.dirty || control?.touched)
  }

  get invalidAccountNumberLength() {
    const control = this.supplierWithdrawalDetailsForm.get('accountNumber')
    return (control?.dirty || control?.touched) && control?.value?.length < 11
  }

  get invalidBankCodeLength() {
    const control = this.supplierWithdrawalDetailsForm.get('bankCode')
    return (control?.dirty || control?.touched) && control?.value?.length < 5
  }

  get invalidBranchCodeLength() {
    const control = this.supplierWithdrawalDetailsForm.get('branchCode')
    return (control?.dirty || control?.touched) && control?.value?.length < 5
  }

  get invalidRibKeyLength() {
    const control = this.supplierWithdrawalDetailsForm.get('ribKey')
    return (control?.dirty || control?.touched) && control?.value?.length < 2
  }

  onSelectMobileMoney(event: any) {
    this.paymentMethodMobileMoneySelected = event.target.checked
  }

  onSelectBankTransfer(event: any) {
    this.paymentMethodBankTransferSelected = event.target.checked
    if (this.paymentMethodBankTransferSelected) {
      const accountNumber = this.supplierWithdrawalDetailsForm.get('accountNumber')
      const bankCode = this.supplierWithdrawalDetailsForm.get('bankCode')
      const branchCode = this.supplierWithdrawalDetailsForm.get('branchCode')
      const ribKey = this.supplierWithdrawalDetailsForm.get('ribKey')

      accountNumber?.setValidators([Validators.required])
      bankCode?.setValidators([Validators.required])
      branchCode?.setValidators([Validators.required])
      ribKey?.setValidators([Validators.required])

    }
  }

  addSupplier(supplier: SupplierOut) {
    this.loading = true
    this.suppliersService.addSupplier(supplier)
      .subscribe(result => {
        if (null != result) {
          this.loading = false
          this.router.navigate(["/dashboard/suppliers/" + result.supplier.id])
        }
      }, error => {
        this.loading = false
        console.log(error)
        this.notifyFailure = true
      })
  }

  onSelectCategory(event: any) {
    this.supplierDetailsForm.get('category')?.setValue(event.target.value)
  }

  onSelectBank(event: any) {
    this.supplierWithdrawalDetailsForm.get('bankName')?.setValue(event.target.value)
  }

  onSelectType(event: any) {
    this.supplierDetailsForm.get('type')?.setValue(event.target.value)
  }

  goBack() {
    this.currentStep--
  }

  onContinue() {
    if (this.currentStep === 0) {
      if (this.supplierDetailsForm.get('legalName')?.invalid) {
        this.supplierDetailsForm.get('legalName')?.markAsTouched()
        return
      }

      if (this.supplierDetailsForm.get('category')?.invalid) {
        this.supplierDetailsForm.get('category')?.markAsTouched()
        return
      }

      if (this.supplierDetailsForm.get('type')?.invalid) {
        this.supplierDetailsForm.get('type')?.markAsTouched()
        return
      }

      if (this.supplierDetailsForm.get('phone')?.value === null && this.supplierDetailsForm.get('email')?.value === null) {
        this.supplierDetailsForm.get('phone')?.setErrors({ 'required': true })
        this.supplierDetailsForm.get('email')?.setErrors({ 'required': true })
        return
      }

      this.currentStep++
    } else {
      if (!this.paymentMethodMobileMoneySelected && !this.paymentMethodBankTransferSelected) {
        this.showAlert = true
        this.alertType = 'error'
        this.alertMessage = 'Please select a withdrawal method'
        this.alertType = 'error'
        return
      }

      if (this.paymentMethodMobileMoneySelected && (this.invalidPhoneIdentifier || this.sharedService.getMobileMoneyOperator(this.supplierWithdrawalDetailsForm.get('withdrawalIdentifier')?.value) === 'unknown')) {
        this.showAlert = true
        this.alertMessage = 'Please enter a valid phone number'
        this.alertType = 'error'
        return
      }

      if (this.paymentMethodBankTransferSelected) {
        let invalidFields = Object.keys(this.supplierWithdrawalDetailsForm.controls).filter(key => {
          let control = this.supplierWithdrawalDetailsForm.get(key)
          return key !== 'withdrawalIdentifier' && (control?.invalid || null == control?.value)
        })

        if (0 < invalidFields.length) {
          invalidFields.forEach(field => {
            this.supplierWithdrawalDetailsForm.get(field)?.markAsTouched()
          })
          return
        }
      }

      const phoneNum = this.supplierWithdrawalDetailsForm.get('withdrawalIdentifier')?.value

      let withdrawal_methods = {}
      if (this.paymentMethodMobileMoneySelected) {
        withdrawal_methods = {
          ...withdrawal_methods,
          mobile_money: {
            identifier: parsePhoneNumber(phoneNum, 'CM')?.number || phoneNum,
            type: 'MOBILE_MONEY',
            operator_id: this.sharedService.getMobileMoneyOperator(this.supplierWithdrawalDetailsForm.get('withdrawalIdentifier')?.value)
          }
        }
      } else {
        withdrawal_methods = {
          ...withdrawal_methods,
          mobile_money: null
        }
      }

      if (this.paymentMethodBankTransferSelected) {
        withdrawal_methods = {
          ...withdrawal_methods,
          bank_account: {
            type: 'BANK_ACCOUNT',
            operator_id: this.supplierWithdrawalDetailsForm.get('bankName')?.value,
            bank_code: this.supplierWithdrawalDetailsForm.get('bankCode')?.value,
            account_number: this.supplierWithdrawalDetailsForm.get('accountNumber')?.value,
            branch_code: this.supplierWithdrawalDetailsForm.get('branchCode')?.value,
            rib_key: this.supplierWithdrawalDetailsForm.get('ribKey')?.value
          }
        }
      } else {
        withdrawal_methods = {
          ...withdrawal_methods,
          bank_account: null
        }
      }

      const obj = {
        legal_name: this.supplierDetailsForm.get('legalName')?.value,
        email: this.supplierDetailsForm.get('email')?.value,
        phone: this.supplierDetailsForm.get('phone')?.value === null ? this.supplierDetailsForm.get('phone')?.value : parsePhoneNumber(this.supplierDetailsForm.get('phone')?.value, 'CM')?.number,
        category_id: this.supplierDetailsForm.get('category')?.value,
        type_id: this.supplierDetailsForm.get('type')?.value,
        withdrawal_methods
      }

      if (this.forUpdate) {
        this.updateSupplier(obj as SupplierOut)
      } else {
        this.addSupplier(obj as SupplierOut)
      }
    }
  }

  updateSupplier(supplier: SupplierOut) {
    this.loading = true
    this.suppliersService.updateEmployee(supplier, this.updateTarget!.id)
      .subscribe(result => {
        if (null != supplier) {
          this.loading = false
          this.router.navigate(["/dashboard/suppliers"])
        }
      }, error => {
        this.loading = false
        this.notifyFailure = true
      })
  }

  close() {
    history.back()
  }

  ngOnDestroy(): void {
    this.suppliersService.fetchedTarget = null
  }
}
