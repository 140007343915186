import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner as faLoader } from '@fortawesome/free-solid-svg-icons';
import { AlertComponent } from '../../alert/alert.component';
import { Phone } from '../../../models/phone';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierOut } from '../../../models/suppliers/supplier-out';
import { SupplierIn } from '../../../models/suppliers/supplier-in';
import { SuppliersService } from '../../../services/suppliers.service';

@Component({
  selector: 'app-supplier-form',
  standalone: true,
  imports: [FormsModule, CommonModule, FontAwesomeModule, AlertComponent, TranslateModule],
  templateUrl: './supplier-form.component.html',
  styleUrl: './supplier-form.component.scss'
})
export class SupplierFormComponent {
  @Input('onError') showErrorAlert: boolean = false
  @Output() onAddSupplier = new EventEmitter<SupplierOut>()
  @Output() onUpdateSupplier = new EventEmitter<SupplierOut>()
  newSupplier = new SupplierOut()
  @Input('updateTarget') currentSupplier: SupplierIn | null = this.data.updateTarget

  _working: Boolean = false
  errorMessage: string = ""
  icons = { faLoader }

  constructor(private data: SuppliersService) {
    if (null != this.currentSupplier) {
      this.newSupplier.legal_name = this.currentSupplier.legal_name
    }
  }

  get working() {
    return this._working && !this.showErrorAlert
  }
  set working(val: boolean) {
    this.showErrorAlert = false
    this._working = val
  }

  addOrUpdateSupplier() {
    // if (null != this.currentSupplier) this.updateSupplier(); else this.addSupplier()
  }

  // addSupplier() {
  //   this.newSupplier.resetFeedbacks()
  //   this.working = true
  //   if (2 > this.newSupplier.legal_name.length) {
  //     this.newSupplier.first_name_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newSupplier.first_name_error = true
  //   }
  //   // if (2 > this.newSupplier.last_name.length) {
  //   //   this.newSupplier.last_name_error_message = "Veuillez remplir ce champ pour continuer"
  //   //   this.newSupplier.last_name_error = true
  //   // }
  //   if ("" == this.newSupplier.withdrawal_method.identifier) {
  //     this.newSupplier.withdrawal_method.identifier_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newSupplier.withdrawal_method.identifier_error = true
  //     this.working = false
  //     return
  //   }
  //   const identifier = new Phone(this.newSupplier.withdrawal_method.identifier)
  //   if (!identifier.isValid) {
  //     this.newSupplier.withdrawal_method.identifier_error_message = "Veuillez indiquer un numéro valide"
  //     this.newSupplier.withdrawal_method.identifier_error = true
  //   }
  //   if (!this.newSupplier.hasError())
  //   {
  //     this.newSupplier.withdrawal_method.operator_id = identifier.operatorId
  //     this.newSupplier.withdrawal_method.identifier = identifier.phoneNumber
  //     this.onAddSupplier.emit(this.newSupplier)
  //     console.log("Saving supplier:" + this.newSupplier.legal_name + " " + this.newSupplier.last_name + " " + this.newSupplier.withdrawal_method.type + " " + this.newSupplier.withdrawal_method.operator_id + " " + this.newSupplier.withdrawal_method.identifier)
  //   } else {
  //     console.log("Error saving supplier:" + this.newSupplier.legal_name + " " + this.newSupplier.last_name + " " + this.newSupplier.withdrawal_method.type + " " + this.newSupplier.withdrawal_method.operator_id + " " + this.newSupplier.withdrawal_method.identifier)
  //     this.working = false
  //   }
  // }

  // updateSupplier() {
  //   this.newSupplier.resetFeedbacks()
  //   this.working = true
  //   if (2 > this.newSupplier.legal_name.length) {
  //     this.newSupplier.first_name_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newSupplier.first_name_error = true
  //   }
  //   if (2 > this.newSupplier.last_name.length) {
  //     this.newSupplier.last_name_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newSupplier.last_name_error = true
  //   }
  //   if ("" == this.newSupplier.withdrawal_method.identifier) {
  //     this.newSupplier.withdrawal_method.identifier_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newSupplier.withdrawal_method.identifier_error = true
  //     this.working = false
  //     return
  //   }
  //   const identifier = new Phone(this.newSupplier.withdrawal_method.identifier)
  //   if (identifier.isValid) {
  //     this.newSupplier.withdrawal_method.identifier_error_message = "Veuillez indiquer un numéro valide"
  //     this.newSupplier.withdrawal_method.identifier_error = true
  //   }
  //   if (!this.newSupplier.hasError())
  //   {
  //     this.newSupplier.withdrawal_method.identifier = identifier.phoneNumber
  //     this.onUpdateSupplier.emit(this.newSupplier)
  //     console.log("Saving supplier:" + this.newSupplier.legal_name + " " + this.newSupplier.last_name + " " + this.newSupplier.withdrawal_method.type + " " + this.newSupplier.withdrawal_method.identifier)
  //   } else {
  //     console.log("Error saving supplier:" + this.newSupplier.legal_name + " " + this.newSupplier.last_name + " " + this.newSupplier.withdrawal_method.type + " " + this.newSupplier.withdrawal_method.identifier)
  //     this.working = false
  //   }
  // }

  close() {
    history.back()
  }

  ngOnDestroy(): void {
    this.working = false
    this.data.updateTarget = null
  }
}
