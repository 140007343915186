import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ScheduleListElement, TransactionListElement } from '../../../models/transaction/transaction-list'

@Component({
  selector: 'app-schedule-item',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './schedule-item.component.html',
  styleUrl: './schedule-item.component.scss'
})
export class ScheduleItemComponent {
  @Output() onClicked = new EventEmitter<ScheduleListElement>()
  @Input('schedule') item: ScheduleListElement | null = null

  onRedirectToDetails(schedule: ScheduleListElement) {
    this.onClicked.emit(schedule)
  }
}
