import { Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ListEmployeesComponent } from './components/list-employees/list-employees.component';
import { authGuard } from './guards/auth.guard';
import { PreviewComponent } from './components/preview/preview.component';
import { NewEmployeeComponent } from './components/new-employee/new-employee.component';
import { AccountComponent } from './components/account/account.component';
import { ListSuppliersComponent } from './components/list-suppliers/list-suppliers.component';
import { NewSupplierComponent } from './components/new-supplier/new-supplier.component';
import { SchedulesListComponent } from './components/transactions/schedules-list/schedules-list.component';
import { ScheduleDetailsComponent } from './components/transactions/schedule-details/schedule-details.component';
import { BusinessComponent } from './components/business/business.component';
import { SupplierDetailsComponent } from './components/details/supplier-details/supplier-details.component';
import { EmployeeDetailsComponent } from './components/details/employee-details/employee-details.component';
import { KtsComponent } from './components/kts/kts.component';

export const routes: Routes = [
  {
    path: 'dashboard', children: [
      {
        path: 'home',
        component: DashboardComponent
      },
      {
        path: 'business-account',
        component: BusinessComponent
      },
      {
        path: 'employees/new',
        component: NewEmployeeComponent
      },
      {
        path: 'employees/:id',
        component: EmployeeDetailsComponent
      },
      {
        path: 'employees',
        component: ListEmployeesComponent
      },
      {
        path: 'suppliers/new',
        component: NewSupplierComponent
      },
      {
        path: 'suppliers/:id',
        component: SupplierDetailsComponent
      },
      {
        path: 'suppliers',
        component: ListSuppliersComponent
      },
      {
        path: 'preview',
        component: PreviewComponent
      },
      {
        path: 'account',
        component: AccountComponent
      },
      {
        path: 'kts',
        component: KtsComponent
      },
      // {
      //   path: 'bulk-payment',
      //   component: BulkPaymentComponent
      // },
      {
        path: 'transactions',
        loadChildren: () => import('./components/transactions/transactions.routes').then(m => m.TRANSACTIONS_ROUTES)
      },
      {
        path: 'schedules/:id',
        component: ScheduleDetailsComponent
      },
      {
        path: 'schedules',
        component: SchedulesListComponent
      },
      {
        path: '**',
        redirectTo: '/dashboard/home',
        pathMatch: 'full'
      }
    ],
    canActivate: [authGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./components/auth/auth.routes').then(m => m.AUTH_ROUTES)
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
];
