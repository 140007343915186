import { Component, ElementRef, Input, SimpleChange, SimpleChanges, ViewChild } from '@angular/core'

import * as d3 from 'd3'
import { Formatters } from '../../helpers/formatters'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss'
})
export class BarChartComponent {

  constructor(private translate: TranslateService) { }

  @Input('moneyIn') moneyIn: number | null = 0
  @Input('moneyOut') moneyOut: number | null = 0
  @Input('moneyCollected') moneyCollected: number | null = 0
  @ViewChild('canvas') canvasRef: ElementRef | null = null
  collected = 500000

  private svg: any;
  private margin = 50;
  private width = 0;
  private height = 0;

  formatAmount(_amount: number | null, currency: any) {
    const amount = _amount ? _amount : 0
    return Formatters.formatAmount(amount, currency, this.translate.getDefaultLang())
  }

  createSvg() {
    this.svg = d3.create('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr("viewBox", [0, 0, this.width, this.height])
      .attr("style", "width: 100%; height: 100%; margin-bottom: 25px; position: relative; bottom: 30px");
  }

  drawBars(data: any[]): void {
    // Create the X-axis band scale
    const x = d3.scaleBand()
      .range([0, this.width])
      .domain(data.map(d => d.name))
      .padding(0.2);

    const colors = ['rgb(108 214 113)', 'rgb(248, 63, 63)', 'rgb(93 49 255']
    const textColors = ['text-green-500', 'text-red-500', 'colors-primary-text']

    // Draw the X-axis on the DOM
    this.svg.append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      // .attr("transform", "translate(-10,0)rotate(-15)")
      .attr('class', 'fonts-bold uppercase')
      .style("text-anchor", "center")
      .text((d: any) => {
        if (d.indexOf('income') >= 0) return this.translate.instant('summary_chart.income')
        if (d.indexOf('expense') >= 0) return this.translate.instant('summary_chart.expenses')
        if (d.indexOf('profit') >= 0) return this.translate.instant('summary_chart.profit')
        return ""
      })

    // Create the Y-axis band scale
    const maxH = d3.max(data, (d) => d.amount)
    const y = d3.scaleLinear()
      .domain([-10000, maxH])
      .range([this.height, 0])

    // Draw the Y-axis on the DOM
    this.svg.append("g")
      .call(d3.axisLeft(y))

    // Create and fill the bars
    this.svg.selectAll('bars')
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d: any) => x(d.name))
      .attr("y", (d: any) => y(d.amount))
      .attr("width", x.bandwidth())
      .attr("height", (d: any) => this.height - y(d.amount))
      .attr("fill", (d: any, i: number) => {
        if (d.name.indexOf('income') >= 0) return colors[0]
        if (d.name.indexOf('expense') >= 0) return colors[1]
        if (d.name.indexOf('profit') >= 0) return colors[2]
        return
      })

      this.svg.selectAll('bars')
      .data(data)
      .enter()
      .append("text")
      .attr("x", (d: any) => x(d.name)!! + x.bandwidth()/2)
      .attr("y", (d: any) => y(d.amount) - 8)
      .attr("width", x.bandwidth())
      .attr('text-anchor', 'middle')
      .attr("style", "font-weight: bold")
      .text((d: any) => {
        return this.formatAmount(d.amount, 'XAF')
      });

    (this.canvasRef!!.nativeElement as HTMLElement).append(this.svg.node())
  }

  ngOnChanges(changes: SimpleChanges) {
    if (null == changes['moneyIn'].currentValue || null == this.canvasRef) return 

    const diff = (this.moneyIn!! - this.moneyOut!!)
    const data = [
      { amount: this.moneyIn, name: 'income' },
      { amount: this.moneyOut, name: 'expenses' },
      { amount: diff > 0 ? diff : 0, name: 'profit' }
    ]

    this.width = (this.canvasRef!!.nativeElement as HTMLElement).getBoundingClientRect().width
    this.height = (this.canvasRef!!.nativeElement as HTMLElement).getBoundingClientRect().height

    this.createSvg()

    this.drawBars(data)
  }
  ngAfterViewInit() {
    if (null == this.moneyIn || null == this.canvasRef) return 

    const diff = (this.moneyIn!! - this.moneyOut!!)
    const data = [
      { amount: this.moneyIn, name: 'income' },
      { amount: this.moneyOut, name: 'expenses' },
      { amount: diff > 0 ? diff : 0, name: 'profit' }
    ]

    this.width = (this.canvasRef!!.nativeElement as HTMLElement).getBoundingClientRect().width
    this.height = (this.canvasRef!!.nativeElement as HTMLElement).getBoundingClientRect().height

    this.createSvg()

    this.drawBars(data)
  }
}
