import { Component, EventEmitter, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-empty-list',
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule],
  templateUrl: './empty-list.component.html',
  styleUrl: './empty-list.component.scss'
})
export class EmptyListComponent {
  icons = {faPlus}

  @Output() onAddEmployee = new EventEmitter()

  addEmployee() {
    this.onAddEmployee.emit()
  }
}
