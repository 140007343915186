import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EmployeeIn } from '../../models/employee/employee-in';
import { EmployeesService } from '../../services/employees.service';
import { DashboardLayoutComponent } from '../../ui/dashboard-layout/dashboard-layout.component';
import { EmptyListComponent } from '../../ui/employees/empty-list/empty-list.component';
import { FullEmployeesListComponent } from '../../ui/employees/full-employees-list/full-employees-list.component';
import { SharedService } from '../../services/shared.service';
import { switchMap, take } from 'rxjs';
import { EmployeeResp } from '../../models/employee/employee-resp';

@Component({
  selector: 'app-list-employees',
  standalone: true,
  imports: [DashboardLayoutComponent, EmptyListComponent, CommonModule, FullEmployeesListComponent, TranslateModule],
  templateUrl: './list-employees.component.html',
  styleUrl: './list-employees.component.scss'
})
export class ListEmployeesComponent implements OnInit {

  constructor(private router: Router, private data: EmployeesService, private sharedService: SharedService) {

  }


  onNotificationClicked() {

  }
  onActionClicked() {
    this.router.navigate(["dashboard/employees/new"])
  }

  get hasNewItem(): Boolean {
    return this.data.hasNewEmployee
  }
  get alreadyLoaded(): Boolean {
    return this.data.loaded
  }
  get employees(): EmployeeIn[] {
    return this.data.employees.reverse()
  }

  updateEmployee(employee: EmployeeIn) {
    this.data.getEmployee(employee.id).pipe(
      take(1),
      switchMap((employeeObj: EmployeeResp) => {
        this.data.fetchedTarget = employeeObj

        return this.sharedService.getStaticData()
      })
    ).subscribe(resp => {
      this.router.navigate(["dashboard/employees/new"])
    })
  }

  ngOnInit() {
    this.data.listEmployees().subscribe(_ => {})
    this.sharedService.getStaticData().subscribe(_ => {})
  }
}
