import { Component, DestroyRef, ElementRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { DashboardLayoutComponent } from '../../../ui/dashboard-layout/dashboard-layout.component';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TransactionService } from '../../../services/transaction.service';
import { TransactionListShimmerComponent } from '../../skeleton-load/transaction-list-shimmer/transaction-list-shimmer.component';
import { ScheduleListElement, TransactionListElement } from '../../../models/transaction/transaction-list';
import Dinero from 'dinero.js';
import { PaginationComponent } from '../../../ui/pagination/pagination.component';
import { SchedulesList } from '../../../ui/transactions/schedules-list/schedules-list.component'
import { EmptySchedulesListComponent } from '../../../ui/transactions/empty-schedules-list/empty-schedules-list.component';

interface LoadedTransactions {
  page: number;
  transactions: TransactionListElement[];
}

@Component({
  selector: 'app-schedules',
  standalone: true,
  imports: [
    DashboardLayoutComponent,
    CommonModule,
    FormsModule,
    EmptySchedulesListComponent,
    TranslateModule,
    TransactionListShimmerComponent,
    RouterModule,
    PaginationComponent,
    SchedulesList
  ],
  providers: [DatePipe],
  templateUrl: './schedules-list.component.html',
  styleUrl: './schedules-list.component.scss'
})
export class SchedulesListComponent implements OnInit {
  selectedOption: any;
  showPopup = false;
  isDropdownOpen: boolean = false;
  languageRegex = /^fr-[A-Z]{2}$/;
  localeLang: string = this.languageRegex.test(navigator.language) ? 'fr-FR' : navigator.language;

  showDropdown: { [key: string]: boolean } = {};

  dropDownOptions: any[] = [
    {
      value: "date",
      name: "Date",
      options: [
        {
          name: "Aujourd'hui",
          value: "today"
        },
        {
          name: "Hier",
          value: "yesterday"
        },
        {
          name: "Cette semaine",
          value: "this-week"
        },
        {
          name: "Ce mois",
          value: "this-month"
        },
        {
          name: "Personnalisé",
          value: "custom"
        }
      ]
    },
    {
      value: "type",
      name: "Type",
      options: [
        {
          name: "Entrant",
          value: "in"
        },
        {
          name: "Sortant",
          value: "out"
        }
      ]
    },
    {
      value: "montant",
      name: "Montant",
      options: [
        {
          name: "Croissant",
          value: "asc"
        },
        {
          name: "Décroissant",
          value: "desc"
        }
      ]
    },
    {
      value: "statut",
      name: "Statut",
      options: [
        {
          name: "Réussie",
          value: "success"
        },
        {
          name: "En attente",
          value: "pending"
        },
        {
          name: "Echouée",
          value: "failed"
        }
      ]
    }
  ]

  translatedString: string = '';

  @ViewChild('dropdown') dropdown!: ElementRef;

  destroyRef = inject(DestroyRef);
  paginationCurrentPage: number = 1;
  FIRST_PAGE = 1;
  SCHEDULES_PER_PAGE = 10;
  loadedTransactions: LoadedTransactions[] = [];
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private transactionService: TransactionService,
    private translate: TranslateService
  ) { }

  get schedules(): ScheduleListElement[] {
    return this.transactionService.schedules.map((t: any) => {
      t.amount_formatted = this.formatAmount(t.amount)
      return t as ScheduleListElement
    })
  }

  get totalResults(): number {
    return this.schedules.length
  }

  newSupplierTransaction() {
    this.transactionService.schedulingPath = true
    this.router.navigate(['/dashboard/transactions/suppliers/new'])
  }
  newEmployeeTransaction() {
    this.transactionService.schedulingPath = true
    this.router.navigate(['/dashboard/transactions/new'])
  }

  ngOnInit(): void {
    this.translate.get('transactions.list.showing.tx', { count: this.schedules.length })
      .subscribe((translation: string) => {
        this.translatedString = translation;
      });

    this.transactionService.getSchedules(this.FIRST_PAGE, this.SCHEDULES_PER_PAGE).subscribe(_ => { this.isLoading = false});
  }
  showSchedule(transaction: ScheduleListElement) {
    this.router.navigate([`/dashboard/schedules/${transaction.id}`]);
  }
  showAllSchedules() {
    this.router.navigate(['/dashboard/schedules'])
  }
  newSchedule() {
    this.transactionService.scheduling = true
    this.router.navigate(['/dashboard/transactions/new'])
  }

  onNotificationClicked() {

  }

  formatAmount(amount: any): string {
    return Dinero({ amount: amount.value, currency: amount.currency })
      .setLocale(this.localeLang)
      .toFormat('$0,0');
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdownElement = this.dropdown?.nativeElement;

    if (!dropdownElement?.contains(target)) {
      this.isDropdownOpen = false;
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onDropdownSelected(index: number) {
    this.showDropdown[index] = !this.showDropdown[index];
  }

  togglePopup(): void {
    this.showPopup = !this.showPopup;
  }

  onRedirectToDetails(item: TransactionListElement) {
    this.router.navigate([`/dashboard/transactions/${item.id}`]);
  }

  // handlePageChange(event: number) {
  //   if (this.paginationCurrentPage === event) {
  //     return;
  //   }

  //   const loadedTx = this.loadedTransactions.find((tx: any) => tx.page === event);
  //   if (this.loadedTransactions.length > 0 && loadedTx) {
  //     this.schedules = loadedTx.transactions;
  //     this.paginationCurrentPage = event;
  //     return;
  //   } else {
  //     this.fetchTransactions(event, this.SCHEDULES_PER_PAGE);
  //   }
  // }

  // fetchTransactions(page: number, limit: number) {
  //   this.isLoading = true;
  //   this.transactionService.getTransactions(page, this.SCHEDULES_PER_PAGE).pipe(
  //     takeUntilDestroyed(this.destroyRef),
  //     switchMap((tx: any) => {
  //       this.schedules = tx.transactions.map((transaction: any) => {
  //         return {
  //           ...transaction,
  //           performed_at: this.datePipe.transform(transaction.performed_at, 'dd/MM/yyyy, HH:mm'),
  //           amount_formatted: this.formatAmount(transaction.amount)
  //         }
  //       });
  //       this.isLoading = false;
  //       this.paginationCurrentPage = page;
  //       this.totalResults = tx.transactions_count;
  //       this.SCHEDULES_PER_PAGE = limit;

  //         this.loadedTransactions.push({
  //           page,
  //           transactions: this.schedules
  //         });

  //       return this.translate.get('transactions.list.showing.tx', { count: this.schedules.length })
  //     })).subscribe((translation: string) => {
  //       this.translatedString = translation;
  //     });
  // }

}
