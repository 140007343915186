import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { faFilter, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { EmployeeIn } from '../../../models/employee/employee-in';
import { Phone } from '../../../models/phone';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-small-employees-list',
  standalone: true,
  imports: [TranslateModule, CommonModule, FontAwesomeModule],
  templateUrl: './small-employees-list.component.html',
  styleUrl: './small-employees-list.component.scss'
})
export class SmallEmployeesListComponent {
  constructor(translate: TranslateService) {}
  icons = {faFilter, faSearch, faEdit}

  @Input() employees: EmployeeIn[] = []
  @Output() onUpdateEmployee = new EventEmitter<EmployeeIn>()
  @Output() onShowAllEmployees = new EventEmitter()

  manageEmployee(employee: EmployeeIn) {
    this.onUpdateEmployee.emit(employee)
  }

  get headers() {
    return ['', 'fullname', 'reference']
  }

  showAllEmployees() {
    this.onShowAllEmployees.emit()
  }

  phone(employee: EmployeeIn): Phone {
    return new Phone(employee.main_withdrawal_method.identifier.substring(4))
  }
}
