import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OperatorInputPipe } from '../../shared/operator-input.pipe';

@Component({
  selector: 'app-operator-icon',
  standalone: true,
  imports: [OperatorInputPipe, ReactiveFormsModule],
  templateUrl: './operator-icon.component.html',
  styleUrl: './operator-icon.component.scss'
})
export class OperatorIconComponent {
  @Input() customizedForm!: FormGroup;
  @Input() formFieldName!: string;
  @Input() useReactiveForm: boolean = true;

  constructor() { }
}
