import { Component, OnInit } from '@angular/core';
import { DashboardLayoutComponent } from '../../../ui/dashboard-layout/dashboard-layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SupplierDetailsShimmerComponent } from '../../skeleton-load/supplier-details-shimmer/supplier-details-shimmer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { SuppliersService } from '../../../services/suppliers.service';
import { SharedService } from '../../../services/shared.service';
import { SupplierOut } from '../../../models/suppliers/supplier-out';
import { PhoneNumberPipe } from '../../../shared/phone-number.pipe';
import { SupplierResp } from '../../../models/suppliers/supplier-resp';
import { SupplierIn } from '../../../models/suppliers/supplier-in';

@Component({
  selector: 'app-supplier-details',
  standalone: true,
  imports: [
    DashboardLayoutComponent,
    TranslateModule,
    CommonModule,
    SupplierDetailsShimmerComponent,
    PhoneNumberPipe
  ],
  templateUrl: './supplier-details.component.html',
  styleUrl: './supplier-details.component.scss'
})
export class SupplierDetailsComponent implements OnInit {
  isLoading: boolean = false;
  categories: any[] = [];
  types: any[] = [];
  bankOperators: any[] = [];
  type: string = '';
  category: string = '';
  bankName: string = '';
  currentSupplier: SupplierIn = {} as SupplierIn;

  constructor(
    private route: ActivatedRoute,
    private suppliersService: SuppliersService,
    private sharedService: SharedService,
    private router: Router,
    private supplierService: SuppliersService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.pipe(
      switchMap((params: any) => {
        return this.suppliersService.getSupplier(params.id)
      }), switchMap((supplier: any) => {
        this.currentSupplier = supplier;

        return this.sharedService.getStaticData();
      })
    ).subscribe(_ => {
      this.categories = this.sharedService.staticData.business_categories;
      this.types = this.sharedService.staticData.supplier_types;
      this.bankOperators = this.sharedService.staticData.bank_operators;
      this.type = this.types.find(t => t.id == this.currentSupplier.type_id)?.name || '';
      this.category = this.categories.find(c => c.id == this.currentSupplier.category_id)?.name || '';
      this.bankName = this.bankOperators.find(b => b.id == this.currentSupplier.withdrawal_methods.bank_account?.operator_id)?.name || '';
      this.isLoading = false;
    })
  }

  onGoBack() {
    history.back();
  }

  onUpdate() {
    this.supplierService.fetchedTarget = this.currentSupplier;
    this.router.navigate(["/dashboard/suppliers/new"])
  }

  onPay() {
    this.supplierService.fetchedTarget = this.currentSupplier;
    this.router.navigate(["/dashboard/transactions/suppliers/new/single-payment"])
  }
}
