import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFilter, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Phone } from '../../../models/phone';
import { SupplierIn } from '../../../models/suppliers/supplier-in';
import { SharedService } from '../../../services/shared.service';
import { SuppliersService } from '../../../services/suppliers.service';
import { PaginationComponent } from '../../pagination/pagination.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-full-suppliers-list',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, FormsModule, TranslateModule, PaginationComponent],
  templateUrl: './full-suppliers-list.component.html',
  styleUrl: './full-suppliers-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullSuppliersListComponent {
  icons = {faFilter, faSearch, faEdit}

  @Input('suppliers') _suppliers: SupplierIn[] = []
  @Input('isLoading') isLoading: Boolean = true
  @Output() onUpdateSupplier = new EventEmitter<SupplierIn>()

  @Input('selecting') selecting: Boolean = false
  @Input('selectingOne') singleSelection: Boolean = false
  @Input('selectedSuppliers') selectedSuppliers: SupplierIn[] = []
  @Output() onSupplierSelected = new EventEmitter<SupplierIn>()

  currentPage = 1

  constructor(private suppliersService: SuppliersService, private sharedService: SharedService, private router: Router){}

  filter = ""

  handlePageChange(current: number) {
    this.suppliersService.listSuppliers(current).subscribe(_ => {})
    this.currentPage = current
  }

  get supplierTypes() {
    return this.sharedService.staticData.supplier_types
  }
  get supplierCategories() {
    return this.sharedService.staticData.business_categories
  }

  get totalResults() {
    return "" == this.filter ? this.suppliersService.totalResults : this.suppliers.length
  }
  get resultsPerPage() {
    return this.suppliersService.resultsPerPage
  }

  manageSupplier(supplierId: string) {
    // Todo - navigate to the supplier details...
    // then delete this console log
    console.log('About to navigate to the details of ', supplierId)
    this.router.navigate(['dashboard/suppliers', supplierId])
  }

  changeSelection(supplier: SupplierIn) {
    if (!this.singleSelection) {
      if (!supplier.selected) this.selectedSuppliers.push(supplier)
      else this.selectedSuppliers.splice(this.selectedSuppliers.findIndex(it => it.id == supplier.id), 1)
      this.suppliers.find(e => e.id == supplier.id)!!.selected = !supplier.selected
    } else {
      this.suppliersService.resetSelection()
      this.onSupplierSelected.emit(supplier)
      this.suppliers.find(e => e.id == supplier.id)!!.selected = !supplier.selected
    }
  }

  trackById(index: number, item: SupplierIn) {
    return item.id
  }

  get suppliers(): SupplierIn[] {
    return this._suppliers.filter(it => it.pageNumber == this.currentPage).filter(item => "" == this.filter
    || JSON.stringify(item).toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
  }

  filterSuppliers() {
    this.suppliersService.filterSuppliers(this.filter).toPromise()
  }

  get headers() {
    return ['', 'legalname', 'reference', 'type', 'category']
  }

  phone(supplier: SupplierIn): Phone {
    return new Phone(supplier.main_withdrawal_method.identifier.substring(4))
  }

  ngOnInit() {
    const selected = this._suppliers.filter(it => it.selected)
      if (selected.length == 1 && this.singleSelection) this.onSupplierSelected.emit(this._suppliers.filter(it => it.selected)[0])
  }
}
