<app-dashboard-layout [hasAction]="false" [pageTitle]="'employees.title' | translate">
  <div pageContent>
    <div class="mb-6"><app-stepper [steps]="steps" [currentStep]="currentStep"></app-stepper></div>
    <section class="max-w-3xl colors-surface rounded-2xl p-4 lg:py-12 text-center relative mx-auto mb-0">
      <button class="p-2 absolute right-2 lg:right-4 top-2 rounded-2xl text-4xl" (click)="close()">&times;</button>

      <div class="relative lg:px-16 text-center py-1">
        <app-alert [position]="'relative'" [alertTitle]="alertTitle | translate" [showAlert]="showAlert"
          [alertMessage]="alertMessage | translate" [alertType]="alertType" [alertDuration]="50000"></app-alert>
      </div>

      @if (forUpdate) {
      <h1 class="text-title fonts-bold pb-4">{{'employees.update.header' | translate}}</h1>
      } @else {
      <h1 class="text-title fonts-bold pb-4">{{'employees.new.header' | translate}}</h1>
      }
      @if (currentStep == 0) {
      <p>{{'employees.new.step1.desc' | translate}}</p>
      <form [formGroup]="employeePersonalDataForm" class="max-w-lg mx-auto space-y-8 mb-20">
        <div class="inputs-group-span-1">
          <label for="firstname">{{'employees.new.firstname' | translate}}</label>
          <input class="inputs-field-default" type="text" formControlName="firstName"
            [ngClass]="{'colors-border-error focus:outline-red-500': invalidFirstName}" id="firstname">
          @if (invalidFirstName) {
          <label for="firstname" class="alert-error px-2 py-1">{{'employees.new.firstname.error' | translate}}</label>
          }
        </div>
        <div class="inputs-group-span-1">
          <label for="lastname">{{'employees.new.lastname' | translate}}</label>
          <input class="inputs-field-default" [ngClass]="{'colors-border-error focus:outline-red-500': invalidLastName}"
            type="text" formControlName="lastName" id="lastname">
          @if (invalidLastName) {
          <label for="lastname" class="alert-error px-2 py-1">{{'employees.new.lastname.error' | translate}}</label>
          }
        </div>

        <div class="inputs-group-span-1">
          <label for="">{{'employees.new.gender' | translate}}</label>
          <div class="p-2 h-12 rounded-lg shadow w-full flex flex-row items-center justify-center gap-2 bg-[#f2f2f7]">
            <label
              class="inline-flex justify-between w-full items-center rounded-lg p-2 border border-transparent has-[:checked]:border-indigo-500 has-[:checked]:text-indigo-900 has-[:checked]:bg-indigo-50 has-[:checked]:font-bold hover:bg-slate-200 transition-all cursor-pointer has-[:checked]:transition-all has-[:checked]:duration-500 duration-500 relative [&amp;_p]:has-[:checked]:translate-y-0 [&amp;_p]:has-[:checked]:transition-transform [&amp;_p]:has-[:checked]:duration-500 [&amp;_p]:has-[:checked]:opacity-100 overflow-hidden">
              <div class="inline-flex items-center justify-center gap-2 relative">
                <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15 3C15 2.44772 15.4477 2 16 2H20C21.1046 2 22 2.89543 22 4V8C22 8.55229 21.5523 9 21 9C20.4477 9 20 8.55228 20 8V5.41288L15.4671 9.94579C15.4171 9.99582 15.363 10.0394 15.3061 10.0767C16.3674 11.4342 17 13.1432 17 15C17 19.4183 13.4183 23 9 23C4.58172 23 1 19.4183 1 15C1 10.5817 4.58172 7 9 7C10.8559 7 12.5642 7.63197 13.9214 8.69246C13.9587 8.63539 14.0024 8.58128 14.0525 8.53118L18.5836 4H16C15.4477 4 15 3.55228 15 3ZM9 20.9963C5.68831 20.9963 3.00365 18.3117 3.00365 15C3.00365 11.6883 5.68831 9.00365 9 9.00365C12.3117 9.00365 14.9963 11.6883 14.9963 15C14.9963 18.3117 12.3117 20.9963 9 20.9963Z"
                      fill="#0F0F0F"></path>
                  </g>
                </svg>
                <p
                  class="font-semibold absolute inset-0 w-full whitespace-nowrap translate-x-full top-1 left-2 transition-all duration-700">
                  {{'employees.new.gender.male' | translate}}
                </p>
              </div>
              <input type="radio" formControlName="gender" value="male"
                class="checked:text-indigo-500 checked:ring-0 checked:ring-current focus:ring-0 focus:ring-current" />
            </label>
            <label
              class="inline-flex justify-between w-full items-center rounded-lg p-2 border border-transparent has-[:checked]:border-indigo-500 has-[:checked]:text-indigo-900 has-[:checked]:bg-indigo-50 has-[:checked]:font-bold hover:bg-slate-200 transition-all cursor-pointer has-[:checked]:transition-all has-[:checked]:duration-500 duration-500 relative [&amp;_p]:has-[:checked]:translate-y-0 [&amp;_p]:has-[:checked]:transition-transform [&amp;_p]:has-[:checked]:duration-500 [&amp;_p]:has-[:checked]:opacity-100 overflow-hidden">
              <div class="inline-flex items-center justify-center gap-2 relative">
                <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M20 9C20 13.0803 16.9453 16.4471 12.9981 16.9383C12.9994 16.9587 13 16.9793 13 17V19H14C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H13V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V21H10C9.44772 21 9 20.5523 9 20C9 19.4477 9.44772 19 10 19H11V17C11 16.9793 11.0006 16.9587 11.0019 16.9383C7.05466 16.4471 4 13.0803 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9ZM6.00365 9C6.00365 12.3117 8.68831 14.9963 12 14.9963C15.3117 14.9963 17.9963 12.3117 17.9963 9C17.9963 5.68831 15.3117 3.00365 12 3.00365C8.68831 3.00365 6.00365 5.68831 6.00365 9Z"
                      fill="#0F0F0F"></path>
                  </g>
                </svg>
                <p
                  class="font-semibold absolute inset-0 w-full whitespace-nowrap translate-x-full top-1 left-2 transition-all duration-700">
                  {{'employees.new.gender.female' | translate}}
                </p>
              </div>
              <input type="radio" formControlName="gender" value="female"
                class="checked:text-indigo-500 checked:ring-0 checked:ring-current focus:ring-0 focus:ring-current" />
            </label>
          </div>
          @if (invalidGender) {
          <label for="gender" class="alert-error px-2 py-1">{{'employees.new.gender.error' | translate}}</label>
          }

        </div>

        <div class="inputs-group-span-1 mt-1 font-semibold">
          <label for="birthDate">{{'employees.new.birthdate' | translate}}</label>
          <span class="rounded-md shadow-sm">
            <input type="date" id="datepicker" (change)="onSelectStartDate($event)"
              class="datepicker for datepicker-input block w-full pl-3 pr-2 py-2.5 sm:text-sm border-gray-300 rounded-md bg-[#f2f2f7] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Select date" formControlName="birthDate" [max]="getMax18Date()">
          </span>
          @if (invalidBirthDate) {
          <label for="birthDate" class="alert-error px-2 py-1">{{'employees.new.birthdate.error' | translate}}</label>
          }
        </div>
        <div class="inputs-group-span-2">
          <label for="idCard">{{'employees.new.idCard' | translate}}</label>
          <input class="inputs-field-default" [ngClass]="{'colors-border-error focus:outline-red-500': invalidIdCard}"
            type="text" formControlName="idCard">
          @if (invalidIdCard) {
          <label for="idCard" class="alert-error px-2 py-1">{{'employees.new.idcard.error' | translate}}</label>
          }
        </div>
      </form>
      } @else if (currentStep == 1) {
      <p>{{'employees.new.step2.desc' | translate}}</p>
      <form [formGroup]="employeeJobDetailsForm" class="max-w-lg mx-auto space-y-8">
        <div class="inputs-group-span-1">
          <label for="jobTitle">{{'employees.new.jobTitle' | translate}}</label>
          <input class="inputs-field-default" [ngClass]="{'colors-border-error focus:outline-red-500': invalidJobTitle}"
            type="text" formControlName="jobTitle">
          @if (invalidJobTitle) {
          <label for="firstname" class="alert-error px-2 py-1">{{'employees.new.jobTitle.error' | translate}}</label>
          }
        </div>
        <div class="inputs-group-span-1">
          <label for="employmentType">{{'employees.new.employmentType' | translate}}</label>
          <select id="employmentTypes" [(ngModel)]="employmentTypeSelected" (change)="onSelectEmployementType($event)"
            [ngModelOptions]="{standalone: true}"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <!-- <option value="" selected>Select an option</option> -->
            <option *ngFor="let option of employmentTypes" [value]="option.id">{{ option.name }}</option>
          </select>
          @if (invalidEmploymentType) {
          <label for="employmentType" class="alert-error px-2 py-1">{{'employees.new.employmentType.error' |
            translate}}</label>
          }
        </div>
        <div class="inputs-group-span-1 font-semibold mt-1">
          <label for="employmentDate">{{'employees.new.employmentDate' | translate}}</label>
          <span class="rounded-md shadow-sm">
            <input type="date" (change)="onSelectStartDate($event)" formControlName="employmentDate"
              [max]="getCurrentDate()"
              class="datepicker datepicker-input block w-full pl-3 pr-2 py-2.5 sm:text-sm border-gray-300 rounded-md bg-[#f2f2f7] focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Select date">
          </span>
          @if (invalidEmploymentDate) {
          <label for="employmentDate" class="alert-error px-2 py-1">{{'employees.new.employmentDate.error' |
            translate}}</label>
          }
        </div>

        <fieldset class="border border-solid border-gray-300 p-3">
          <legend class="text-base font-semibold">{{'employees.new.notification' | translate}}</legend>

          <div class="relative mb-6 inputs-group-span-2">
            <label for="email">{{'employees.new.nc.email' | translate}}</label>
            <input type="text" id="input-group-1" formControlName="email"
              class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            @if (employeeJobDetailsForm.get('email')?.hasError('required') &&
            employeeJobDetailsForm.get('phone')?.hasError('required')) {
            <label class="alert-error px-2 py-1">{{'employees.new.nc.email.error' | translate}}</label>
            }
          </div>

          <div class="relative inputs-group-span-2">
            <label for="phone">{{'employees.new.nc.phone' | translate}}</label>
            <input type="text" id="input-group-1" formControlName="phone" maxlength="9"
              class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
          </div>
        </fieldset>
      </form>
      } @else {
      <p>{{'employees.new.step3.desc' | translate}}</p>
      <form [formGroup]="employeeWithdrawalDetailsForm" class="block  xl:px-24 lg:px-8">
        <div class="flex items-center justify-center my-5">
          <p class="relative pl-8 py-2">
            <span class="absolute top-0 left-0 h-full bg-blue-600 w-2"></span>
            <span class="ml-4 text-lg fonts-bold">{{'payment.method.desc' | translate}}</span>
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 my-5">
          <div class="border border-gray-200 rounded-md p-4 flex items-center">
            <input id="checkbox-1" type="checkbox" [(ngModel)]="mobileMoneySelected"
              [ngModelOptions]="{standalone: true}" (change)="onSelectMobileMoney($event)" name="checkbox-1"
              class="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-indigo-600">
            <label for="checkbox-1" class="ml-2 font-bold">{{'topup.option.mobile.money.title' |
              translate}}
            </label>
          </div>
          <div class="border border-gray-200 rounded-md p-4 flex items-center">
            <input id="checkbox-2" type="checkbox" [(ngModel)]="bankAccountSelected"
              [ngModelOptions]="{standalone: true}" (change)="onSelectBankTransfer($event)" name="checkbox-2"
              class="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-indigo-600">
            <label for="checkbox-2" class="ml-2 font-bold">{{'topup.option.bank.title' |
              translate}}</label>
          </div>
        </div>

        @if (paymentMethodMobileMoneySelected) {
        <fieldset class="border border-solid border-gray-300 p-3 mb-4">
          <legend class="text-base font-semibold">{{'topup.option.mobile.money.title' |
            translate}}</legend>

          <div class="inputs-group-span-1">
            <label for="phoneNumber">{{'topup.form.phone' | translate}}</label>
            <div class="relative">
              <input class="inputs-field-default w-full" type="text" formControlName="withdrawalIdentifier"
                maxlength="9" [ngClass]="{'colors-border-error focus:outline-red-500': invalidPhoneIdentifier}">
              <app-operator-icon [customizedForm]="employeeWithdrawalDetailsForm"
                [formFieldName]="'withdrawalIdentifier'"></app-operator-icon>
            </div>
            @if (invalidPhoneIdentifier) {
            <label class="alert-error px-2 py-1">Please enter a valid number</label>
            }
          </div>
        </fieldset>
        }

        @if (paymentMethodBankTransferSelected) {
        <fieldset class="border border-solid border-gray-300 p-3">
          <legend class="text-base font-semibold">{{'topup.option.bank.title' | translate}}</legend>

          <div class="space-y-4">
            <div class="inputs-group-span-1">
              <label for="bankName">{{'topup.option.bank.name' | translate}}</label>
              <select [(ngModel)]="bankSelected" (change)="onSelectBank($event)" [ngModelOptions]="{standalone: true}"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <option *ngFor="let option of bankOperators" [value]="option.id">{{ option.name }}</option>
              </select>
              @if (invalidBankName) {
              <label for="bankName" class="alert-error px-2 py-1">{{'suppliers.new.bank.name.error' |
                translate}}</label>
              }
            </div>

            <div class="inputs-group-span-1">
              <label for="accountNumber">{{'withdrawal.method.account.number' | translate}}</label>
              <div class="relative">
                <input class="inputs-field-default w-full" type="text" formControlName="accountNumber"
                  [ngClass]="{'colors-border-error focus:outline-red-500': invalidAccountNumber || invalidAccountNumberLength}"
                  maxlength="11">
                @if (invalidAccountNumber) {
                <label for="accountNumber" class="alert-error px-2 py-1">{{'suppliers.new.account.number.error' |
                  translate}}</label>
                }
                @if (invalidAccountNumberLength) {
                <label for="accountNumber" class="alert-error px-2 py-1">{{'suppliers.new.account.number.minlength' |
                  translate}}</label>
                }
              </div>
            </div>
            <div class="inputs-group-span-1">
              <label for="bankCode">{{'withdrawal.method.bank.bankCode' | translate}}</label>
              <div class="relative">
                <input class="inputs-field-default w-full" type="text"
                  [ngClass]="{'colors-border-error focus:outline-red-500': invalidBankCode || invalidBankCodeLength}"
                  formControlName="bankCode" maxlength="5">
              </div>
              @if (invalidBankCode) {
              <label for="bankCode" class="alert-error px-2 py-1">{{'suppliers.new.bank.code.error' |
                translate}}</label>
              }
              @if (invalidBankCodeLength) {
              <label for="bankCode" class="alert-error px-2 py-1">{{'suppliers.new.bank.code.minlength' |
                translate}}</label>
              }
            </div>
            <div class="inputs-group-span-1">
              <label for="branchCode">{{'withdrawal.method.bank.branchCode' | translate}}</label>
              <div class="relative">
                <input class="inputs-field-default w-full"
                  [ngClass]="{'colors-border-error focus:outline-red-500': invalidBranchCode || invalidBranchCodeLength}"
                  type="text" formControlName="branchCode" maxlength="5">
              </div>
              @if (invalidBranchCode) {
              <label for="branchCode" class="alert-error px-2 py-1">{{'suppliers.new.branch.code.error' |
                translate}}</label>
              }
              @if (invalidBranchCodeLength) {
              <label for="branchCode" class="alert-error px-2 py-1">{{'suppliers.new.branch.code.minlength' |
                translate}}</label>
              }
            </div>

            <div class="inputs-group-span-1">
              <label for="ribKey">{{'withdrawal.method.bank.ribKey' | translate}}</label>
              <div class="relative">
                <input class="inputs-field-default w-full" type="text"
                  [ngClass]="{'colors-border-error focus:outline-red-500': invalidRibKey || invalidRibKeyLength}"
                  formControlName="ribKey" maxlength="2">
              </div>
              @if (invalidRibKey) {
              <label for="ribKey" class="alert-error px-2 py-1">{{'suppliers.new.rib.error' |
                translate}}</label>
              }
              @if (invalidRibKeyLength) {
              <label for="ribKey" class="alert-error px-2 py-1">{{'suppliers.new.rib.minlength' |
                translate}}</label>
              }
            </div>
          </div>
        </fieldset>
        }
      </form>
      }
    </section>


    <div class="w-full md:w-[calc(100%-284px)] fixed md:absolute md:left-[284px] bottom-0 left-0 px-4 md:px-8">
      <div class="w-full flex justify-center py-4">
        @if (currentStep > 0) {
        <button class="px-8 py-2 border colors-primary-border colors-primary-text rounded-lg self-start mr-2"
          (click)="goBack()"><span class="mr-2 font-black">&lang;</span>{{'simple_payment.action_back' |
          translate}}</button>
        }
        @if (currentStep !== (steps.length + 1)) { <button class="px-8 py-2 colors-primary-bg rounded-lg self-start"
          (click)="continue()" *ngIf="!loading">{{currentStep !==
          2
          ? ("bulk_payment.next_action" | translate) :
          ("bulk_payment.confirm_action" | translate)}}<span class="ml-2 font-black">&rang;</span></button>
        }
        <button class="px-8 py-2 colors-primary-bg rounded-lg self-start flex disabled" *ngIf="loading">
          <p class="animate animate-spin mr-4"><fa-icon [icon]="icons.faLoader"></fa-icon></p>
          {{'simple_payment.action_working' | translate}}
        </button>
      </div>
    </div>
  </div>
</app-dashboard-layout>
