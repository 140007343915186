<div class="animate-pulse-children max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
  <div class="bg-white rounded-lg p-3">
    <div class="flex fonts-bold items-center">
      <svg width="34px" height="34px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#10004c">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path fill="#10004c" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path>
          <path fill="#10004c"
            d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z">
          </path>
        </g>
      </svg>
      <span class="text-lg ml-4 w-44 colors-background font-bold">&nbsp;&nbsp;</span>
    </div>
  </div>

  <div class="px-6 py-4">
    <h3 class="text-lg mb-2 bg-slate-300 fonts-bold colors-background px-2">&nbsp;&nbsp;</h3>
    <div class="flex justify-between px-4">
      <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      <p class="w-44 colors-background">&nbsp;&nbsp;</p>
    </div>
    <div class="flex justify-between px-4">
      <p>&nbsp;&nbsp;</p>
      <p>&nbsp;&nbsp;</p>
    </div>
    <div class="flex justify-between px-4">
      <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      <p class="w-44 colors-background">&nbsp;&nbsp;</p>
    </div>
  </div>

  <div class="border-t border-gray-200 px-6 py-4">
    <h3 class="text-lg font-semibold fonts-bold bg-slate-300 mb-2 px-2">&nbsp;&nbsp;</h3>
    <div class="flex justify-between px-4">
      <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      <p class="w-44 colors-background">&nbsp;&nbsp;</p>
    </div>
    <div class="flex justify-between px-4">
      <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      <p class="w-44 colors-background">&nbsp;&nbsp;</p>
    </div>
  </div>

  <div class="border-t border-gray-200 px-6 py-4">
    <h3 class="text-lg fonts-bold bg-slate-300 mb-2 px-2">&nbsp;&nbsp;</h3>
    <fieldset class="border border-solid border-gray-300 p-3 mb-4">
      <legend class="text-base font-semibold colors-background w-44">&nbsp;&nbsp;</legend>

      <div class="flex justify-between px-4">
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      </div>
    </fieldset>

    <fieldset class="border border-solid border-gray-300 p-3">
      <legend class="text-base font-semibold w-44 colors-background">&nbsp;&nbsp;</legend>
      <div class="flex justify-between px-4">
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      </div>
      <div class="flex justify-between px-4">
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      </div>
      <div class="flex justify-between px-4">
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      </div>
      <div class="flex justify-between px-4">
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      </div>
      <div class="flex justify-between px-4">
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
        <p class="w-44 colors-background">&nbsp;&nbsp;</p>
      </div>
    </fieldset>
  </div>

  <div class="flex justify-center mb-4">
    <button class="colors-primary-text rounded-2xl px-8 colors-primary-border py-2 mr-4 border colors-background">&nbsp;&nbsp;</button>
    <!-- <button *ngIf="!canceling" (click)="cancel(currentTransaction)" class="text-orange-600 px-8 py-2 rounded-2xl border border-orange-600">{{'schedules.details.action_cancel' | translate}}</button> -->
    <!-- <button *ngIf="canceling" class="flex text-orange-600 px-8 py-2 rounded-2xl border border-orange-600"><p class="animate animate-spin mr-1"><fa-icon [icon]="icons.faLoader"></fa-icon></p>{{'schedules.details.action_canceling' | translate}}</button> -->
  </div>
</div>
