<li *ngIf="null != item" (click)="onRedirectToDetails(item)"
    class="py-5 colors-background-card cursor-pointer grid grid-cols-1 md:grid-cols-3 w-full md:justify-between border px-4 rounded-lg my-5">
    <div class="flex gap-1 col-span-1">
        <div class="flex-none w-12 h-12 rounded-full">
            @if (item.is_money_in) {
            <ng-container *ngIf="item.is_topup; else normal">
                <div class="flex justify-center items-center color-income-icon-bg w-[48px] h-[48px] rounded-full">
                    <fa-icon class="color-income-icon-text font-bold text-2xl" [icon]="icons.faPlus"></fa-icon>
                </div>
            </ng-container>
            <ng-template #normal>
                <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path opacity="0.5"
                            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
                            fill="#1C39"></path>
                        <path
                            d="M13.5 15.75C13.9142 15.75 14.25 15.4142 14.25 15C14.25 14.5858 13.9142 14.25 13.5 14.25H10.8107L15.5303 9.53033C15.8232 9.23744 15.8232 8.76256 15.5303 8.46967C15.2374 8.17678 14.7626 8.17678 14.4697 8.46967L9.75 13.1893V10.5C9.75 10.0858 9.41421 9.75 9 9.75C8.58579 9.75 8.25 10.0858 8.25 10.5V15C8.25 15.4142 8.58579 15.75 9 15.75H13.5Z"
                            fill="#1C39"></path>
                    </g>
                </svg>
            </ng-template>
            } @else {
            <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path opacity="0.5"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        fill="#f83f3f"></path>
                    <path
                        d="M10.5 8.25C10.0858 8.25 9.75 8.58579 9.75 9C9.75 9.41421 10.0858 9.75 10.5 9.75H13.1893L8.46967 14.4697C8.17678 14.7626 8.17678 15.2374 8.46967 15.5303C8.76256 15.8232 9.23744 15.8232 9.53033 15.5303L14.25 10.8107V13.5C14.25 13.9142 14.5858 14.25 15 14.25C15.4142 14.25 15.75 13.9142 15.75 13.5V9C15.75 8.58579 15.4142 8.25 15 8.25H10.5Z"
                        fill="#f83f3f"></path>
                </g>
            </svg>
            }
        </div>
        <div>
            <span class="block text-lg font-medium" [textContent]="item.concept"></span>
            <span class="block text-xs">{{item.performed_at | date : 'dd/MM/yyyy, HH:mm'}}</span>
        </div>
    </div>
    <span [ngClass]="{
                    'color-failed-status': item.status === 'ERROR',
                    'color-complete-status': item.status === 'COMPLETED',
                    'color-pending-status': item.status !== 'ERROR' && item.status !== 'COMPLETED'
                  }" class="flex justify-center pt-3 col-span-1">{{'transactions_list.item_status_' + (item.status != 'ERROR' && item.status != 'COMPLETED' ? 'pending' : item.status.toLowerCase()) | translate}}</span>
    <div class="flex md:flex-col col-span-1 md:items-end justify-between items-center">
        <span>{{item.amount_formatted}}</span>
        <span class="text-xs">{{item.peer_label}}</span>
    </div>
</li>