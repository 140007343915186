import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-website-icon',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './website-icon.component.html',
  styleUrl: './website-icon.component.scss'
})
export class WebsiteIconComponent {
  isModalOpen = false;
  copied: boolean = false;
  @Input() website = 'https://www.example.com';

  isInputOpen: boolean = false;
  // website: string = ''; // Initialize with appropriate value

  toggleInput(): void {
    this.isInputOpen = !this.isInputOpen;
  }

  copyWebsite() {
    if (this.website) {
      navigator.clipboard.writeText(this.website);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    }

  }
}
