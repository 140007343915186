import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BusinessService } from '../../services/business.service';
import { BusinessUiComponent } from '../../ui/business-ui/business-ui.component';
import { DashboardLayoutComponent } from '../../ui/dashboard-layout/dashboard-layout.component';

@Component({
  selector: 'app-business',
  standalone: true,
  imports: [DashboardLayoutComponent, TranslateModule, BusinessUiComponent],
  templateUrl: './business.component.html',
  styleUrl: './business.component.scss'
})
export class BusinessComponent {

  constructor(private businessData: BusinessService) {
  }

  get loading() {
    return !this.businessData.loaded
  }

  get business() {
    return this.businessData.infos
  }

  update() {
    console.log("About to update")
  }

  onNotificationClicked() { }

  onActionClicked() { }

  ngOnInit() {
    this.businessData.getInfos().subscribe(_ => { })
  }
}
