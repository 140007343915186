<div class="p-4 colors-surface rounded-2xl h-full lg:flex flex-col justify-between">
    <div>
        <div class="w-full inline-flex justify-between mb-4">
            <h2 class="text-lg">{{title}}</h2>
            <p class="text-xs capitalize">{{comment}}</p>
        </div>
        <h1 class="text-title mb-4 fonts-bold">{{formatAmount(amount, currency)}}</h1>
    </div>
    <button [disabled]="disabled" class="disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-50 colors-background py-2 px-6 self-start rounded-xl fonts-normal"
        (click)="onActionClicked()">{{actionLabel}}</button>
</div>
