import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EmployeeOut } from '../../models/employee/employee-out';
import { EmployeesService } from '../../services/employees.service';
import { DashboardLayoutComponent } from '../../ui/dashboard-layout/dashboard-layout.component';
import { EmployeeFormComponent } from '../../ui/employees/employee-form/employee-form.component';
import { StepperComponent } from '../../ui/stepper/stepper.component';
import { faSpinner as faLoader } from '@fortawesome/free-solid-svg-icons';
import parsePhoneNumber from 'libphonenumber-js';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StaticData } from '../../models/static-data';
import { OperatorIconComponent } from '../../ui/operator-icon/operator-icon.component';
import { AlertComponent } from '../../ui/alert/alert.component';

@Component({
  selector: 'app-new-employee',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DashboardLayoutComponent,
    EmployeeFormComponent,
    CommonModule,
    TranslateModule,
    StepperComponent,
    FontAwesomeModule,
    OperatorIconComponent,
    AlertComponent
  ],
  templateUrl: './new-employee.component.html',
  styleUrl: './new-employee.component.scss'
})
export class NewEmployeeComponent implements OnInit, OnDestroy {
  notifyFailure: boolean = false
  employeePersonalDataForm!: FormGroup
  employeeJobDetailsForm!: FormGroup
  employeeWithdrawalDetailsForm!: FormGroup
  steps: string[] = ["Personal Data", "Job details", "Withdrawal details"]
  currentStep: number = 0
  icons = { faLoader }
  loading: boolean = false
  alertTitle: string = ''
  showAlert: boolean = false
  alertMessage: string = ''
  alertType: string = 'info'
  selectedPaymentMethod: string = "MOBILE_MONEY"
  employmentTypeSelected: any
  genders: any[] = []
  forUpdate: boolean = false
  employmentTypes: any[] = []
  mobileMoneySelected: boolean = false
  bankAccountSelected: boolean = false
  currentEmployee: EmployeeOut | null = null
  destroyRef = inject(DestroyRef)
  bankSelected: any
  bankOperators: any[] = []
  paymentMethodMobileMoneySelected: boolean = false
  paymentMethodBankTransferSelected: boolean = false

  constructor(
    private router: Router,
    private employeesService: EmployeesService,
    private sharedService: SharedService,
    private translate: TranslateService
  ) {
    this.translate.get('STEPS.NEW.EMPLOYEE').subscribe((translatedSteps: string[]) => {
      this.steps = translatedSteps;
    });
  }

  ngOnInit(): void {
    const employee = this.employeesService.fetchedTarget
    // this.currentEmployee = this.employeesService.updateTarget as any
    this.currentEmployee = this.employeesService.fetchedTarget as any

    this.initializeForm(employee)

    this.sharedService.getStaticData().subscribe(_ => {
      this.genders = this.sharedService.staticData?.genders
      this.employmentTypes = this.sharedService.staticData?.employment_types
      this.bankOperators = this.sharedService.staticData.bank_operators
    })
  }

  initializeForm(employee: any) {
    if (employee) {
      this.forUpdate = true
      this.employeePersonalDataForm = new FormGroup({
        firstName: new FormControl(employee.first_name, [Validators.required]),
        lastName: new FormControl(employee.last_name, [Validators.required]),
        gender: new FormControl(employee.gender_id, [Validators.required]),
        birthDate: new FormControl(employee.birth_date, [Validators.required]),
        idCard: new FormControl(employee.national_id_number, [Validators.required]),
      })

      this.employeeJobDetailsForm = new FormGroup({
        jobTitle: new FormControl(employee.role, [Validators.required]),
        employmentType: new FormControl(employee.employment_type_id, [Validators.required]),
        employmentDate: new FormControl(employee.employment_date, [Validators.required]),
        email: new FormControl(employee.email),
        phone: new FormControl(employee?.phone !== null ? employee?.phone.split('+237')[1] : employee?.phone),
      })

      this.employeeWithdrawalDetailsForm = new FormGroup({
        withdrawalIdentifier: new FormControl(employee.withdrawal_methods.mobile_money !== null ? employee.withdrawal_methods.mobile_money.identifier.split('+237')[1] : null),
        bankName: new FormControl(employee.withdrawal_methods.bank_account !== null ? employee.withdrawal_methods.bank_account.operator_id : null),
        bankCode: new FormControl(employee.withdrawal_methods.bank_account !== null ? employee.withdrawal_methods.bank_account.bank_code : null),
        accountNumber: new FormControl(employee.withdrawal_methods.bank_account !== null ? employee.withdrawal_methods.bank_account.account_number : null),
        branchCode: new FormControl(employee.withdrawal_methods.bank_account !== null ? employee.withdrawal_methods.bank_account.branch_code : null),
        ribKey: new FormControl(employee.withdrawal_methods.bank_account !== null ? employee.withdrawal_methods.bank_account.rib_key : null)
      })
      this.employmentTypes = this.sharedService.staticData?.employment_types
      this.employmentTypeSelected = this.employmentTypes.find((type: any) => type.id === employee.employment_type_id)?.id

      this.genders = this.sharedService.staticData?.genders
      this.employmentTypes = this.sharedService.staticData?.employment_types
      this.bankOperators = this.sharedService.staticData.bank_operators

      if (employee.withdrawal_methods.mobile_money !== null) {
        this.paymentMethodMobileMoneySelected = true
        this.mobileMoneySelected = true
      }
      if (employee.withdrawal_methods.bank_account !== null) {
        this.paymentMethodBankTransferSelected = true
        this.bankSelected = this.bankOperators.find(it => it.id === employee.withdrawal_methods.bank_account.operator_id)?.id
        this.bankAccountSelected = true
      }
      // this.selectedPaymentMethod = employee.main_withdrawal_method.type
    } else {
      this.employeePersonalDataForm = new FormGroup({
        firstName: new FormControl(null, [Validators.required]),
        lastName: new FormControl(null, [Validators.required]),
        gender: new FormControl(null, [Validators.required]),
        birthDate: new FormControl(null, [Validators.required]),
        idCard: new FormControl(null, [Validators.required]),
      })

      this.employeeJobDetailsForm = new FormGroup({
        jobTitle: new FormControl(null, [Validators.required]),
        employmentType: new FormControl(null, [Validators.required]),
        employmentDate: new FormControl(null, [Validators.required]),
        email: new FormControl(null),
        phone: new FormControl(null),
      })

      this.employeeWithdrawalDetailsForm = new FormGroup({
        withdrawalIdentifier: new FormControl(null, this.paymentMethodMobileMoneySelected ? Validators.required : null),
        bankName: new FormControl(null, this.paymentMethodBankTransferSelected ? Validators.required : null),
        bankCode: new FormControl(null, this.paymentMethodBankTransferSelected ? Validators.required : null),
        accountNumber: new FormControl(null, this.paymentMethodBankTransferSelected ? Validators.required : null),
        branchCode: new FormControl(null, this.paymentMethodBankTransferSelected ? Validators.required : null),
        ribKey: new FormControl(null, this.paymentMethodBankTransferSelected ? Validators.required : null)
      })
    }
  }

  get updateTarget() {
    // return this.employeesService.updateTarget
    return this.employeesService.fetchedTarget
  }

  get invalidFirstName() {
    const control = this.employeePersonalDataForm.get('firstName')
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidLastName() {
    const control = this.employeePersonalDataForm.get('lastName')
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidGender() {
    const control = this.employeePersonalDataForm.get('gender')
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidBirthDate() {
    const control = this.employeePersonalDataForm.get('birthDate')
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidIdCard() {
    const control = this.employeePersonalDataForm.get('idCard')
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidJobTitle() {
    const control = this.employeeJobDetailsForm.get('jobTitle')
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidPhoneIdentifier() {
    const control = this.employeeWithdrawalDetailsForm.controls['withdrawalIdentifier']
    return (control?.dirty || control?.touched) && control?.value.length < 9
  }

  get invalidEmploymentType() {
    const control = this.employeeJobDetailsForm.get('employmentType')
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidEmploymentDate() {
    const control = this.employeeJobDetailsForm.get('employmentDate')
    return control?.invalid && (control.dirty || control.touched)
  }

  get invalidAccountNumber() {
    const control = this.employeeWithdrawalDetailsForm.get('accountNumber')
    return control?.invalid && (control?.dirty || control?.touched)
  }

  get invalidBankCode() {
    const control = this.employeeWithdrawalDetailsForm.get('bankCode')
    return control?.invalid && (control?.dirty || control?.touched)
  }

  get invalidBranchCode() {
    const control = this.employeeWithdrawalDetailsForm.get('branchCode')
    return control?.invalid && (control?.dirty || control?.touched)
  }

  get invalidRibKey() {
    const control = this.employeeWithdrawalDetailsForm.get('ribKey')
    return control?.invalid && (control?.dirty || control?.touched)
  }

  get invalidBankName() {
    const control = this.employeeWithdrawalDetailsForm.controls['bankName']
    return this.paymentMethodBankTransferSelected && (control.dirty || control.touched)
  }

  get invalidPhone() {
    const control = this.employeeWithdrawalDetailsForm.controls['withdrawalIdentifier']
    return (control?.dirty || control?.touched) && control?.value.length < 9 || this.sharedService.getMobileMoneyOperator(control?.value ?? '') === 'unknown'
  }

  get invalidAccountNumberLength() {
    const control = this.employeeWithdrawalDetailsForm.get('accountNumber')
    return (control?.dirty || control?.touched) && control?.value?.length < 11
  }

  get invalidBankCodeLength() {
    const control = this.employeeWithdrawalDetailsForm.get('bankCode')
    return (control?.dirty || control?.touched) && control?.value?.length < 5
  }

  get invalidBranchCodeLength() {
    const control = this.employeeWithdrawalDetailsForm.get('branchCode')
    return (control?.dirty || control?.touched) && control?.value?.length < 5
  }

  get invalidRibKeyLength() {
    const control = this.employeeWithdrawalDetailsForm.get('ribKey')
    return (control?.dirty || control?.touched) && control?.value?.length < 2
  }

  addEmployee(employee: EmployeeOut) {
    this.employeesService.addEmployee(employee)
      .subscribe(result => {
        if (null != result) {
          this.router.navigate(["/dashboard/employees/"])
        }
      }, error => {
        this.notifyFailure = true
      })
  }

  formatPhoneNumber(phone: string): string {
    return parsePhoneNumber(phone, 'CM')?.formatNational() || phone
  }

  addEmployeeUpdated(employee: any) {
    this.loading = true;
    this.employeesService.newEmployee(employee).subscribe({
      next: (response: any) => {
        this.loading = false
        this.router.navigate(["/dashboard/employees/" + response.employee.id])
      },
      error: (error) => {
        this.loading = false
      }
    })
  }

  onSelectEmployementType(event: any) {
    this.employeeJobDetailsForm.get('employmentType')?.setValue(event.target.value)
  }

  updateEmployee(employee: EmployeeOut) {
    this.employeesService.updateEmployee(employee, this.updateTarget!.id)
      .subscribe(result => {
        if (null != employee) {
          this.router.navigate(["/dashboard/employees"])
        }
      }, error => {
        this.notifyFailure = true
      })
  }

  newUpdateEmployee(employee: any) {
    this.loading = true
    this.employeesService.updateEmployee2(employee, this.updateTarget!.id).subscribe({
      next: (response) => {
        this.loading = false
        this.router.navigate(["/dashboard/employees"])
      },
      error: (error) => {
        this.loading = false
      }
    })
  }

  continue() {
    if (this.currentStep === 0) {
      let invalidFields = Object.keys(this.employeePersonalDataForm.controls).filter(key => {
        let control = this.employeePersonalDataForm.get(key)
        return control?.invalid || null == control?.value
      })

      if (0 < invalidFields.length) {
        invalidFields.forEach(field => {
          this.employeePersonalDataForm.get(field)?.markAsTouched()
        })
        return
      }

      this.currentStep++
    } else if (this.currentStep === 1) {
      let invalidFields = Object.keys(this.employeeJobDetailsForm.controls).filter(key => {
        let control = this.employeeJobDetailsForm.get(key)
        return key !== 'email' && key !== 'phone' && (control?.invalid || null == control?.value)
      })

      if (0 < invalidFields.length) {
        invalidFields.forEach(field => {
          this.employeeJobDetailsForm.get(field)?.markAsTouched()
        })
        return;
      }

      if (this.employeeJobDetailsForm.get('phone')?.value === null && this.employeeJobDetailsForm.get('email')?.value === null) {
        this.employeeJobDetailsForm.get('phone')?.setErrors({ 'required': true })
        this.employeeJobDetailsForm.get('email')?.setErrors({ 'required': true })
        return;
      }

      this.currentStep++
    } else {
      if (!this.paymentMethodMobileMoneySelected && !this.paymentMethodBankTransferSelected) {
        this.showAlert = true
        this.alertType = 'error'
        this.alertMessage = 'Please select a withdrawal method'
        this.alertType = 'error'
        return
      }

      if (this.paymentMethodMobileMoneySelected && (this.invalidPhoneIdentifier || this.sharedService.getMobileMoneyOperator(this.employeeWithdrawalDetailsForm.get('withdrawalIdentifier')?.value) === 'unknown')) {
        this.showAlert = true
        this.alertMessage = 'Please enter a valid phone number'
        this.alertType = 'error'
        return
      }

      if (this.paymentMethodBankTransferSelected) {
        let invalidFields = Object.keys(this.employeeWithdrawalDetailsForm.controls).filter(key => {
          let control = this.employeeWithdrawalDetailsForm.get(key)
          return key !== 'withdrawalIdentifier' && (control?.invalid || null == control?.value)
        })

        if (0 < invalidFields.length) {
          invalidFields.forEach(field => {
            this.employeeWithdrawalDetailsForm.get(field)?.markAsTouched()
          })
          return
        }
      }

      const employeeObject = {
        first_name: this.employeePersonalDataForm.get('firstName')?.value,
        last_name: this.employeePersonalDataForm.get('lastName')?.value,
        gender_id: this.employeePersonalDataForm.get('gender')?.value,
        birth_date: this.employeePersonalDataForm.get('birthDate')?.value,
        national_id_number: this.employeePersonalDataForm.get('idCard')?.value,
        phone: this.employeeJobDetailsForm.get('phone')?.value !== null ? parsePhoneNumber(this.employeeJobDetailsForm.get('phone')?.value?.toString(), 'CM')?.number : null,
        email: this.employeeJobDetailsForm.get('email')?.value,
        role: this.employeeJobDetailsForm.get('jobTitle')?.value,
        employment_date: this.employeeJobDetailsForm.get('employmentDate')?.value,
        employment_type_id: this.employeeJobDetailsForm.get('employmentType')?.value,
        withdrawal_methods: this.buildWithdrawalMethod()
      }

      if (this.forUpdate) {
        this.newUpdateEmployee({ employee: employeeObject })
      } else {
        this.addEmployeeUpdated({ employee: employeeObject })
      }
    }
  }

  buildWithdrawalMethod() {
    const phoneNum = this.employeeWithdrawalDetailsForm.get('withdrawalIdentifier')?.value

    let withdrawal_methods = {}
    if (this.paymentMethodMobileMoneySelected) {
      withdrawal_methods = {
        ...withdrawal_methods,
        mobile_money: {
          identifier: parsePhoneNumber(phoneNum, 'CM')?.number || phoneNum,
          type: 'MOBILE_MONEY',
          operator_id: this.sharedService.getMobileMoneyOperator(this.employeeWithdrawalDetailsForm.get('withdrawalIdentifier')?.value)
        }
      }
    } else {
      withdrawal_methods = {
        ...withdrawal_methods,
        mobile_money: null
      }
    }

    if (this.paymentMethodBankTransferSelected) {
      withdrawal_methods = {
        ...withdrawal_methods,
        bank_account: {
          type: "BANK_ACCOUNT",
          operator_id: this.employeeWithdrawalDetailsForm.get('bankName')?.value,
          bank_code: this.employeeWithdrawalDetailsForm.get('bankCode')?.value,
          account_number: this.employeeWithdrawalDetailsForm.get('accountNumber')?.value,
          branch_code: this.employeeWithdrawalDetailsForm.get('branchCode')?.value,
          rib_key: this.employeeWithdrawalDetailsForm.get('ribKey')?.value
        }
      }
    } else {
      withdrawal_methods = {
        ...withdrawal_methods,
        bank_account: null
      }
    }

    return withdrawal_methods
  }

  goBack() {
    this.currentStep--
  }

  onSelectStartDate(event: any) {

  }

  close() {
    history.back()
  }

  checkFormValidity() {
    if (this.currentStep === 0) {
      return this.employeePersonalDataForm.valid
    } else if (this.currentStep === 1) {
      return this.employeeJobDetailsForm.valid
    } else {
      return this.employeeWithdrawalDetailsForm.valid
    }
  }

  getCurrentDate(): string {
    const currentDate = new Date().toISOString().split('T')[0];
    return currentDate;
  }

  getMax18Date(): string {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 18)
    return currentDate.toISOString().split('T')[0];
  }

  onSelectMobileMoney(event: any) {
    this.paymentMethodMobileMoneySelected = event.target.checked
  }

  onSelectBank(event: any) {
    this.employeeWithdrawalDetailsForm.get('bankName')?.setValue(event.target.value)
  }

  onSelectBankTransfer(event: any) {
    this.paymentMethodBankTransferSelected = event.target.checked
    if (this.paymentMethodBankTransferSelected) {
      const accountNumber = this.employeeWithdrawalDetailsForm.get('accountNumber')
      const bankCode = this.employeeWithdrawalDetailsForm.get('bankCode')
      const branchCode = this.employeeWithdrawalDetailsForm.get('branchCode')
      const ribKey = this.employeeWithdrawalDetailsForm.get('ribKey')

      accountNumber?.setValidators([Validators.required])
      bankCode?.setValidators([Validators.required])
      branchCode?.setValidators([Validators.required])
      ribKey?.setValidators([Validators.required])

    }
  }

  ngOnDestroy(): void {
    this.employeesService.fetchedTarget = null
  }
}
