<app-dashboard-layout [pageTitle]="pageTitle" [hasAction]="false"
    (onNotificationClick)="onNotificationClicked()" (onAction)="onActionClicked()">
    @if (!(hasEmployees || hasSuppliers)) {
    <div customActionButton #dropdown>
        <button id="dropdownDefaultButton" (click)="toggleDropdown()"
        [attr.data-dropdown-toggle]="isDropdownOpen ? 'dropdown' : null"
        class="colors-primary-border text-sm font-medium md:text-xl colors-primary-bg px-5 py-2.5 text-center inline-flex items-center rounded-2xl"
        type="button"><fa-icon [icon]="icons.faPlus" class="mr-2"></fa-icon> {{'dashboard.home.action_add_collabo' | translate}} <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
        </svg>
        </button>

        <div id="dropdown" [ngClass]="{'hidden': !isDropdownOpen}"
        class="z-10 fixed text-sm colors-on-background md:text-xl dropdown-button divide-y rounded-lg shadow md:w-56 w-44">
        <ul class="py-2" [attr.aria-labelledby]="isDropdownOpen ? 'dropdownDefaultButton' : null">
            <li>
            <a [routerLink]="['/dashboard/employees/new']" class="block px-4 py-2 dropdown-button">{{'employees.action.add' |
                translate}}</a>
            </li>
            <li>
            <a [routerLink]="['/dashboard/suppliers/new']" class="block px-4 py-2 dropdown-button">{{'suppliers.action.add' |
                translate}}</a>
            </li>
            <li>
        </ul>
        </div>
    </div>
    }
    @if (hasEmployees || hasSuppliers) {
    <div customActionButton #dropdown>
        <button id="dropdownDefaultButton" (click)="toggleDropdown()"
        [attr.data-dropdown-toggle]="isDropdownOpen ? 'dropdown' : null"
        class="colors-primary-border text-sm font-medium md:text-xl colors-primary-bg px-5 py-2.5 text-center inline-flex items-center rounded-2xl"
        type="button">{{'transactions.action.new' | translate}} <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
        </svg>
        </button>

        <div id="dropdown" [ngClass]="{'hidden': !isDropdownOpen}"
        class="z-10 fixed text-sm colors-on-background md:text-xl dropdown-button divide-y rounded-lg shadow md:w-56 w-44">
        <ul class="py-2" [attr.aria-labelledby]="isDropdownOpen ? 'dropdownDefaultButton' : null">
            <li>
            <a [routerLink]="['/dashboard/transactions/new']" class="block px-4 py-2 dropdown-button">{{'transactions.action.new.salary' |
                translate}}</a>
            </li>
            <li>
            <a [routerLink]="['/dashboard/transactions/suppliers/new']" class="block px-4 py-2 dropdown-button">{{'transactions.action.new.supplier' |
                translate}}</a>
            </li>
            <li>
        </ul>
        </div>
    </div>
    }
    <div pageContent class="flex flex-col md:grid md:grid-cols-6 md:grid-flow-row md:items-stretch gap-2 lg:gap-4">
        <app-summary-card (onAction)="onSummaryActionClicked(1)" class="row-span-2 col-span-2" [title]="'Futa Wallet'"
            [comment]="date" [currency]="summary?.wallet_balance?.currency" [amount]="summary ? (summary?.wallet_balance?.value) : 0" [actionLabel]="'wallet.refill' | translate">
        </app-summary-card>
        <app-summary-card (onAction)="onSummaryActionClicked(2)" [disabled]="!(hasEmployees || hasSuppliers)" class="row-span-2 col-span-2" [title]="'payment.next' | translate"
            [amount]="summary && summary.next_schedule ? (summary?.next_schedule?.total_amount?.value) : 0" [comment]="nextPaymentDate(summary?.next_schedule?.schedule_start_date)" [actionLabel]="(null == summary?.next_schedule ? 'payment.schedule' : 'dashboard.review_schedule') | translate">
        </app-summary-card>
        <app-summary-chip class="col-span-2" [title]="'chips.total.salary' | translate" [currency]="summary?.employee_total_salary_paid.currency" [amount]="summary?.employee_total_salary_paid.value" [frequency]="1">
        </app-summary-chip>
        <app-summary-chip class="col-span-2" [title]="'chips.total.bills' | translate" [currency]="summary?.supplier_total_paid.currency" [amount]="summary?.supplier_total_paid.value" [defaultColor]="false"
            [frequency]="1"></app-summary-chip>
        <app-small-transactions-list (onItemClicked)="showTransaction($event)" (onShowAllTransactions)="showAllTransactions()" (onNewTransaction)="newTransaction()" [list]="transactions" class="md:col-span-3 2xl:col-span-4 self-stretch"></app-small-transactions-list>
        <app-bar-chart [moneyCollected]="summary?.total_money_collected?.value" [moneyOut]="summary?.total_money_out?.value" [moneyIn]="summary?.total_money_in?.value" class="md:col-span-3 2xl:col-span-2"></app-bar-chart>
        <!-- <app-small-employees-list (onShowAllEmployees)="showAllEmployees()" class="md:col-span-3 2xl:col-span-2" [employees]="employees"></app-small-employees-list> -->
    </div>
</app-dashboard-layout>
