import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardLayoutComponent } from '../../ui/dashboard-layout/dashboard-layout.component';
import { SimplePaymentFormComponent } from '../../ui/payments/simple-payment-form/simple-payment-form.component';
import { SimpleSummaryComponent } from '../../ui/payments/simple-summary/simple-summary.component';

@Component({
  selector: 'app-preview',
  standalone: true,
  imports: [DashboardLayoutComponent, SimplePaymentFormComponent, TranslateModule],
  templateUrl: './preview.component.html',
  styleUrl: './preview.component.scss'
})
export class PreviewComponent {

}
