import { BankAccountOut } from "../withdrawal-method/bank-account-out"
import { MobileMoneyOut } from "../withdrawal-method/mobile-money-out"
import { WithdrawalMethodOut } from "../withdrawal-method/withdrawal-method-out"

export class SupplierOut {
    legal_name: string = ""
    type_id: string = ""
    category_id: string = ""
    phone: string = ""
    email: string = ""
    reference: string = ""
    // withdrawal_method = new WithdrawalMethodOut()
    withdrawal_methods = {
      mobile_money: new MobileMoneyOut(),
      bank_account: new BankAccountOut()
    }

    wrap(): SupplierOutWrapped {
        return new SupplierOutWrapped(this)
    }
}
export class SupplierOutWrapped {
    supplier: SupplierOut
    constructor(supplier: SupplierOut) {
        this.supplier = supplier
    }
}
