import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Formatters } from '../../helpers/formatters';

@Component({
  selector: 'app-summary-chip',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './summary-chip.component.html',
  styleUrl: './summary-chip.component.scss'
})
export class SummaryChipComponent {
  @Input('title') title: string = ""
  @Input('amount') amount: number = 0
  @Input('currency') currency: string = "XAF"
  @Input('frequency') _frequency: number = 0
  frequencies: string[] = ['week', 'month', 'year']
  @Input('defaultColor') defaultColor: boolean = true

  constructor(private translate: TranslateService){}

  get frequency(): string {
    return this.frequencies[this._frequency]
  }

  formatAmount(amount: number, currency: any) {
    return Formatters.formatAmount(amount, currency, this.translate.getDefaultLang())
  }
}
