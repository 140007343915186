import { Component, OnInit } from '@angular/core';
import { DashboardLayoutComponent } from '../../../ui/dashboard-layout/dashboard-layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierDetailsShimmerComponent } from '../../skeleton-load/supplier-details-shimmer/supplier-details-shimmer.component';
import { EmployeeResp } from '../../../models/employee/employee-resp';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeesService } from '../../../services/employees.service';
import { SharedService } from '../../../services/shared.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-employee-details',
  standalone: true,
  imports: [
    DashboardLayoutComponent,
    TranslateModule,
    SupplierDetailsShimmerComponent
  ],
  templateUrl: './employee-details.component.html',
  styleUrl: './employee-details.component.scss'
})
export class EmployeeDetailsComponent implements OnInit {
  currentEmployee: EmployeeResp = {} as EmployeeResp;
  isLoading: boolean = false;
  bankName: string = '';
  bankOperators: any[] = [];
  employmentTypes: any[] = [];
  genders: any[] = [];
  employmentType: string = '';

  constructor(
    private route: ActivatedRoute,
    private employeesService: EmployeesService,
    private sharedService: SharedService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.pipe(
      switchMap((params: any) => {
        return this.employeesService.getEmployee(params.id);
      }),
      switchMap((employee: any) => {
        this.currentEmployee = employee;

        return this.sharedService.getStaticData();
      })
    ).subscribe(_ => {
      this.employmentTypes = this.sharedService.staticData?.employment_types;
      this.bankOperators = this.sharedService.staticData.bank_operators;
      this.employmentType = this.employmentTypes.find((type: any) => type.id === this.currentEmployee?.employment_type_id)?.name
      this.bankName = this.bankOperators.find(b => b.id == this.currentEmployee.withdrawal_methods.bank_account?.operator_id)?.name || '';
      this.isLoading = false;
    });
  }

  onGoBack() {
    history.back();
  }

  onUpdate() {
    this.employeesService.fetchedTarget = this.currentEmployee;
    this.router.navigate(["/dashboard/employees/new"])
  }

  onPay() {
    this.employeesService.fetchedTarget = this.currentEmployee;
    this.router.navigate(["/dashboard/transactions/new/single-payment"])
  }
}
