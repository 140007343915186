<div class="rounded-2xl bg-purple-200" [ngClass]="{'colors-blue-bg': defaultColor}">
    <div class="px-4 py-2">
        <div class="w-full inline-flex justify-between items-center mb-1">
            <p class="text-lg">{{title}}</p>
            <p class="text-xs inline-flex items-center fonts-bold pt-2">
                <span class="pb-2 mr-1">{{'chips.frequency.' + frequency | translate}}</span>
                <!-- <sup class="text-lg">&#8964;</sup> -->
            </p>
        </div>
        <h1 class="text-title mb-2 fonts-bold">{{formatAmount(amount, currency)}}</h1>
    </div>
</div>
