<div class="p-4 colors-surface rounded-2xl h-full">
    <div class="flex justify-between items-center">
        <h2 class="text-title fonts-bold">{{'home.paid_employees' | translate}}</h2>
        <a class="text-xs colors-primary-text font-semibold cursor-pointer" *ngIf="0 < employees.length" (click)="showAllEmployees()">{{'dashboard_home.list_see_more' | translate}}</a>
    </div>
    <p class="my-16" *ngIf="0 == employees.length">{{'employees.small-list.empty' | translate}}</p>
    <div class="w-full overflow-x-scroll px-4" *ngIf="0 != employees.length">
        <div class="grid grid-cols-5 items-center mt-8">
            <ng-container *ngFor="let header of headers; let i = index">
                <!-- Removing translation for now -->
                <!-- <p class="hidden md:block colors-background py-2 mt-2 fonts-bold" [ngClass]="{'col-span-1': i == 0 || i == employees.length - 1, 'col-span-2': i != 0 && i != employees.length - 1}">{{i == 0 ? '' : 'employees.list.header.' + header | translate}}&nbsp;</p> -->
                <p class="hidden md:block colors-background py-2 mt-2 fonts-bold" [ngClass]="{'col-span-1 rounded-l-xl': i == 0, 'col-span-2': i != 0, 'rounded-r-xl': i == (headers.length - 1)}">{{i == 0 ? '' : 'employees.list_header_' + header | translate}}&nbsp;</p>
            </ng-container>
            <ng-container *ngFor="let employee of employees; let i = index">
                <hr class="col-span-5 md:hidden"/>
                <p class="mt-2 col-span-1 layouts-symbols-badge-small" [ngClass]="employee.color">{{employee.symbols()}}</p>
                <div class="py-2 mt-2 text-lg col-span-2 md:col-span-2" (click)="manageEmployee(employee)"><p>{{employee.first_name}}</p> <p>{{employee.last_name}}</p></div>
                <div class="py-2 mt-2 col-span-2">
                    <p>{{phone(employee).operatorName}}&nbsp;</p>
                    <p>{{employee.reference}}&nbsp;</p>
                </div>
            </ng-container>
        </div>
    </div>
</div>
