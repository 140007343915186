import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { DashboardLayoutComponent } from '../../../ui/dashboard-layout/dashboard-layout.component';
import { TransactionService } from '../../../services/transaction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import Dinero from 'dinero.js';
import { TransactionDetailsShimmerComponent } from '../../skeleton-load/transaction-details-shimmer/transaction-details-shimmer.component';
import { PhoneNumberPipe } from '../../../shared/phone-number.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner as faLoader } from '@fortawesome/free-solid-svg-icons';
import { SuppliersService } from '../../../services/suppliers.service';
import { EmployeesService } from '../../../services/employees.service';
import { DashboardService } from '../../../services/dashboard.service';
import { ProductsService } from '../../../services/products.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-schedule-details',
  standalone: true,
  imports: [
    DashboardLayoutComponent,
    CommonModule,
    TransactionDetailsShimmerComponent,
    PhoneNumberPipe,
    TranslateModule,
    FontAwesomeModule
  ],
  providers: [DatePipe],
  templateUrl: './schedule-details.component.html',
  styleUrl: './schedule-details.component.scss'
})
export class ScheduleDetailsComponent implements OnInit {
  currentTransaction: any = null;
  currentTransactionId: string = '';
  destroyRef = inject(DestroyRef);
  languageRegex = /^fr-[A-Z]{2}$/;
  showDetails: boolean = false;
  canceling: boolean = false;
  ordersData: any[] = [];
  localeLang: string = this.languageRegex.test(navigator.language) ? 'fr-FR' : navigator.language;

  icons = {faLoader}

  working = false

  constructor(
    private router: Router,
    private location: Location,
    private suppliersData: SuppliersService,
    private employeesData: EmployeesService,
    private dashboard: DashboardService,
    private transactionService: TransactionService,
    private shared: SharedService,
    private activatedRoute: ActivatedRoute,
    public datePipe: DatePipe,
    private translater: TranslateService
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.currentTransactionId = params.id;
    });
  }

  ngOnInit(): void {
    this.transactionService.getScheduleDetails(this.currentTransactionId)
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((data: any) => {
        this.currentTransaction = data;
        this.currentTransaction.total_amount_formatted = this.formatAmount(data.schedule.total_amount);
        this.currentTransaction.fees_formatted = this.formatAmount(data.schedule.fees);
        this.currentTransaction.amount_formatted = this.formatAmount(data.schedule.amount);
        this.showDetails = true;
        if (data.schedule.schedulable_type === 'BULK_ORDER') {
          this.ordersData = Array.isArray(data.schedule?.orders_data) ? data.schedule.orders_data : data.schedule?.orders_data ? [data.schedule.orders_data] : [];
          this.ordersData.forEach((order) => {
            order.formatted_amount = this.formatAmount(order.product.details.amount)
          });
          this.currentTransaction.orderData = this.ordersData
        }
      });
      this.employeesData.listEmployees().subscribe(_ => { })
      this.suppliersData.listSuppliers().subscribe(_ => { })
      this.dashboard.summary().subscribe(_ => { })
      this.shared.getStaticData().subscribe(_ => {})
  }

  async duplicate(transaction: any) {
    this.working = true
    this.transactionService.scheduling = true
    this.transactionService.duplicateTarget = transaction
    if (transaction.schedule.schedulable_type == 'BULK_ORDER') {
      if ("BUSINESS_EMPLOYEE" == transaction.schedule.recipient_type) {
        const employeesIds = transaction.schedule.orders_data.map((it: any) => it.recipient.id)
        await this.employeesData.getBulk(employeesIds)
        this.transactionService.duplicateTarget.schedule.concept = await this.translater.get('schedules.copy_label', {concept: transaction.schedule.concept}).toPromise()
        this.router.navigate(["/dashboard/transactions/new/bulk-payment"])
      } else {
        const suppliersIds = transaction.schedule.orders_data.map((it: any) => it.recipient.id)
        await this.suppliersData.getBulk(suppliersIds)
        this.transactionService.duplicateTarget.schedule.concept = await this.translater.get('schedules.copy_label', {concept: transaction.schedule.concept}).toPromise()
        this.router.navigate(["/dashboard/transactions/suppliers/new/bulk-payment"])
      }
    } else {
      if ("BUSINESS_EMPLOYEE" == transaction.schedule.recipient_type) {
        const employeeId = transaction.schedule.recipient.id
        await this.employeesData.getBulk([employeeId])
        this.transactionService.duplicateTarget.schedule.concept = await this.translater.get('schedules.copy_label', {concept: transaction.schedule.concept}).toPromise()
        this.router.navigate(["/dashboard/transactions/new/single-payment"])
      } else {
        const supplierId = transaction.schedule.recipient.id
        await this.suppliersData.getBulk([supplierId])
        this.transactionService.duplicateTarget.schedule.concept = await this.translater.get('schedules.copy_label', {concept: transaction.schedule.concept}).toPromise()
        this.router.navigate(["/dashboard/transactions/suppliers/new/single-payment"])
      }
    }
    this.working = false
  }

  cancel(transaction: any) {
    this.canceling = true
    this.transactionService.cancelSchedule(transaction.schedule.id).subscribe(_ => {
      this.router.navigate(["/dashboard/schedules"], {replaceUrl: true})
      this.canceling = false
    })
  }

  onGoBack() {
    this.location.back();
  }

  formatAmount(amount: any): string {
    return Dinero({ amount: amount.value, currency: amount.currency })
      .setLocale(this.localeLang)
      .toFormat('$0,0');
  }
}
