<div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
  @if (useReactiveForm) {
  <form [formGroup]="customizedForm">
    @if (customizedForm.controls[formFieldName].value !== '' && customizedForm.controls[formFieldName].value !== null) {
    <img [src]="customizedForm.controls[formFieldName].value| operatorInput" alt="Icon"
      class="w-10 h-10 object-cover rounded-full">
    }
  </form>
  } @else {
  @if (formFieldName !== '' && formFieldName !== null) {
  <img [src]="formFieldName | operatorInput" alt="Icon" class="w-10 h-10 object-cover rounded-full">
  }
  }
</div>
