<app-dashboard-layout [hasAction]="false" [pageTitle]="'suppliers.title' | translate">
  <div pageContent>
    <div class="mb-6"><app-stepper [steps]="steps" [currentStep]="currentStep"></app-stepper></div>
    <section class="max-w-3xl colors-surface rounded-2xl p-4 lg:py-12 text-center relative mx-auto mb-0">
      <button class="p-2 absolute right-2 lg:right-4 top-2 rounded-2xl text-4xl" (click)="close()">&times;</button>

      <div class="relative lg:px-16 text-center py-1">
        <app-alert [position]="'relative'" [alertTitle]="alertTitle | translate" [showAlert]="showAlert"
          [alertMessage]="alertMessage | translate" [alertType]="alertType" [alertDuration]="50000"></app-alert>
      </div>

      @if (forUpdate) {
      <h1 class="text-title fonts-bold pb-4">{{'suppliers.update.header' | translate}}</h1>
      <p>{{'suppliers.update.text' | translate}}</p>
      } @else {
      <h1 class="text-title fonts-bold pb-4">{{'suppliers.new.header' | translate}}</h1>
      <p>{{'suppliers.new.text' | translate}}</p>
      }
      @if (currentStep == 0) {
      <form [formGroup]="supplierDetailsForm" class="max-w-lg mx-auto space-y-8 mb-20">
        <div class="inputs-group-span-1">
          <label for="legalName">{{'suppliers.new.legalname' | translate}}</label>
          <input class="inputs-field-default"
            [ngClass]="{'colors-border-error focus:outline-red-500': invalidLegalName}" type="text"
            formControlName="legalName">
          @if (invalidLegalName) {
          <label for="legalName" class="alert-error px-2 py-1">{{'suppliers.new.legalName.error' | translate}}</label>
          }
        </div>

        <div class="inputs-group-span-1">
          <label for="category">{{'suppliers.new.category' | translate}}</label>
          <select [(ngModel)]="categorySelected" (change)="onSelectCategory($event)"
            [ngModelOptions]="{standalone: true}"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option *ngFor="let option of categories" [value]="option.id">{{ option.name }}</option>
          </select>
          @if (invalidCategory) {
          <label for="category" class="alert-error px-2 py-1">{{'suppliers.new.category.error' |
            translate}}</label>
          }
        </div>

        <div class="inputs-group-span-1">
          <label for="type">{{'suppliers.new.type' | translate}}</label>
          <select [(ngModel)]="typeSelected" (change)="onSelectType($event)" [ngModelOptions]="{standalone: true}"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option *ngFor="let option of types" [value]="option.id">{{ option.name }}</option>
          </select>
          @if (invalidType) {
          <label for="type" class="alert-error px-2 py-1">{{'suppliers.new.type.error' |
            translate}}</label>
          }
        </div>

        <fieldset class="border border-solid border-gray-300 p-3">
          <legend class="text-base font-semibold">{{'employees.new.notification' | translate}}</legend>

          <div class="relative mb-6 inputs-group-span-2">
            <label for="email">{{'employees.new.nc.email' | translate}}</label>
            <input type="text" id="input-group-1" formControlName="email"
              class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            @if (supplierDetailsForm.get('email')?.hasError('required') &&
            supplierDetailsForm.get('phone')?.hasError('required')) {
            <label class="alert-error px-2 py-1">{{'employees.new.nc.email.error' | translate}}</label>
            }
          </div>

          <div class="relative inputs-group-span-2">
            <label for="phone">{{'employees.new.nc.phone' | translate}}</label>
            <input type="text" id="input-group-1" formControlName="phone" maxlength="9"
              class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
          </div>
        </fieldset>
      </form>
      } @else {
      <form [formGroup]="supplierWithdrawalDetailsForm" class="block  xl:px-24 lg:px-8">
        <div class="flex items-center justify-center my-5">
          <p class="relative pl-8 py-2">
            <span class="absolute top-0 left-0 h-full bg-blue-600 w-2"></span>
            <span class="ml-4 text-lg fonts-bold">{{'payment.method.desc' | translate}}</span>
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 my-5">
          <div class="border border-gray-200 rounded-md p-4 flex items-center">
            <input id="checkbox-1" type="checkbox" [(ngModel)]="mobileMoneySelected"
              [ngModelOptions]="{standalone: true}" (change)="onSelectMobileMoney($event)" name="checkbox-1"
              class="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-indigo-600">
            <label for="checkbox-1" class="ml-2 font-bold">{{'topup.option.mobile.money.title' |
              translate}}
            </label>
          </div>
          <div class="border border-gray-200 rounded-md p-4 flex items-center">
            <input id="checkbox-2" type="checkbox" [(ngModel)]="bankAccountSelected"
              [ngModelOptions]="{standalone: true}" (change)="onSelectBankTransfer($event)" name="checkbox-2"
              class="w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-indigo-600">
            <label for="checkbox-2" class="ml-2 font-bold">{{'topup.option.bank.title' |
              translate}}</label>
          </div>
        </div>

        @if (paymentMethodMobileMoneySelected) {
        <fieldset class="border border-solid border-gray-300 p-3 mb-4">
          <legend class="text-base font-semibold">{{'topup.option.mobile.money.title' |
            translate}}</legend>

          <div class="inputs-group-span-1">
            <label for="phoneNumber">{{'topup.form.phone' | translate}}</label>
            <div class="relative">
              <input class="inputs-field-default w-full" type="text" formControlName="withdrawalIdentifier"
                maxlength="9" [ngClass]="{'colors-border-error focus:outline-red-500': invalidPhoneIdentifier}">
              <app-operator-icon [customizedForm]="supplierWithdrawalDetailsForm"
                [formFieldName]="'withdrawalIdentifier'"></app-operator-icon>
            </div>
            @if (invalidPhoneIdentifier) {
            <label class="alert-error px-2 py-1">Please enter a valid number</label>
            }
          </div>
        </fieldset>
        }

        @if (paymentMethodBankTransferSelected) {
        <fieldset class="border border-solid border-gray-300 p-3">
          <legend class="text-base font-semibold">{{'topup.option.bank.title' | translate}}</legend>

          <div class="space-y-4">
            <div class="inputs-group-span-1">
              <label for="bankName">{{'topup.option.bank.name' | translate}}</label>
              <select [(ngModel)]="bankSelected" (change)="onSelectBank($event)" [ngModelOptions]="{standalone: true}"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <option *ngFor="let option of bankOperators" [value]="option.id">{{ option.name }}</option>
              </select>
              @if (invalidBankName) {
              <label for="bankName" class="alert-error px-2 py-1">{{'suppliers.new.bank.name.error' |
                translate}}</label>
              }
            </div>

            <div class="inputs-group-span-1">
              <label for="accountNumber">{{'withdrawal.method.account.number' | translate}}</label>
              <div class="relative">
                <input class="inputs-field-default w-full" type="text" formControlName="accountNumber"
                  [ngClass]="{'colors-border-error focus:outline-red-500': invalidAccountNumber || invalidAccountNumberLength}"
                  maxlength="11">
              </div>
              @if (invalidAccountNumber) {
              <label for="accountNumber" class="alert-error px-2 py-1">{{'suppliers.new.account.number.error' |
                translate}}</label>
              }
              @if (invalidAccountNumberLength) {
              <label for="accountNumber" class="alert-error px-2 py-1">{{'suppliers.new.account.number.minlength' |
                translate}}</label>
              }
            </div>
            <div class="inputs-group-span-1">
              <label for="bankCode">{{'withdrawal.method.bank.bankCode' | translate}}</label>
              <div class="relative">
                <input class="inputs-field-default w-full" type="text"
                  [ngClass]="{'colors-border-error focus:outline-red-500': invalidBankCode || invalidBankCodeLength}"
                  formControlName="bankCode" maxlength="5">
              </div>
              @if (invalidBankCode) {
              <label for="bankCode" class="alert-error px-2 py-1">{{'suppliers.new.bank.code.error' |
                translate}}</label>
              }
              @if (invalidBankCodeLength) {
              <label for="bankCode" class="alert-error px-2 py-1">{{'suppliers.new.bank.code.minlength' |
                translate}}</label>
              }
            </div>
            <div class="inputs-group-span-1">
              <label for="branchCode">{{'withdrawal.method.bank.branchCode' | translate}}</label>
              <div class="relative">
                <input class="inputs-field-default w-full"
                  [ngClass]="{'colors-border-error focus:outline-red-500': invalidBranchCode}" type="text"
                  formControlName="branchCode" maxlength="5">
              </div>
              @if (invalidBranchCode) {
              <label for="branchCode" class="alert-error px-2 py-1">{{'suppliers.new.branch.code.error' |
                translate}}</label>
              }
              @if (invalidBranchCodeLength) {
              <label for="branchCode" class="alert-error px-2 py-1">{{'suppliers.new.branch.code.minlength' |
                translate}}</label>
              }
            </div>

            <div class="inputs-group-span-1">
              <label for="ribKey">{{'withdrawal.method.bank.ribKey' | translate}}</label>
              <div class="relative">
                <input class="inputs-field-default w-full" type="text"
                  [ngClass]="{'colors-border-error focus:outline-red-500': invalidRibKey}" formControlName="ribKey"
                  maxlength="2">
              </div>
              @if (invalidRibKey) {
              <label for="ribKey" class="alert-error px-2 py-1">{{'suppliers.new.rib.error' |
                translate}}</label>
              }
              @if (invalidRibKeyLength) {
              <label for="ribKey" class="alert-error px-2 py-1">{{'suppliers.new.rib.minlength' |
                translate}}</label>
              }
            </div>
          </div>
        </fieldset>
        }
      </form>
      }
    </section>

    <div class="w-full md:w-[calc(100%-284px)] fixed md:absolute md:left-[284px] bottom-0 left-0 px-4 md:px-8">
      <div class="w-full flex justify-center py-4">
        @if (currentStep > 0) {
        <button class="px-8 py-2 border colors-primary-border colors-primary-text rounded-lg self-start mr-2"
          (click)="goBack()"><span class="mr-2 font-black">&lang;</span>{{'simple_payment.action_back' |
          translate}}</button>
        }
        @if (currentStep !== (steps.length + 1)) { <button class="px-8 py-2 colors-primary-bg rounded-lg self-start"
          (click)="onContinue()" *ngIf="!loading">{{currentStep !==
          2
          ? ("bulk_payment.next_action" | translate) :
          ("bulk_payment.confirm_action" | translate)}}<span class="ml-2 font-black">&rang;</span></button>
        }
        <button class="px-8 py-2 colors-primary-bg rounded-lg self-start flex disabled" *ngIf="loading">
          <p class="animate animate-spin mr-4"><fa-icon [icon]="icons.faLoader"></fa-icon></p>
          {{'simple_payment.action_working' | translate}}
        </button>
      </div>
    </div>

  </div>
</app-dashboard-layout>
