import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Formatters } from '../../helpers/formatters';

@Component({
  selector: 'app-summary-card',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './summary-card.component.html',
  styleUrl: './summary-card.component.scss'
})
export class SummaryCardComponent {
  @Input('title') title:string = ""
  @Input('comment') comment:string = ""
  @Input('amount') amount:number = 0
  @Input('currency') currency:string = "XAF"
  @Input('actionLabel') actionLabel:string = ""
  @Input() disabled: boolean = false

  constructor(private translate: TranslateService) {}

  @Output() onAction = new EventEmitter()

  onActionClicked() {
    this.onAction.emit()
  }

  formatAmount(amount: number, currency: any) {
    return Formatters.formatAmount(amount, currency, this.translate.getDefaultLang())
  }
}
