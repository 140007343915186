<section *ngIf="isLoading; else notEmpty" class="animate-pulse-children space-y-12 rounded-2xl relative w-full border colors-primary-dark-border py-4 colors-surface overflow-x-hidden">
    <div class="flex space-x-4">
        <div class="colors-background rounded-lg w-32 h-10">&nbsp;</div>
        <div class="colors-background rounded-lg w-full h-10">&nbsp;</div>
    </div>
   <div class="flex-1 space-y-4">
        <div class="colors-background rounded-lg w-full h-10">&nbsp;</div>
        <div class="space-y-4">
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
            <div class="colors-background rounded-2xl w-full h-16">&nbsp;</div>
        </div>
   </div>
</section>
<ng-template #notEmpty>
<section class="rounded-2xl relative w-full border colors-primary-dark-border py-4 colors-surface overflow-x-hidden">
    <div class="flex w-full px-4">
        <div class="flex stretch items-center border relative colors-primary-dark-border py-2 px-4 w-full rounded-lg">
            <label class="mr-2" for="search">
                <fa-icon [icon]="icons.faSearch"></fa-icon>
            </label>
            <input (keyup)="filterSuppliers()" class="w-full h-full focus:outline-none" [(ngModel)]="filter" name="search" id="search" type="text" [placeholder]="'suppliers.list.search.field' | translate">
        </div>
    </div>
    <div class="w-full overflow-x-scroll px-4">
        <div class="grid grid-cols-5 md:grid-cols-9 items-center md:min-w-[712px] mt-8">
            <ng-container>
                <ng-container *ngFor="let header of headers; let i = index">
                    <p class="hidden md:block colors-background py-2 mt-2 fonts-bold" 
                    [ngClass]="{
                        'col-span-2': i > 0, 
                        'rounded-l-2xl pl-4': i == 0}">
                        {{i == 0 ? '' : 'suppliers.list.header.' + header | translate}}
                        &nbsp;
                    </p>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let supplier of suppliers; let i = index; trackBy: trackById">
                <div (click)="manageSupplier(supplier.id)" class="grid grid-cols-5 md:grid-cols-9 col-span-9 hover:bg-gray-50 cursor-pointer items-center rounded-2xl">
                    <hr class="col-span-5 md:hidden"/>
                    <p class="mt-2 layouts-symbols-badge-small md:layouts-symbols-badge" [ngClass]="supplier.color">{{supplier.symbols()}}</p>
                    <p class="py-2 mt-2 text-lg col-span-2 md:col-span-2">{{supplier.legal_name}}&nbsp;</p>
                    <p class="py-2 mt-2 text-lg col-span-2 md:col-span-2">{{supplier.reference}}&nbsp;</p>
                    <p class="py-2 mt-2 text-lg col-span-2 md:col-span-2">{{supplier.type(supplierTypes)}}&nbsp;</p>
                    <p class="py-2 mt-2 text-lg col-span-2 md:col-span-2">{{supplier.category(supplierCategories)}}&nbsp;</p>
                </div>
            </ng-container>
        </div>
        <div>&nbsp;</div>
        <app-pagination (onPageChanged)="handlePageChange($event)" [totalResult]="totalResults"
          [pageSize]="resultsPerPage" [currentPage]="currentPage"></app-pagination>
    </div>
</section>
</ng-template>
