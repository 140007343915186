<app-dashboard-layout [hasAction]="false" [pageTitle]="'schedules.details.title' | translate">
  <div pageContent>
    @if (showDetails) {
    <div
      class="rounded-2xl fonts-normal relative w-full border colors-primary-dark-border py-4 colors-surface overflow-x-hidden">
      <div class="bg-white rounded-lg p-3">
        <div (click)="onGoBack()" class="flex fonts-bold items-center">
          <svg width="34px" height="34px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#10004c">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path fill="#10004c" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path>
              <path fill="#10004c"
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z">
              </path>
            </g>
          </svg>
          <span class="text-lg ml-4 font-bold cursor-pointer">{{'schedules.details.back' | translate}}</span>
        </div>
      </div>

      <div class="flex flex-col px-4 md:px-10 mt-4">

        @if (currentTransaction?.schedule.schedulable_type === 'BULK_ORDER') {
        <div>
          <ul class="divide-y fonts-bold">
            <li
              class="py-5 bg-white flex border colors-primary-dark-border items-center md:justify-between px-4 rounded-full flex-col sm:flex-row">
              <div class="flex gap-3">
                <div class="flex-none w-12 h-12 rounded-full">
                  <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path opacity="0.5"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        fill="#f83f3f"></path>
                      <path
                        d="M10.5 8.25C10.0858 8.25 9.75 8.58579 9.75 9C9.75 9.41421 10.0858 9.75 10.5 9.75H13.1893L8.46967 14.4697C8.17678 14.7626 8.17678 15.2374 8.46967 15.5303C8.76256 15.8232 9.23744 15.8232 9.53033 15.5303L14.25 10.8107V13.5C14.25 13.9142 14.5858 14.25 15 14.25C15.4142 14.25 15.75 13.9142 15.75 13.5V9C15.75 8.58579 15.4142 8.25 15 8.25H10.5Z"
                        fill="#f83f3f"></path>
                    </g>
                  </svg>
                </div>
                <div>
                  <span class="block text-lg font-medium">Futa Wallet</span>
                  <span class="block text-xs">{{ ('schedules.frequency_' + currentTransaction?.schedule.schedule_frequency).toLowerCase() | translate }}</span>
                </div>
              </div>
              <div class="flex md:flex-col justify-between space-x-2 ml-14 md:ml-0 md:items-center">
                <span class="text-lg fonts-bold">{{ currentTransaction?.schedule.concept }}</span>
                <span class="block text-xs">{{ (currentTransaction?.schedule.schedule_frequency == "ONCE" ? 'schedules.once_on' : 'schedules.monthly_from') | translate }} {{ datePipe.transform(currentTransaction?.schedule.schedule_next_execution_date, 'dd/MM/yyyy') }}</span>
              </div>
              <div class="flex md:flex-col flex-row md:items-center ml-14 md:ml-0">
                <span>{{ currentTransaction?.amount_formatted }}</span>
                @if (currentTransaction?.schedule.schedulable_type === 'BULK_ORDER') {
                <span class="flex text-sm">
                  <svg width="20px" height="20px" viewBox="0 0 24 24" class="mr-1" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <rect width="24" height="24" fill="white"></rect>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5 9.5C5 7.01472 7.01472 5 9.5 5C11.9853 5 14 7.01472 14 9.5C14 11.9853 11.9853 14 9.5 14C7.01472 14 5 11.9853 5 9.5Z"
                        fill="#07033a"></path>
                      <path
                        d="M14.3675 12.0632C14.322 12.1494 14.3413 12.2569 14.4196 12.3149C15.0012 12.7454 15.7209 13 16.5 13C18.433 13 20 11.433 20 9.5C20 7.567 18.433 6 16.5 6C15.7209 6 15.0012 6.2546 14.4196 6.68513C14.3413 6.74313 14.322 6.85058 14.3675 6.93679C14.7714 7.70219 15 8.5744 15 9.5C15 10.4256 14.7714 11.2978 14.3675 12.0632Z"
                        fill="#07033a"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4.64115 15.6993C5.87351 15.1644 7.49045 15 9.49995 15C11.5112 15 13.1293 15.1647 14.3621 15.7008C15.705 16.2847 16.5212 17.2793 16.949 18.6836C17.1495 19.3418 16.6551 20 15.9738 20H3.02801C2.34589 20 1.85045 19.3408 2.05157 18.6814C2.47994 17.2769 3.29738 16.2826 4.64115 15.6993Z"
                        fill="#07033a"></path>
                      <path
                        d="M14.8185 14.0364C14.4045 14.0621 14.3802 14.6183 14.7606 14.7837V14.7837C15.803 15.237 16.5879 15.9043 17.1508 16.756C17.6127 17.4549 18.33 18 19.1677 18H20.9483C21.6555 18 22.1715 17.2973 21.9227 16.6108C21.9084 16.5713 21.8935 16.5321 21.8781 16.4932C21.5357 15.6286 20.9488 14.9921 20.0798 14.5864C19.2639 14.2055 18.2425 14.0483 17.0392 14.0008L17.0194 14H16.9997C16.2909 14 15.5506 13.9909 14.8185 14.0364Z"
                        fill="#07033a"></path>
                    </g>
                  </svg>
                  {{ordersData.length}}
                </span>
                }
              </div>
            </li>
          </ul>
        </div>

        <div class="rounded-lg border my-14 shadow-lg">
          <ul class="divide-y space-y-3">
            @for (order of ordersData; track $index) {
            <li
              class="py-5 bg-[#f2f2f7] cursor-pointer flex items-center md:justify-between border px-4 rounded-lg flex-col sm:flex-row">
              <div class="flex gap-3">
                <div class="flex-none w-12 h-12 rounded-full">
                  <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path opacity="0.5"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        fill="#f83f3f"></path>
                      <path
                        d="M10.5 8.25C10.0858 8.25 9.75 8.58579 9.75 9C9.75 9.41421 10.0858 9.75 10.5 9.75H13.1893L8.46967 14.4697C8.17678 14.7626 8.17678 15.2374 8.46967 15.5303C8.76256 15.8232 9.23744 15.8232 9.53033 15.5303L14.25 10.8107V13.5C14.25 13.9142 14.5858 14.25 15 14.25C15.4142 14.25 15.75 13.9142 15.75 13.5V9C15.75 8.58579 15.4142 8.25 15 8.25H10.5Z"
                        fill="#f83f3f"></path>
                    </g>
                  </svg>
                </div>
                <div>
                  <span class="block text-lg fonts-bold">{{order.recipient.full_name}}</span>
                  <span class="block text-md">{{order.recipient.identifier}}</span>
                  <!-- <span class="block text-xs">{{ datePipe.transform(currentTransaction?.schedule.performed_at,
                    'dd/MM/yyyy') }}</span> -->
                </div>
              </div>
              <div class="flex md:flex-col fonts-bold flex-row md:items-center ml-14 md:ml-0">
                <span class="text-lg">{{ order.formatted_amount }}</span>
              </div>
            </li>
            }
          </ul>
        </div>
        } @else {
        <div class="bg-[#f2f2f7] mb-8 shadow-lg border rounded-lg overflow-hidden">
          <div class="px-6 py-4 space-y-4">
            <p class="flex justify-between">
              <span>{{'transaction_details.concept' | translate}}: </span>
              <span class="fonts-bold">{{ currentTransaction?.schedule?.concept }}</span>
            </p>
            <p class="flex justify-between">
              <span>{{'transaction_details.recipient_name' | translate}}: </span>
              <span class="fonts-bold">{{ currentTransaction?.schedule?.recipient.full_name }}</span>
            </p>
            <p class="flex justify-between">
              <span>{{'transaction_details.recipient_phone_number' | translate}}: </span>
              <span class="fonts-bold">{{ currentTransaction?.schedule?.recipient.identifier ?? '' | phoneNumber}}</span>
            </p>
            <p class="flex justify-between">
              <span>{{'transaction_details.schedule_frequency' | translate}}: </span>
              <span class="fonts-bold">{{ currentTransaction?.schedule?.schedule_frequency }}</span>
            </p>
            <p class="flex justify-between">
              <span>{{'transaction_details.schedule_next_date' | translate}}: </span>
              <span class="fonts-bold">{{ datePipe.transform(currentTransaction?.schedule?.schedule_next_execution_date,
                'dd/MM/yyyy') }}</span>
            </p>
            <p class="flex justify-between">
              <span>{{'transactions.details.recipient.amount.paid' | translate}}: </span>
              <span class="fonts-bold">{{ currentTransaction?.amount_formatted }}</span>
            </p>
            <p class="flex justify-between">
              <span>{{'transactions.details.recipient.amount.fees' | translate}}: </span>
              <span class="fonts-bold">{{ currentTransaction?.fees_formatted }}</span>
            </p>
            <p class="flex text-xl fonts-bold items-center justify-between pt-8">
              <span>{{'transactions.details.recipient.total.amount' | translate}}: </span>
              <span class="fonts-bold">{{ currentTransaction?.total_amount_formatted }}</span>
            </p>
          </div>
        </div>
        }
      </div>
      <div class="flex justify-center mb-4">
        <button [disabled]="canceling" *ngIf="!working" (click)="duplicate(currentTransaction)" class="colors-primary-text rounded-2xl px-8 colors-primary-border py-2 mr-4 border">{{'schedules.details.action_duplicate' | translate}}</button>
        <button *ngIf="working" class="flex colors-primary-text rounded-2xl px-8 colors-primary-border py-2 mr-4 border"><p class="animate animate-spin mr-1"><fa-icon [icon]="icons.faLoader"></fa-icon></p>{{'schedules.details.action_working' | translate}}</button>
        <button *ngIf="!canceling" (click)="cancel(currentTransaction)" class="text-orange-600 px-8 py-2 rounded-2xl border border-orange-600">{{'schedules.details.action_cancel' | translate}}</button>
        <button *ngIf="canceling" class="flex text-orange-600 px-8 py-2 rounded-2xl border border-orange-600"><p class="animate animate-spin mr-1"><fa-icon [icon]="icons.faLoader"></fa-icon></p>{{'schedules.details.action_canceling' | translate}}</button>
      </div>
    </div>
    } @else {
    <app-transaction-details-shimmer></app-transaction-details-shimmer>
    }
  </div>
</app-dashboard-layout>
