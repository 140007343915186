<div class="w-full text-center pt-16 pb-24">
    <div class="w-full flex justify-center">
        <img src="assets/employees_empty_picture.png" class="max-w-[480px] min-w-[280px]" alt="empty employees list picture">
    </div>
    <h2 class="fonts-bold mb-2">{{'employees.empty.header' | translate}}</h2>
    <p class="text-sm mb-4">{{'employees.empty.text' | translate}}</p>
    <button (click)="addEmployee()" 
        class="border colors-primary-border colors-primary-bg inline-flex items-center rounded-2xl px-4 py-2">
        <fa-icon class="mr-2" [icon]="icons.faPlus"></fa-icon>
        <p>{{'employees.action.add' | translate}}</p>
    </button>
</div>
