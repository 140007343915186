import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operatorInput',
  standalone: true
})
export class OperatorInputPipe implements PipeTransform {

  transform(textValue: string = ''): string {
    // const mtnRegex = /^(?:\+237)?(6[78]|65[0-4]|68[0-3])/;
    const mtnRegex = /^(?:\+237)?(?:67|65[0-4]|68[0-3])/;
    const orangeRegex = /^(?:\+237)?(?:69|65[5-9])/;

    const eventValue = textValue;

    if (mtnRegex.test(eventValue)) {
      return '../../../assets/mtn.png';
    } else if (orangeRegex.test(eventValue)) {
      return '../../../assets/orange.png';
    }

    return '../../../assets/cancel.png';
  }
}
