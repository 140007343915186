<div class="p-4 colors-surface rounded-2xl min-h-[400px] h-full col-span-2">
    <div class="flex justify-between items-center">
        <h2 class="">{{'schedules.list_showing_x' | translate: {count: schedules.length} }}</h2>
    </div>
    <ng-container *ngIf="0 == schedules.length">
        <div class="flex flex-col justify-center">
            <p class="mt-16 mb-6 text-center">{{'schedules.empty_list' | translate}}</p>
            <button class="colors-primary-bg rounded-2xl px-4 py-2 mx-auto" (click)="newSchedule()">{{'schedules.schedule_new' | translate}}</button>
        </div>
    </ng-container>
    <ng-container *ngIf="0 != schedules.length">
        <ul class="my-4">
            <li>
                <ng-container *ngFor="let schedule of schedules; let i = index">
                    <app-schedule-item (onClicked)="itemClicked($event)" [schedule]="schedule"></app-schedule-item>
                </ng-container>
            </li>
        </ul>
        <app-pagination (onPageChanged)="handlePageChange($event)" [totalResult]="totalResults"
          [pageSize]="resultsPerPage" [currentPage]="currentPage"></app-pagination>
    </ng-container>
</div>
