import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-kts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './kts.component.html',
  styleUrl: './kts.component.scss'
})
export class KtsComponent {
  isOpen: boolean = false;
}
