import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = localStorage.getItem('token') || '';

    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    });

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 400) {
            if (error.error.error.id === 'invalid_credentials_error')
              this.translate.get('errorMessage.auth.400').subscribe((res: string) => {
                this.showErrorSnackBar(res);
              });
          } else if (error.status === 401) {
            this.translate.get('errorMessage.401').subscribe((res: string) => {
              this.showErrorSnackBar(res);
            });
            // this.router.navigate(['/auth/login']);
          } else if (error.status === 404) {
            this.translate.get('errorMessage.404').subscribe((res: string) => {
              this.showErrorSnackBar(res);
            });
          }
          else if (error.status === 422) {
            this.translate.get('errorMessage.422').subscribe((res: string) => {
              this.showErrorSnackBar(res);
            });
          }
          else if (error.status === 500) {
            this.translate.get('errorMessage.500').subscribe((res: string) => {
              this.showErrorSnackBar(res);
            });
          } else {
            this.translate.get('errorMessage.500').subscribe((res: string) => {
              this.showErrorSnackBar(res);
            });
          }
        } else {
          this.showErrorSnackBar(error.error.error.developer_message);
        }

        return throwError(() => error);
      })
    );
  }

  private showErrorSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 7000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['snackbar-error']
    });
  }
}
