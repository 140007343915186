<app-dashboard-layout [hasAction]="false" [pageTitle]="'employees_details.title' | translate">
  <div pageContent>
    @if (isLoading) {
    <app-supplier-details-shimmer></app-supplier-details-shimmer>
    } @else {
    <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
      <div class="bg-white rounded-lg p-3">
        <div (click)="onGoBack()" class="flex fonts-bold items-center">
          <svg width="34px" height="34px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#10004c">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path fill="#10004c" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path>
              <path fill="#10004c"
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z">
              </path>
            </g>
          </svg>
          <span class="text-lg ml-4 font-bold cursor-pointer">{{'employees_details.back_to_employees' | translate}}</span>
        </div>
      </div>

      <div class="px-6 py-4 space-y-3">
        <h3 class="text-lg mb-2 bg-slate-300 fonts-bold px-2">{{'employees_details.personal_data' | translate}}</h3>
        <div class="flex justify-between px-4">
          <p>{{'employees.list.header.firstname' | translate}}:</p>
          <p>{{currentEmployee.first_name}}</p>
        </div>
        <div class="flex justify-between px-4">
          <p>{{'employees.list.header.lastname' | translate}}:</p>
          <p>{{currentEmployee.last_name}}</p>
        </div>
        <div class="flex justify-between px-4">
          <p>{{'gender' | translate}}:</p>
          <p class="uppercase">{{currentEmployee.gender_id}}</p>
        </div>
        <div class="flex justify-between px-4">
          <p>{{'employees.new.birthdate' | translate}}:</p>
          <p>{{currentEmployee.birth_date}}</p>
        </div>
        <div class="flex justify-between px-4">
          <p>{{'employees_details.identification_number' | translate}}:</p>
          <p>{{currentEmployee.national_id_number}}</p>
        </div>
      </div>

      <div class="px-6 py-4 space-y-3">
        <h3 class="text-lg mb-2 bg-slate-300 fonts-bold px-2">{{'general.info' | translate}}</h3>

        <div class="flex justify-between px-4">
          <p>{{'suppliers.new.category' | translate}}:</p>
          <p>{{currentEmployee.role}}</p>
        </div>
        <div class="flex justify-between px-4">
          <p>{{'suppliers.list.header.type' | translate}}:</p>
          <p>{{employmentType}}</p>
        </div>
        <div class="flex justify-between px-4">
          <p>{{'employees_details.employment_date' | translate}}:</p>
          <p>{{currentEmployee.employment_date}}</p>
        </div>
      </div>

      <div class="border-t border-gray-200 px-6 py-4 space-y-3">
        <h3 class="text-lg font-semibold fonts-bold bg-slate-300 mb-2 px-2">{{'employees.new.notification' | translate}}
        </h3>
        <div class="flex justify-between px-4">
          <p>{{'employees.new.nc.email' | translate}}:</p>
          <p>{{currentEmployee.email}}</p>
        </div>
        <div class="flex justify-between px-4">
          <p>{{'employees.new.nc.phone' | translate}}:</p>
          <p>{{currentEmployee.phone}}</p>
        </div>
      </div>

      <div class="border-t border-gray-200 px-6 py-4 space-y-3">
        <h3 class="text-lg fonts-bold bg-slate-300 mb-2 px-2">{{'withdrawal.method' | translate}}</h3>
        @if (currentEmployee.withdrawal_methods.mobile_money !== null) {
        <fieldset class="border border-solid border-gray-300 p-3 mb-4">
          <legend class="text-base font-semibold">{{'topup.option.mobile.money.title' |
            translate}}</legend>

          <div class="flex justify-between px-4">
            <p>{{'topup.step2.identifier' | translate}}:</p>
            <p>{{currentEmployee.withdrawal_methods.mobile_money.identifier}}</p>
          </div>
        </fieldset>
        }

        @if (currentEmployee.withdrawal_methods.bank_account !== null) {
        <fieldset class="border border-solid border-gray-300 p-3 space-y-3">
          <legend class="text-base font-semibold">{{'topup.option.bank.title' | translate}}</legend>
          <div class="flex justify-between px-4">
            <p>{{'bank.name' | translate}}:</p>
            <p>{{bankName}}</p>
          </div>
          <div class="flex justify-between px-4">
            <p>{{'suppliers.new.phone.number' | translate}}:</p>
            <p>{{currentEmployee.withdrawal_methods.bank_account.account_number}}</p>
          </div>
          <div class="flex justify-between px-4">
            <p>{{'bank.code' | translate}}:</p>
            <p>{{currentEmployee.withdrawal_methods.bank_account.bank_code}}</p>
          </div>
          <div class="flex justify-between px-4">
            <p>{{'branch.code' | translate}}:</p>
            <p>{{currentEmployee.withdrawal_methods.bank_account.branch_code}}</p>
          </div>
          <div class="flex justify-between px-4">
            <p>{{'rib.key' | translate}}:</p>
            <p>{{currentEmployee.withdrawal_methods.bank_account.rib_key}}</p>
          </div>
        </fieldset>
        }
      </div>

      <div class="flex justify-center space-x-8 mb-4">
        <button (click)="onUpdate()" class="colors-primary-text rounded-2xl px-8 colors-primary-border py-2 mr-4 border">{{'update' |
          translate}}</button>
        <button (click)="onPay()" class="colors-primary-text rounded-2xl px-8 colors-primary-border py-2 mr-4 border">{{'employee_details.pay_him' | translate}}</button>
      </div>
    </div>
    }
  </div>
</app-dashboard-layout>
