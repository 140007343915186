import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-empty-schedules-list',
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule],
  templateUrl: './empty-schedules-list.component.html',
  styleUrl: './empty-schedules-list.component.scss'
})
export class EmptySchedulesListComponent {
  constructor(private router: Router) {}

  icons = { faPlus }
  showDropdown = false;

  @Output() onNewTransaction = new EventEmitter()

  newTransaction() {
    // this.onNewTransaction.emit()
    this.showDropdown = !this.showDropdown;
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  goToNewSupplier() {
    this.router.navigate(['/dashboard/transactions/suppliers/new'])
  }

  goToNewTransaction() {
    this.router.navigate(['/dashboard/transactions/new'])
  }

}
