import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { DashboardLayoutComponent } from '../../ui/dashboard-layout/dashboard-layout.component';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PaymentMethod, TopUpRequest, TopUpResponse } from '../../models/account/top-up';
import parsePhoneNumber from 'libphonenumber-js';
import Dinero, { Currency } from 'dinero.js';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Amount } from '../../models/transaction/transaction';
import { AccountService } from '../../services/account.service';
import { LoaderComponent } from '../loader/loader.component';
import { ErrorComponent } from '../../ui/error/error.component';
import { PhoneNumberPipe } from '../../shared/phone-number.pipe';
import { AlertComponent } from '../../ui/alert/alert.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { faSpinner as faLoader } from '@fortawesome/free-solid-svg-icons';
import { StepperComponent } from '../../ui/stepper/stepper.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PaymentProgressComponent } from '../../ui/payment-progress/payment-progress.component';
import { Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { TransactionService } from '../../services/transaction.service';
import { OperatorInputPipe } from '../../shared/operator-input.pipe';
import { OperatorIconComponent } from '../../ui/operator-icon/operator-icon.component';
import { SharedService } from '../../services/shared.service';
import { MoneyPipe } from '../../shared/money.pipe';
import { stringify } from 'node:querystring';
import { BankDetailsGeneratorComponent } from '../../generators/bank-details-generator/bank-details-generator.component';

@Component({
  selector: 'app-top-up-account',
  standalone: true,
  imports: [
    DashboardLayoutComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    ErrorComponent,
    PhoneNumberPipe,
    AlertComponent,
    TranslateModule,
    StepperComponent,
    FontAwesomeModule,
    PaymentProgressComponent,
    OperatorInputPipe,
    OperatorIconComponent,
    MoneyPipe,
    BankDetailsGeneratorComponent
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit {
  topUpForm!: FormGroup;
  steps = ['topup_steps.payment_details', 'topup_steps.payment_summary', 'topup_steps.payment_confirmation'];
  currentStep = 0;
  paymentMethod: PaymentMethod = {} as PaymentMethod;
  destroyRef = inject(DestroyRef);
  isLoading: boolean = false;
  errorMessage: string = '';
  showError: boolean = false;
  showAlert: boolean = false;
  topUpResponse: TopUpResponse = {} as TopUpResponse;
  alertMessage: string = '';
  alertTitle: string = '';
  icons = { faLoader };
  status: string = 'PENDING';
  showPaymentProgress: boolean = false;
  mobileOperator: string = '';
  buttonText: string = '';
  showBankDetails: boolean = false;
  copied: number | null = null;


  selectedPaymentMethod = 'MOBILE_MONEY';

  amountError: boolean = false;
  amountErrorMessage: string = '';
  phoneErrorMessage: string = '';

  copyAllContent: string = ''

  constructor(
    private accountService: AccountService,
    private router: Router,
    private transactionSevice: TransactionService,
    private sharedService: SharedService,
    private translate: TranslateService,
  ) { }

  bankName(id: string): string {
    return this.sharedService.bankIdToFullName(id)
  }

  goHome() {
    this.router.navigate(['/dashboard/home'], {replaceUrl: true})
  }

  ngOnInit(): void {
    this.topUpForm = new FormGroup({
      amount: new FormControl(null, [Validators.required]),
      phone: new FormControl(null),
    });
    this.sharedService.getStaticData().subscribe(_ => {})
  }

  get isPhoneInvalid() {
    const control = this.topUpForm.get('phone');
    if (control?.invalid && (control.dirty || control.touched)) {
      this.phoneErrorMessage = 'topup_steps.error_invalid_phone';
      return true;
    }

    if (control?.value && !parsePhoneNumber(control.value, 'CM')) {
      this.phoneErrorMessage = 'topup_steps.error_invalid_phone';
      return true;
    }

    return false;
  }

  get isAmountInvalid() {
    const control = this.topUpForm.get('amount');
    return control?.invalid && (control.dirty || control.touched);
  }

  get amount() {
    // return amount
    const amount = this.topUpForm.get('amount')?.value * 100
    const plus2Percent = amount * 0.02 + amount
    return Dinero({ amount: plus2Percent, currency: 'XAF' })
      .setLocale(this.translate.currentLang)
      .toFormat('$0,0');
  }

  getAmount(amount: any) {
    return Dinero({ amount: amount.value, currency: 'XAF' })
      .setLocale(this.translate.currentLang)
      .toFormat('$0,0');
  }

  previousStep() {
    if (this.currentStep < 2)
      this.currentStep--;
    else {
      this.getCopyAllContent()
    }
  }

  getCopyAllContent() {
    const result = this.translate.get('topup_page.copy_all_content',
    {
      beneficiary: this.topUpResponse.recipient_name ? this.topUpResponse.recipient_name : 'FUTAMOBILE SARL',
      reference: this.topUpResponse.deposit_details.reference,
      accountNumber: this.topUpResponse.deposit_details.account_number,
      bankCode: this.topUpResponse.deposit_details.bank_code,
      amount: (this.topUpResponse.total_amount.value/100).toString(),
      bankName: this.bankName(this.topUpResponse.deposit_details.bank_operator_id)
    }).subscribe((r: string) => this.copyToClipboard(r, 7))
  }

  nextStep() {
    this.showError = false;
    const control = this.topUpForm.get('amount');
    if (control?.value < 500) {
      this.amountErrorMessage = 'topup_steps.error_amount_too_small';
      this.amountError = true;
      return;
    }
    if (control?.value > 500000) {
      this.amountErrorMessage = 'topup_steps.error_amount_too_big';
      this.amountError = true;
      return;
    }


    if (this.selectedPaymentMethod === 'MOBILE_MONEY') {
      const phoneNumber = this.topUpForm.value.phone;
      this.paymentMethod = {
        type: 'MOBILE_MONEY',
        operator_id: this.sharedService.getMobileMoneyOperator(phoneNumber),
        identifier: parsePhoneNumber(phoneNumber, 'CM')?.number || ''
      }

      this.mobileOperator = this.paymentMethod.operator_id!!;
    } else {
      this.paymentMethod = {
        type: 'BANK_ACCOUNT',
        operator_id: null,
        identifier: null
      }
      this.showBankDetails = true;
    }

    const amount: Amount = {
      value: this.topUpForm.value.amount * 100,
      currency: 'XAF'
    }


    const obj: TopUpRequest = {
      id: null,
      amount: amount,
      payment_method: this.paymentMethod as PaymentMethod,
    }

    if (this.currentStep === 0) {
      this.isLoading = true;
      this.accountService.initiateTopUp({ topup: obj }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.topUpResponse = response;
          this.currentStep++;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error)
          this.errorMessage = error.error.error.developer_message;
          this.showError = true;
        }
      });
    } else if (this.currentStep === 1) {
        this.isLoading = true;

        this.accountService.confirmTopUp(this.topUpResponse?.id).pipe(
          takeUntilDestroyed(this.destroyRef),
          switchMap(response => {
            this.topUpResponse = response;
            this.topUpForm.reset();
            this.isLoading = false;

            if (this.paymentMethod.type == "BANK_ACCOUNT")
            {
              this.currentStep++;
              return of()
            }
            this.showPaymentProgress = true;
            this.currentStep++;
            return this.transactionSevice.checkTransactionStatus(this.topUpResponse.transaction_id);
          })
        ).subscribe({
          next: (response) => {
            this.status = response.details.status;
            this.currentStep++;
          },
          error: (error) => {
            this.isLoading = false;
            this.errorMessage = error.error.error.developer_message;
            this.showError = true;
          }
        });
    }
  }

  onChanged(event: any) {
    const valueChecked = event.target.value;
    if (valueChecked === 'BANK_ACCOUNT') {
      this.steps = ['topup_steps.payment_details', 'topup_steps.payment_summary', 'topup.steps.account_details'];
    } else {
      this.steps = ['topup_steps.payment_details', 'topup_steps.payment_summary', 'topup_steps.payment_confirmation'];
    }
  }

  onDisableNext() {
    if (this.topUpForm.invalid) {
      return true;
    }

    return this.currentStep >= 2 && 'BANK_ACCOUNT' != this.paymentMethod.type
  }

  copyToClipboard(text: string, id: number) {
    navigator.clipboard.writeText(text);
    this.copied = id;
    setTimeout(() => {
      this.copied = null;
    }, 5000);
  }

  moneyFormat(amount: Amount) {
    return Dinero({ amount: amount.value, currency: amount.currency as Currency }).toFormat('$0,0');
  }

  close() {
    history.back()
  }

  startAgain() {
    this.currentStep = 0;
    this.showPaymentProgress = false;
    this.router.navigate(['/dashboard/home']);
  }
}
