<div class="p-4 colors-surface rounded-2xl min-h-[400px] h-full col-span-2">
    <div class="flex justify-between items-center">
        <h2 class="text-title fonts-bold">{{'transactions.list.title' | translate}}</h2>
        <a class="text-xs colors-primary-text font-semibold cursor-pointer" *ngIf="0 < transactions.length" (click)="showAllTransactions()">{{'dashboard_home.list_see_more' | translate}}</a>
    </div>
    <ng-container *ngIf="0 == transactions.length">
        <div class="flex flex-col justify-center items-center h-full">
            <p class="mb-16 text-center">{{'transactions.smalllist.empty' | translate}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="0 != transactions.length">
        <ul class="my-4">
            <li>
                <ng-container *ngFor="let transaction of transactions; let i = index">
                    <app-transaction-item (onClicked)="itemClicked($event)" [transaction]="transaction"></app-transaction-item>
                </ng-container>
            </li>
        </ul>
    </ng-container>
</div>
