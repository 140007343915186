import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { BusinessIn } from '../../models/business/business-in';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { WebsiteIconComponent } from '../website-icon/website-icon.component';
import { TruncateWordsPipe } from '../../shared/truncate-words.pipe';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-business-ui',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MenuItemComponent,
    FontAwesomeModule,
    WebsiteIconComponent,
    TruncateWordsPipe
  ],
  templateUrl: './business-ui.component.html',
  styleUrl: './business-ui.component.scss'
})
export class BusinessUiComponent implements OnInit {
  icons = { faSignOut }
  isCopied: boolean = false
  isCopiedPublicLink: boolean = false

  @Input() business: BusinessIn | null = null
  @Output() updateRequested = new EventEmitter()

  constructor(private router: Router, private auth: AuthService, private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.sharedService.getStaticData().subscribe(_ => {})
  }

  categoryName(categoryId: string): string {
    return this.sharedService.categoryName(categoryId)
  }
  statusName(statusId: string): string {
    return this.sharedService.statusName(statusId)
  }

  signOut() {
    this.auth.signOut()
    this.router.navigate(["/login"], { replaceUrl: true })
  }

  onUpdate() {
    this.updateRequested.emit()
  }

  onCopyReference() {
    navigator.clipboard.writeText(this.business?.reference || "")
    this.isCopied = true
    setTimeout(() => this.isCopied = false, 3000)
  }

  onCopyPublicLink() {
    navigator.clipboard.writeText(this.business?.public_link || "")
    this.isCopiedPublicLink = true
    setTimeout(() => this.isCopiedPublicLink = false, 3000)
  }
}
