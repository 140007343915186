import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Form, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { parsePhoneNumber } from 'libphonenumber-js';
import { EmployeeIn } from '../../../models/employee/employee-in';
import { EmployeeOut } from '../../../models/employee/employee-out';
import { EmployeesService } from '../../../services/employees.service';
import { faSpinner as faLoader } from '@fortawesome/free-solid-svg-icons';
import { AlertComponent } from '../../alert/alert.component';
import { Phone } from '../../../models/phone';
import { TranslateModule } from '@ngx-translate/core';
import { OperatorIconComponent } from "../../operator-icon/operator-icon.component";
import { SharedService } from '../../../services/shared.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StaticData } from '../../../models/static-data';

@Component({
  selector: 'app-employee-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FontAwesomeModule,
    AlertComponent,
    TranslateModule,
    OperatorIconComponent
  ],
  templateUrl: './employee-form.component.html',
  styleUrl: './employee-form.component.scss'
})
export class EmployeeFormComponent {
  @Input('onError') showErrorAlert: boolean = false
  @Input() currentStep: number = 0
  @Output() onAddEmployee = new EventEmitter<EmployeeOut>()
  @Output() onUpdateEmployee = new EventEmitter<EmployeeOut>()
  @Output() formInvalid: EventEmitter<boolean> = new EventEmitter<boolean>()
  newEmployee = new EmployeeOut()
  employeePersonalDataForm!: FormGroup
  employeeJobDetailsForm!: FormGroup
  employeeWithdrawalDetailsForm!: FormGroup
  selectedPaymentMethod: string = ""
  employmentTypeSelected: any
  genders: any[] = []
  employmentTypes: any[] = []
  destroyRef = inject(DestroyRef)
  @Input('updateTarget') currentEmployee: EmployeeIn | null = this.data.updateTarget
  // showEmailInput = false;
  // showPhoneInput = false;

  _working: Boolean = false
  errorMessage: string = ""
  icons = { faLoader }

  constructor(private data: EmployeesService, private sharedService: SharedService) {
    if (null != this.currentEmployee) {
      this.newEmployee.first_name = this.currentEmployee.first_name
      this.newEmployee.last_name = this.currentEmployee.last_name
      this.newEmployee.withdrawal_method.identifier = this.currentEmployee.main_withdrawal_method.identifier
      this.newEmployee.withdrawal_method.operator_id = this.currentEmployee.main_withdrawal_method.operator_id
      this.newEmployee.withdrawal_method.type = this.currentEmployee.main_withdrawal_method.type
    }
  }

  get working() {
    return this._working && !this.showErrorAlert
  }
  set working(val: boolean) {
    this.showErrorAlert = false
    this._working = val
  }

  addOrUpdateEmployee() {
    // if (null != this.currentEmployee) this.updateEmployee(); else this.addEmployee()
  }

  checkFormValidity() {
    console.log("Visibility")
    if (this.currentStep === 0) {
      this.formInvalid.emit(this.employeePersonalDataForm.invalid)
    } else if (this.currentStep === 1) {
      this.formInvalid.emit(this.employeeJobDetailsForm.invalid)
    } else {
      this.formInvalid.emit(this.employeeWithdrawalDetailsForm.invalid)
    }
  }

  // addEmployee() {
  //   this.newEmployee.resetFeedbacks()
  //   this.working = true
  //   if (2 > this.newEmployee.first_name.length) {
  //     this.newEmployee.first_name_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newEmployee.first_name_error = true
  //   }
  //   if (2 > this.newEmployee.last_name.length) {
  //     this.newEmployee.last_name_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newEmployee.last_name_error = true
  //   }
  //   if ("" == this.newEmployee.withdrawal_method.identifier) {
  //     this.newEmployee.withdrawal_method.identifier_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newEmployee.withdrawal_method.identifier_error = true
  //     this.working = false
  //     return
  //   }
  //   const identifier = new Phone(this.newEmployee.withdrawal_method.identifier)
  //   if (!identifier.isValid) {
  //     this.newEmployee.withdrawal_method.identifier_error_message = "Veuillez indiquer un numéro valide"
  //     this.newEmployee.withdrawal_method.identifier_error = true
  //   }
  //   if (!this.newEmployee.hasError()) {
  //     this.newEmployee.withdrawal_method.operator_id = identifier.operatorId
  //     this.newEmployee.withdrawal_method.identifier = identifier.phoneNumber
  //     this.onAddEmployee.emit(this.newEmployee)
  //   } else {
  //     this.working = false
  //   }
  // }

  // updateEmployee() {
  //   this.newEmployee.resetFeedbacks()
  //   this.working = true
  //   if (2 > this.newEmployee.first_name.length) {
  //     this.newEmployee.first_name_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newEmployee.first_name_error = true
  //   }
  //   if (2 > this.newEmployee.last_name.length) {
  //     this.newEmployee.last_name_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newEmployee.last_name_error = true
  //   }
  //   if ("" == this.newEmployee.withdrawal_method.identifier) {
  //     this.newEmployee.withdrawal_method.identifier_error_message = "Veuillez remplir ce champ pour continuer"
  //     this.newEmployee.withdrawal_method.identifier_error = true
  //     this.working = false
  //     return
  //   }
  //   const identifier = new Phone(this.newEmployee.withdrawal_method.identifier)
  //   if (identifier.isValid) {
  //     this.newEmployee.withdrawal_method.identifier_error_message = "Veuillez indiquer un numéro valide"
  //     this.newEmployee.withdrawal_method.identifier_error = true
  //   }
  //   if (!this.newEmployee.hasError()) {
  //     this.newEmployee.withdrawal_method.identifier = identifier.phoneNumber
  //     this.onUpdateEmployee.emit(this.newEmployee)
  //     console.log("Saving employee:" + this.newEmployee.first_name + " " + this.newEmployee.last_name + " " + this.newEmployee.withdrawal_method.type + " " + this.newEmployee.withdrawal_method.identifier)
  //   } else {
  //     console.log("Error saving employee:" + this.newEmployee.first_name + " " + this.newEmployee.last_name + " " + this.newEmployee.withdrawal_method.type + " " + this.newEmployee.withdrawal_method.identifier)
  //     this.working = false
  //   }
  // }

  close() {
    history.back()
  }

  ngOnDestroy(): void {
    this.working = false
    this.data.updateTarget = null
  }
}
