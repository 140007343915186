<app-dashboard-layout [pageTitle]="'suppliers.title' | translate" [actionLabel]="'suppliers.layout.action.add' | translate" [hasAction]="suppliers.length > 0"
    (onNotificationClick)="onNotificationClicked()" (onAction)="onActionClicked()">
    <div pageContent>
        <ng-container *ngIf="suppliers.length > 0 || !alreadyLoaded; else emptyList">
            <ng-container *ngIf="hasNewItem; else noNews">
                <p class="bg-green-200 text-green-600 py-2 px-4 rounded-2xl border border-green-300">{{'suppliers.added.notification' | translate}}</p>
            </ng-container>
            <ng-template #noNews>
                <ng-container *ngIf="alreadyLoaded; else loadingList">
                    <p>{{'suppliers.fulllist.header' | translate}}</p>
                </ng-container>
            </ng-template>
            <br class="colors-background h-8">
            <app-full-suppliers-list [suppliers]="suppliers" [isLoading]="!alreadyLoaded" (onUpdateSupplier)="updateSupplier($event)"></app-full-suppliers-list>
        </ng-container>
        <ng-template #emptyList>
            <ng-container *ngIf="alreadyLoaded; else loadingList">
                <app-empty-list (onAddSupplier)="onActionClicked()"></app-empty-list>
            </ng-container>
        </ng-template>
        <ng-template #loadingList>
            <p>{{'suppliers.fulllist.loading' | translate}}</p>
        </ng-template>
    </div>
</app-dashboard-layout>
